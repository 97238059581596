"use client";

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faCartShopping, faArrowRightArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';

function NavbarLanding() {
  const navigate = useNavigate();

  const handleNavigateToLogin = () => {
    navigate('/signin'); // Mengarahkan ke halaman login
  };

  return (
    <div className="flex justify-between items-center p-2">
      <div className="flex items-center ml-2"> 
        <img src="/img/logo.png" alt="Meals to Heals" className="h-14 w-40" />
      </div>
      <div className="flex-grow flex justify-center space-x-6">
        <Link to="/" className="text-md font-semibold cursor-pointer">Home</Link>
        <Link to="#" className="text-md font-semibold cursor-pointer">Location</Link>
        <Link to="/about-us" className="text-md font-semibold cursor-pointer">About</Link>
        <Link to="/contact-us" className="text-md font-semibold cursor-pointer">Contact</Link>
      </div>
      <div className="flex items-center mr-4">
        <FontAwesomeIcon
          icon={faMagnifyingGlass}
          className="text-xl text-gray-600 cursor-pointer mr-6"
          onClick={handleNavigateToLogin} // Event handler untuk ikon search
        />
        <FontAwesomeIcon
          icon={faCartShopping}
          className="text-xl text-gray-600 cursor-pointer mr-10"
          onClick={handleNavigateToLogin} // Event handler untuk ikon cart
        />
        <Link to="/signin">
          <button className="px-6 py-2 bg-green-500 text-white rounded-lg text-sm mr-3">
            Login
          </button>
        </Link>
        <Link to="/role">
          <button className="px-6 py-2 border-2 border-green-500 text-green-500 rounded-lg text-sm mr-10 hover:bg-green-500 hover:text-white">
            Register
          </button>
        </Link>
        {/* <div className="flex items-center space-x-2">
          <div className="text-center">
            <img src="/img/eng-flag.jpeg" alt="English" className="h-6 w-6 inline-block rounded-full" />
            <p className="text-sm">Eng</p>
          </div>
          <FontAwesomeIcon icon={faArrowRightArrowLeft} className="text-md text-gray-600 cursor-pointer -mt-5" />
          <div className="text-center">
            <img src="/img/ina-flag.jpeg" alt="Indonesia" className="h-6 w-6 inline-block rounded-full" />
            <p className="text-sm">Ina</p>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default NavbarLanding;