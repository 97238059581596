"use client";

import { Button, Label, Radio } from "flowbite-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faUsers,
  faMoneyBill,
  faUserNurse,
} from "@fortawesome/free-solid-svg-icons";
import "./register.css";
import NavbarRegis from "../components/navbar/navbarRegis";
import { Link } from "react-router-dom";

function Role() {
  return (
    <form>
      <NavbarRegis />
      <div className="flex max-w-xl flex-col gap-5 mx-auto mt-16 mb-16">
        <h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-gray-800">
          Select Your Role
        </h2>
        <h6 className="role-text text-center text-xs max-w-md mb-3 text-gray-500 dark:text-gray-400 mt-3">
          "Welcome to our website! Please select your role to continue.
          Selecting your role ensures we provide you with access rights and an
          experience tailored to your needs. Thank you for choosing us!"
        </h6>
        <Link to="/member" className="role max-w-xs gap-2">
          <Radio
            id="member"
            name="countries"
            value="Member"
            className="member"
            defaultChecked
          />
          <FontAwesomeIcon icon={faUser} className="ml-12" />
          <Label htmlFor="member" className="ml-4">
            Member
          </Label>
        </Link>
        <Link to="/partner" className="role max-w-xs gap-2">
          <Radio
            id="partner"
            name="countries"
            value="Partner"
            className="partner"
          />
          <FontAwesomeIcon icon={faUser} className="ml-12" />
          <Label htmlFor="partner" className="ml-4">
            Partner
          </Label>
        </Link>
        <Link to="/patron" className="role max-w-xs gap-2">
          <Radio
            id="patron"
            name="countries"
            value="Patron"
            className="patron"
          />
          <FontAwesomeIcon icon={faMoneyBill} className="ml-12" />
          <Label htmlFor="patron" className="ml-4">
            Patron
          </Label>
        </Link>
        <Link to="/caregiver" className="role max-w-xs gap-2">
          <Radio
            id="caregiver"
            name="countries"
            value="Caregiver"
            className="care"
          />
          <FontAwesomeIcon icon={faUserNurse} className="ml-12" />
          <Label htmlFor="caregiver" className="ml-4">
            Caregiver
          </Label>
        </Link>
        <Link to="/organization" className="role max-w-xs gap-2">
          <Radio
            id="organization"
            name="countries"
            value="Organization"
            className="orgz"
          />
          <FontAwesomeIcon icon={faUsers} className="ml-12" />
          <Label htmlFor="organization" className="ml-4">
            Organization
          </Label>
        </Link>
        <Button
          type="submit"
          className="submit max-w-xs bg-green-600 text-white hover:bg-green-200"
        >
          Submit
        </Button>
      </div>
    </form>
  );
}

export default Role;
