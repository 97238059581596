"use client";

import { Label, Button, TextInput, Datepicker } from "flowbite-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import SidebarMember from "../components/sidebar/sidebarMember";
import { Link } from "react-router-dom";
import "./meal.css";
import { memo, useEffect, useState } from "react";
import axios from "axios";
import { getAuthAndProfileResponses } from "../utils/localstorage";
import { Tooltip } from "react-tooltip";
import { name } from "../../node_modules/workbox-build/node_modules/ajv/dist/compile/codegen/code";
import { MultiSelect } from "react-multi-select-component";

function MemberOwnProfile() {
  const preventNonNumeric = (e) => {
    // Allow backspace, delete, arrow keys, and tab
    const allowedKeys = [
      "Backspace",
      "Delete",
      "ArrowLeft",
      "ArrowRight",
      "Tab",
    ];

    if (allowedKeys.includes(e.key)) {
      return; // Allow these keys
    }

    // Prevent minus key and non-numeric characters
    if (e.code === "Minus" || (isNaN(e.key) && e.key !== ".")) {
      e.preventDefault();
    }

    const inputValue = e.target.value;

    // Regular expression to allow numbers with up to 2 decimal places
    const decimalPattern = /^\d*\.?\d{0,2}$/;

    if (!decimalPattern.test(inputValue)) {
      e.preventDefault();
    }
  };

  const [allergies, setAllergies] = useState([]);
  const [illnesses, setIllnesses] = useState([]);

  const [allergyData, setAllergyData] = useState([]);
  const [illnessData, setIllnessData] = useState([]);

  const [showConfirm, setShowConfirm] = useState(false);
  const [profileImage, setProfileImage] = useState(null);
  const [imageError, setImageError] = useState("");
  const [imageName, setImageName] = useState("");

  const [show, setShow] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);

  let [selectedDate, setSelectedDate] = useState(new Date());
  let [year, month, day] = "0";

  const genders = ["Male", "Female"];

  const { authResponse, profileResponse } = getAuthAndProfileResponses();

  const accessToken = authResponse["access-token"];
  const refreshToken = authResponse["refresh-token"];

  const handleClick = () => setShow(!show);
  const newPasswordClick = () => setShowConfirmPassword(!showConfirmPassword);
  const newConfirmPasswordClick = () =>
    setShowConfirmNewPassword(!showConfirmNewPassword);

  const [ownMemberProfile, setOwnMemberProfile] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file && file.size > 2 * 1024 * 1024) {
      setImageError("File size exceeds 2MB**");
      setImageName("");
    } else {
      setImageError("");
      setProfileImage(URL.createObjectURL(file));
      setImageName(file.name);
    }
  };

  const fetchOwnMemberProfile = async () => {
    try {
      setIsLoading(true);
      await axios
        .get(process.env.REACT_APP_API_BASE_URL + "profiles/me", {
          headers: {
            "Content-Type": "application/json",
            "Access-Token": `${accessToken}`,
            "Refresh-Token": `${refreshToken}`,
            Authorization: `Bearer ${authResponse.token}`,
          },
        })
        .then((response) => {
          setOwnMemberProfile(response?.data?.data);
        });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const loadAllergiesAndIllnesses = async () => {
    const { data: responseAllergies } = await axios.get(
      process.env.REACT_APP_API_BASE_URL + "allergies/raw"
    );

    const { data: responseIllnesses } = await axios.get(
      process.env.REACT_APP_API_BASE_URL + "illnesses/raw"
    );

    const allergies = responseAllergies.data;
    const illnesses = responseIllnesses.data;

    const arrAllergies = [...allergies].map((data) => {
      return { ...data, label: data.name, value: data.id };
    });
    const arrIllnesses = [...illnesses].map((data) => {
      return { ...data, label: data.name, value: data.id };
    });

    setAllergies(arrAllergies);
    setIllnesses(arrIllnesses);
  };

  const handleUpdatePassword = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      await axios.patch(
        process.env.REACT_APP_API_BASE_URL + "profiles/passwords/own",
        JSON.stringify({
          old_password: e.target.oldPassword.value,
          new_password: e.target.newPassword.value,
          confirm_new_password: e.target.confirmNewPassword.value,
        }),
        {
          headers: {
            Authorization: `Bearer ${authResponse.token}`,
            "Access-Token": `${accessToken}`,
            "Refresh-Token": `${refreshToken}`,
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      console.log(error);
    } finally {
      alert("Update password success");
      setIsLoading(false);
    }
  };

  function getFormattedDate(string) {
    const date = new Date(Date.parse(string)); // Parse the date string correctly
    const formattedDate = new Date(
      date.getTime() - date.getTimezoneOffset() * 60000
    ) // Adjust for time zone
      .toISOString()
      .slice(0, 10);
    return formattedDate;
  }

  const handleUpdateBaseProfile = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      await axios.patch(
        process.env.REACT_APP_API_BASE_URL + "profiles/members/own",
        JSON.stringify({
          first_name: e.target.firstName.value,
          last_name: e.target.lastName.value,
          date_of_birth: getFormattedDate(e.target.dob.value),
          gender: e.target.gender.value,
          height: parseFloat(e.target.height.value),
          weight: parseFloat(e.target.weight.value),
          illness_id: illnessData.map((data) => data.id),
          allergy_id: allergyData.map((data) => data.id),
        }),
        {
          headers: {
            Authorization: `Bearer ${authResponse.token}`,
            "Access-Token": `${accessToken}`,
            "Refresh-Token": `${refreshToken}`,
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      console.log(error);
    } finally {
      alert("Update profile success");
      fetchOwnMemberProfile();
      setIsLoading(false);
    }
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  let cgDOB = ownMemberProfile?.date_of_birth;

  if (cgDOB) {
    [year, month, day] = cgDOB.split("-");
    selectedDate = new Date(year, month - 1, day);
  }

  useEffect(() => {
    fetchOwnMemberProfile();
    loadAllergiesAndIllnesses();
  }, []);

  useEffect(() => {
    if (ownMemberProfile) {
      const mappedMemberAllergies = ownMemberProfile?.allergies?.map((data) => {
        return {
          ...data,
          label: data?.allergy?.name,
          value: data?.allergy?.id,
        };
      });

      const mappedMemberIllnesses = ownMemberProfile?.illnesses?.map((data) => {
        return {
          ...data,
          label: data?.illness?.name,
          value: data?.illness?.id,
        };
      });

      if (mappedMemberAllergies) {
        setAllergyData(mappedMemberAllergies);
      }

      if (mappedMemberIllnesses) {
        setIllnessData(mappedMemberIllnesses);
      }
    }
  }, [ownMemberProfile]);

  return (
    <div className="flex h-screen">
      <SidebarMember className="fixed h-full" />
      <div className="flex flex-col flex-grow p-6 overflow-y-auto">
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center">
            <Link to="/home-meal">
              <FontAwesomeIcon icon={faChevronLeft} className="text-lg" />
            </Link>

            <div className="mb-2 ml-12">
              <h2 className="text-2xl font-bold leading-9 tracking-tight text-gray-800">
                Akun Saya
              </h2>
              <p className="text-xs text-gray-600">
                Ini adalah detail akun anda
              </p>
            </div>
          </div>
        </div>
        <hr className="border-gray-400 mb-4" />
        <div className="mt-4 ml-12 lg:flex-row lg:gap-6">
          <div className="flex">
            <div>
              <img
                src={profileImage || ownMemberProfile?.user?.image?.image?.path}
                alt="Profile"
                className="h-36 w-36 inline-block rounded-full border-2"
              />
            </div>
            <div className="ml-14 mt-4">
              <p className="font-bold text-lg">Profile Picture</p>
              <p className="font-semibold text-[12px] text-gray-400">
                PNG, JPG, JPEG up to 5MB
              </p>
              <div className="flex mt-8">
                <label
                  className="mr-8 text-md font-semibold cursor-pointer text-green-600"
                  htmlFor="fileInput"
                >
                  Change Picture
                </label>
                <input
                  id="fileInput"
                  type="file"
                  accept="image/png, image/jpeg"
                  onChange={handleImageChange}
                  className="hidden"
                />
                {imageName
                  ? imageName
                  : ownMemberProfile?.user?.image?.image?.name && (
                      <p className="ml-8 text-sm font-medium text-gray-500">
                        {imageName
                          ? imageName
                          : ownMemberProfile?.user?.image?.image?.name}
                      </p>
                    )}
                {imageError && (
                  <p className="ml-8 text-red-500 text-sm font-semibold">
                    {imageError}
                  </p>
                )}
              </div>
            </div>
          </div>
          <form
            className="gap-4 w-[50vw] mt-8"
            key={ownMemberProfile?.id}
            onSubmit={handleUpdateBaseProfile}
          >
            <h3 className="font-bold text-xl mb-4">Details</h3>
            <div className="name flex">
              <div className="mt-2 w-[100%] mr-2">
                <Label htmlFor="firstName" value="Nama Depan" />
                <TextInput
                  id="firstName"
                  name="firstName"
                  type="text"
                  className="mt-2 w-[100%]"
                  defaultValue={ownMemberProfile?.first_name}
                  required
                />
              </div>
              <div className="mt-2 w-[100%] ml-2">
                <Label htmlFor="lastName" value="Nama Belakang" />
                <TextInput
                  id="lastName"
                  name="lastName"
                  type="text"
                  className="mt-2 w-[100%]"
                  defaultValue={ownMemberProfile?.last_name}
                  required
                />
              </div>
            </div>
            <div className="mt-3">
              <Label htmlFor="dob" value="Tanggal Lahir" />
              <Datepicker
                onChange={handleDateChange}
                defaultDate={selectedDate}
                name="dob"
              />
            </div>
            <div className="mt-3 w-[100%]">
              <Label htmlFor="email1" value="Email" />
              <TextInput
                id="email1"
                type="text"
                className="mt-2 w-[100%]"
                defaultValue={ownMemberProfile?.user?.email}
                disabled
                required
                data-tooltip-id="my-tooltip"
                data-tooltip-content="Email tidak bisa diganti"
              />
              <Tooltip id="my-tooltip" className="max-w-72 !rounded-md" />
            </div>
            <div className="mt-3">
              <Label htmlFor="gender" value="Jenis Kelamin" />
              <div className="flex mt-2">
                <select
                  name=""
                  defaultValue={ownMemberProfile?.gender}
                  id="gender"
                  className="pass select-option"
                >
                  {genders.map((gender, i) => (
                    <option defaultValue={gender} key={i}>
                      {gender}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="flex">
              <div className="mt-2 w-[100%] mr-2">
                <Label htmlFor="height" value="Tinggi (Cm)" />
                <TextInput
                  id="height"
                  type="decimal"
                  min="0"
                  className="mt-2 w-[100%]"
                  defaultValue={ownMemberProfile?.height}
                  required
                  onKeyDown={preventNonNumeric}
                />
              </div>
              <div className="mt-2 w-[100%] ml-2">
                <Label htmlFor="weight" value="Berat (Kg)" />
                <TextInput
                  id="weight"
                  type="decimal"
                  min="0"
                  className="mt-2 w-[100%]"
                  defaultValue={ownMemberProfile?.weight}
                  required
                  onKeyDown={preventNonNumeric}
                />
              </div>
            </div>
            <div>
              <Label htmlFor="allergies" value="Allergi" />
              <div className="flex mt-2">
                <MultiSelect
                  options={allergies}
                  className="select-option"
                  value={allergyData}
                  onChange={setAllergyData}
                  labelledBy="Allergies"
                />
              </div>
            </div>

            <div>
              <Label htmlFor="illnesses" value="Penyakit" />
              <div className="flex mt-2">
                <MultiSelect
                  options={illnesses}
                  className="select-option"
                  value={illnessData}
                  onChange={setIllnessData}
                  labelledBy="Illness"
                />
              </div>
            </div>
            <Button
              type="submit"
              className="mt-8 w-[15vw] bg-green-600 text-white hover:bg-green-200"
            >
              Simpan
            </Button>
          </form>
          <form
            className="gap-4 w-[50vw] mt-12"
            onSubmit={handleUpdatePassword}
          >
            <h3 className="mt-4 mb-4 font-bold">Ganti Password</h3>
            <div>
              <div className="mb-2 block mt-2">
                <Label htmlFor="oldPassword" value="Password Saat Ini" />
              </div>
              <div className="flex">
                <input
                  id="oldPassword"
                  type={show ? "text" : "password"}
                  className="text-sm py-3 w-[100%] focus:border-cyan-500 focus:ring-cyan-500 rounded-lg"
                  placeholder="8+ characters"
                  name="oldPassword"
                  required
                />
                <p
                  onClick={handleClick}
                  className="item-center ml-[-5vh] mt-3 cursor-pointer text-gray-400"
                >
                  {show ? (
                    <FontAwesomeIcon icon={faEyeSlash} />
                  ) : (
                    <FontAwesomeIcon icon={faEye} />
                  )}
                </p>
              </div>
            </div>
            <div>
              <div className="mb-2 block mt-2">
                <Label htmlFor="newPassword" value="Password Baru" />
              </div>
              <div className="flex">
                <input
                  id="newPassword"
                  type={showConfirmPassword ? "text" : "password"}
                  className="text-sm py-3 w-[100%] focus:border-cyan-500 focus:ring-cyan-500 rounded-lg"
                  placeholder="8+ characters"
                  name="newPassword"
                  required
                />
                <p
                  onClick={newPasswordClick}
                  className="item-center ml-[-5vh] mt-3 cursor-pointer text-gray-400"
                >
                  {showConfirmPassword ? (
                    <FontAwesomeIcon icon={faEyeSlash} />
                  ) : (
                    <FontAwesomeIcon icon={faEye} />
                  )}
                </p>
              </div>
            </div>
            <div>
              <div className="mb-2 block mt-2">
                <Label
                  htmlFor="confirmNewPassword"
                  value="Konfirmasi Password Baru"
                />
              </div>
              <div className="flex">
                <input
                  id="confirmNewPassword"
                  type={showConfirmNewPassword ? "text" : "password"}
                  className="text-sm py-3 w-[100%] focus:border-cyan-500 focus:ring-cyan-500 rounded-lg"
                  placeholder="8+ characters"
                  name="confirmNewPassword"
                  required
                />
                <p
                  onClick={newConfirmPasswordClick}
                  className="item-center ml-[-5vh] mt-3 cursor-pointer text-gray-400"
                >
                  {showConfirmNewPassword ? (
                    <FontAwesomeIcon icon={faEyeSlash} />
                  ) : (
                    <FontAwesomeIcon icon={faEye} />
                  )}
                </p>
              </div>
            </div>
            <Button
              type="submit"
              className="mt-8 w-[15vw] bg-green-600 text-white hover:bg-green-200"
            >
              Simpan
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default MemberOwnProfile;
