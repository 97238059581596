"use client";

import { Label, Button, TextInput, FileInput, Textarea, Select } from "flowbite-react";
import SidebarPartner from "../components/sidebar/sidebarPartner";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

function AddNewStaff() {
  return (
    <div className="flex h-screen">
      <SidebarPartner className="fixed h-full" />
      <div className="flex flex-col flex-grow p-6 overflow-y-auto">
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center">
            <a href="/list-staff">
              <FontAwesomeIcon icon={faChevronLeft} className="text-lg" />
            </a>
            <div className="mb-2 ml-5">
              <h2 className="text-2xl font-bold leading-9 tracking-tight text-gray-800">Add Staff Form</h2>
              <p className="text-xs text-gray-600">This is a form for adding staff </p>
            </div>
          </div>
        </div>
        <hr className="border-gray-400 mb-4" />
        <div className="flex flex-col mt-4 lg:flex-row lg:gap-6">
          <div className="flex flex-col gap-4 w-full lg:w-1/2">
            <div>
              <Label htmlFor="decrepitName1" value="Name" />
              <TextInput id="decrepitName1" type="text" className="mt-2" required />
            </div>
            <div>
              <Label htmlFor="nameChild1" value="Email" />
              <TextInput id="nameChild1" type="text" className="mt-2" required />
            </div>
            <div>
              <Label htmlFor="age1" value="Date of Birth" />
              <TextInput id="age1" type="text" className="mt-2" required />
            </div>
            <div>
              <Label htmlFor="age1" value="Sex" />
              <Select id="sex" required>
                <option>Male</option>
                <option>Female</option>
              </Select>
            </div>
            <div>
              <Label htmlFor="address1" value="Address" />
              <Textarea id="comment" required rows={5} className="mt-2" />
            </div>
          </div>
          <div className="flex flex-col gap-4 w-full lg:w-1/2">
            <div>
              <Label htmlFor="age1" value="Email" />
              <TextInput id="age1" type="text" className="mt-2" required />
            </div>
            <Label htmlFor="age1" value="Member Picture" />
            <Label
              htmlFor="dropzone-file"
              className="flex h-54 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:border-gray-500 dark:hover:bg-gray-600"
            >
              <div className="flex flex-col items-center justify-center pb-6 pt-5">
                <svg
                  className="mb-4 h-8 w-8 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 16"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                  />
                </svg>
                <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                  <span className="font-semibold">Click to upload</span> or drag and drop
                </p>
                <p className="text-xs text-gray-500 dark:text-gray-400">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
              </div>
              <FileInput id="dropzone-file" className="hidden" />
            </Label>
            <div>
              <Label htmlFor="dateAdded1" value="Date Added" />
              <TextInput id="dateAdded1" type="text" className="mt-2" required />
            </div>
          </div>
        </div>
        <Button type="submit" className="mt-20 w-[20vw] bg-green-600 text-white hover:bg-green-200">Save</Button>
      </div>
    </div>
  );
}

export default AddNewStaff;
