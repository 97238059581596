"use client";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

function NavbarRating() {
  const navigate = useNavigate();

  const handleNavigateToHomeMeal = () => {
    navigate('/home-meal'); // Mengarahkan ke halaman home meal
  };

  return (
    <div className="flex justify-between items-center p-2 shadow-md">
      <div className="relative">
        <div
          className="flex items-center justify-center w-10 h-10 ml-6 rounded-full transition-all duration-300 hover:bg-gray-200 cursor-pointer"
          onClick={handleNavigateToHomeMeal} // Event handler untuk tanda panah ke kiri
        >
          <FontAwesomeIcon icon={faArrowLeft} className="text-gray-600 text-xl" />
        </div>
      </div>
      <div className="flex-grow text-center">
        <img src="/img/logo.png" alt="Meals to Heals" className="h-12 inline-block" />
      </div>
      <div className="relative">
        <div
          className="flex items-center justify-center w-10 h-10 mr-6 rounded-full transition-all duration-300 hover:bg-gray-200 cursor-pointer"
          onClick={handleNavigateToHomeMeal} // Event handler untuk tanda X
        >
          <FontAwesomeIcon icon={faXmark} className="text-gray-600 text-xl" />
        </div>
      </div>
    </div>
  );
}

export default NavbarRating;