"use client";

import React, { useState, useEffect } from "react";
import { Label, Button } from "flowbite-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import SidebarAdmin from "../components/sidebar/sidebarAdmin";

async function fetchMemberDetail(memberId) {
	try {
		const response = await fetch(
			`https://meals-api.103.127.137.58.nip.io/api/v1/members/raw/${memberId}`,
		); // Ganti dengan URL backend Anda
		const result = await response.json();
		if (result.status === "success") {
			return result.data;
		} else {
			throw new Error(result.message);
		}
	} catch (error) {
		console.error("Error fetching member detail:", error);
		return null;
	}
}

function AdminDetailMember() {
	const [member, setMember] = useState(null);
	const memberId = "id-member-yang-sesuai"; // Ganti dengan logika pengambilan ID member yang sesuai

	useEffect(() => {
		const getMemberDetail = async () => {
			const fetchedMember = await fetchMemberDetail(memberId);
			setMember(fetchedMember);
		};

		getMemberDetail();
	}, [memberId]);

	if (!member) {
		return <p>Loading...</p>;
	}

	return (
		<div className="flex h-screen">
			<SidebarAdmin className="fixed h-full" />
			<div className="flex flex-col flex-grow p-6 overflow-y-auto">
				<div className="flex items-center mb-4">
					<a href="/admin-list-member">
						<FontAwesomeIcon icon={faChevronLeft} className="text-lg" />
					</a>
					<div className="mb-2 ml-10">
						<h2 className="text-2xl font-bold leading-9 tracking-tight text-gray-800">
							Detail Member
						</h2>
						<p className="text-xs text-gray-600">This is a detail of members</p>
					</div>
				</div>
				<hr className="border-gray-400 mb-4" />
				<div className="flex justify-between items-center mb-4">
					<p className="font-semibold ml-8">
						{new Date(member.created_at).toLocaleDateString()}
					</p>
					<div className="flex items-center space-x-2 mr-4">
						<a
							href="#"
							className="font-medium text-cyan-500 dark:text-cyan-500"
						>
							Edit
						</a>
						<p className="text-cyan-500 font-semibold">|</p>
						<a
							href="#"
							className="font-medium text-cyan-500 dark:text-cyan-500"
						>
							Delete
						</a>
					</div>
				</div>
				<div className="grid grid-cols-2 gap-4 mb-4 mt-4">
					<div>
						<Label htmlFor="name" className="ml-8 font-semibold text-md">
							Name
						</Label>
						<p className="text-sm ml-8 mt-2">{member.user.name}</p>
					</div>
					<div>
						<Label htmlFor="sex" className="ml-8 font-semibold text-md">
							Sex
						</Label>
						<p className="text-sm ml-8 mt-2">{member.user.sex}</p>
					</div>
					<div className="mt-8">
						<Label htmlFor="email" className="ml-8 font-semibold text-md">
							Email
						</Label>
						<p className="text-sm ml-8 mt-2">{member.user.email}</p>
					</div>
					<div className="mt-8">
						<Label htmlFor="dob" className="ml-8 font-semibold text-md">
							Date of Birth
						</Label>
						<p className="text-sm ml-8 mt-2">
							{new Date(member.user.dob).toLocaleDateString()}
						</p>
					</div>
					<div className="mt-8">
						<Label htmlFor="age" className="ml-8 font-semibold text-md">
							Age
						</Label>
						<p className="text-sm ml-8 mt-2">{member.user.age} Years Old</p>
					</div>
					<div className="mt-8">
						<Label htmlFor="address" className="ml-8 font-semibold text-md">
							Address
						</Label>
						<p className="text-sm ml-8 mt-2">{member.user.address}</p>
					</div>
				</div>
				<div className="mb-4 mt-8">
					<Label
						htmlFor="allergies-illnesses"
						className="ml-8 font-semibold text-md"
					>
						Allergies or Illnesses
					</Label>
					<div className="grid grid-cols-2 gap-4 mt-4">
						<div className="ml-8">
							<Label htmlFor="allergies" className="font-semibold text-md">
								Allergies
							</Label>
							{member.allergies.length > 0 ? (
								member.allergies.map((allergy, index) => (
									<p key={index} className="mt-2 text-sm">
										{index + 1}. {allergy}
									</p>
								))
							) : (
								<p className="mt-2 text-sm">None</p>
							)}
						</div>
						<div className="ml-8">
							<Label htmlFor="illnesses" className="font-semibold text-md">
								Illnesses
							</Label>
							{member.illnesses.length > 0 ? (
								member.illnesses.map((illness, index) => (
									<p key={index} className="mt-2 text-sm">
										{index + 1}. {illness}
									</p>
								))
							) : (
								<p className="mt-2 text-sm">None</p>
							)}
						</div>
					</div>
				</div>
				<Button type="submit" className="submit max-w-xs mt-16 ml-8">
					Save
				</Button>
			</div>
		</div>
	);
}

export default AdminDetailMember;
