"use client";

import { Button, Checkbox, Label, TextInput, Select } from "flowbite-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import "./register.css";
import { useState } from "react";
import NavbarRegis from "../components/navbar/navbarRegis";

function Caregiver() {
	const [show, setShow] = useState(false);
	const handleClick = () => {
		setShow(!show);
	};

	const [showConfirm, setShowConfirm] = useState(false);
	const confirmClick = () => {
		setShowConfirm(!showConfirm);
	};

	return (
		<form>
			<NavbarRegis />
			<div className="flex max-w-md flex-col gap-4 mx-auto mt-16 mb-14">
				<div>
					<h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-gray-800">
						Sign Up for App
					</h2>
				</div>
				<div className="flex">
					<div className="c-name mr-2">
						<Label htmlFor="name" value="Name" />
						<TextInput id="name" className="mt-2" required />
					</div>
					<div className="c-username">
						<Label htmlFor="username" value="Username" />
						<TextInput id="username" className="mt-2" required />
					</div>
				</div>
				<div className="flex">
					<div className="c-email mr-2">
						<Label htmlFor="email" value="Email" />
						<TextInput id="email" className="mt-2" required />
					</div>
					<div className="c-caregiver">
						<Label htmlFor="caregiver" value="Caregiver" />
						<Select id="caregiver" className="mt-2" required>
							<option>Personal</option>
							<option>Member</option>
							<option>Company</option>
						</Select>
					</div>
				</div>
				<div>
					<div className="mb-2 block">
						<Label htmlFor="password1" value="Password" />
					</div>
					<div className="flex">
						<input
							id="password1"
							type={show ? "text" : "password"}
							className="pass text-sm py-3 w-full focus:border-cyan-500 focus:ring-cyan-500"
							placeholder="8+ characters"
							required
						/>
						<p
							onClick={handleClick}
							className="item-center ml-[-5vh] mt-3 cursor-pointer text-gray-400"
						>
							{show ? (
								<FontAwesomeIcon icon={faEyeSlash} />
							) : (
								<FontAwesomeIcon icon={faEye} />
							)}
						</p>
					</div>
				</div>
				<div>
					<div className="mb-2 block">
						<Label htmlFor="password2" value="Confirmation Password" />
					</div>
					<div className="flex">
						<input
							id="password2"
							type={showConfirm ? "text" : "password"}
							className="confirm text-sm py-3 w-full focus:border-cyan-500 focus:ring-cyan-500"
							placeholder="8+ characters"
							required
						/>
						<p
							onClick={confirmClick}
							className="item-center ml-[-5vh] mt-3 cursor-pointer text-gray-400"
						>
							{showConfirm ? (
								<FontAwesomeIcon icon={faEyeSlash} />
							) : (
								<FontAwesomeIcon icon={faEye} />
							)}
						</p>
					</div>
				</div>
				<div className="flex items-center max-w-xl -mt-2">
					<div className="remember cursor-pointer">
						<Checkbox id="remember" className="cursor-pointer" />
						<Label htmlFor="remember" className="ml-2 text-xs cursor-pointer">
							I Agree
						</Label>
					</div>
					<div className="policy">
						<Label
							htmlFor="policy"
							className="ml-2 text-xs text-cyan-600 dark:text-cyan-500 cursor-pointer"
						>
							Privacy Policy
						</Label>
					</div>
				</div>
				<div className="text-xs text-center mt-4">
					<p>
						By clicking "I Agree" you agree to the application TOS <br />
						and Privacy Policy
					</p>
				</div>
				<Button
					type="submit"
					className="bg-green-600 text-white hover:bg-green-200"
				>
					Submit
				</Button>
				<div className="text-sm text-center -mt-2">
					<p>
						Already have an account?
						<a
							href="#"
							className="ml-1 font-semibold text-cyan-600 dark:text-cyan-500 cursor-pointer"
						>
							Log In
						</a>
					</p>
				</div>
			</div>
		</form>
	);
}

export default Caregiver;
