"use client";

import { Label, Button } from "flowbite-react";
import SidebarOrg from "../components/sidebar/sidebarOrg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

function DetailMember() {

  return (
    <div className="flex h-screen">
      <SidebarOrg className="fixed h-full" />
      <div className="flex flex-col flex-grow p-6 overflow-y-auto">
        <div className="flex items-center mb-4">
          <a href="/list-member">
            <FontAwesomeIcon icon={faChevronLeft} className="text-lg" />
          </a>
          <div className="mb-2 ml-10">
            <h2 className="text-2xl font-bold leading-9 tracking-tight text-gray-800">Detail Member</h2>
            <p className="text-xs text-gray-600">This is a detail of members in your organization</p>
          </div>
        </div>
        <hr className="border-gray-400 mb-4"/>
        <div className="flex justify-between items-center mb-4">
          <p className="font-semibold ml-8">23 Juli 2023</p>
          <div className="flex items-center space-x-2 mr-4">
            <a href="#" className="font-medium text-cyan-500 dark:text-cyan-500">Edit</a>
            <p className="text-cyan-500 font-semibold">|</p>
            <a href="#" className="font-medium text-cyan-500 dark:text-cyan-500">Delete</a>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4 mb-4 mt-4">
          <div>
            <Label htmlFor="name" className="ml-8 font-semibold text-md">Name</Label>
            <p className="text-sm ml-8 mt-2">Bambang Siswanto</p>
          </div>
          <div>
            <Label htmlFor="sex" className="ml-8 font-semibold text-md">Sex</Label>
            <p className="text-sm ml-8 mt-2">Male</p>
          </div>
          <div className="mt-8">
            <Label htmlFor="email" className="ml-8 font-semibold text-md">Email</Label>
            <p className="text-sm ml-8 mt-2">bambangsuswinto@gmail.com</p>
          </div>
          <div className="mt-8">
            <Label htmlFor="email" className="ml-8 font-semibold text-md">Date of Birth</Label>
            <p className="text-sm ml-8 mt-2">16 April 1956</p>
          </div>
          <div className="mt-8">
            <Label htmlFor="email" className="ml-8 font-semibold text-md">Age</Label>
            <p className="text-sm ml-8 mt-2">65 Years Old</p>
          </div>
          <div className="mt-8">
            <Label htmlFor="address" className="ml-8 font-semibold text-md">Address</Label>
            <p className="text-sm ml-8 mt-2">Jl. Raya Puputan No.86, Dangin Puri Klod, Kec. Denpasar Tim., Kota Denpasar, Bali 80234</p>
          </div>
        </div>
        <div className="mb-4 mt-8">
          <Label htmlFor="allergies-illnesses" className="ml-8 font-semibold text-md">Allergies or Illnesses</Label>
          <div className="grid grid-cols-2 gap-4 mt-4">
            <div className="ml-8">
              <Label htmlFor="allergies" className="font-semibold text-md">Allergies</Label>
              <p className="mt-2 text-sm">1. Daun bawang</p>
              <p className="mt-2 text-sm">2. Penyedap rasa terutama msg</p>
            </div>
            <div className="ml-8">
              <Label htmlFor="illnesses" className="font-semibold text-md">Illnesses</Label>
              <p className="mt-2 text-sm">1. Tidak bisa makan telor</p>
              <p className="mt-2 text-sm">2. Tidak bisa makan nasi yang terlalu keras atau terlalu lembek</p>
            </div>
          </div>
        </div>
        <div className="ml-8 mt-16 mb-8">
          <Button type="submit" className="w-[14vw] bg-green-600 text-white hover:bg-green-200">Save</Button>
        </div>
      </div>
    </div>
  );
}

export default DetailMember;