"use client";

import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouse,
  faBurger,
  faCartShopping,
  faMoneyBill,
  faAddressBook,
  faUser,
  faSignOut,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { getAuthAndProfileResponses } from "../../utils/localstorage";
import { Tooltip } from "react-tooltip";
import axios from "axios";

export function SidebarMember() {
  const { authResponse, _ } = getAuthAndProfileResponses();
  const navigate = useNavigate();
  const location = useLocation();

  const accessToken = authResponse["access-token"];
  const refreshToken = authResponse["refresh-token"];

  const [profileResponse, setProfileResponse] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const menuItems = [
    { name: "Home", icon: faHouse, link: "/home-meal" },
    // { name: "Meal", icon: faBurger, link: "/menu-meal" },
    { name: "Caregiver", icon: faUser, link: "/member-caregiver-profile" },
    { name: "Order", icon: faMoneyBill, link: "/delivery" },
    { name: "Cart", icon: faCartShopping, link: "/cart" },
    // { name: "Contact", icon: faAddressBook, link: "/contact-us" },
  ];

  const fetchProfileData = async () => {
    try {
      setIsLoading(true);
      await axios
        .get(process.env.REACT_APP_API_BASE_URL + "profiles/me", {
          headers: {
            Authorization: `Bearer ${authResponse.token}`,
            "Access-Token": `${accessToken}`,
            "Refresh-Token": `${refreshToken}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setProfileResponse(response?.data?.data);
        });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const validateLogin = () => {
      if (!authResponse || !profileResponse) {
        navigate("/signin");
      }
    };

    validateLogin();
  }, [authResponse, profileResponse, navigate]);

  useEffect(() => {
    fetchProfileData();
  }, []);

  const onRedirectLogout = async () => {
    if (window.confirm("Are you sure you want to logout?")) {
      localStorage.clear();
      navigate("/");
    }
  };

  const wrapText = (text) => {
    if (text?.length > 13) {
      return text.slice(0, 13) + "...";
    }
    return text;
  };

  return (
    <div className="shrink-0 w-64 h-screen bg-gray-200 text-gray flex flex-col justify-between">
      <div>
        <div className="flex justify-center items-center py-6">
          <img
            src="/img/logo.png"
            alt="Meals to Heals"
            className="h-auto w-56"
          />
        </div>
        <div className="space-y-4">
          {menuItems.map((item) => (
            <Link
              to={item.link}
              key={item.name}
              className={`flex items-center px-6 py-2 rounded-sm cursor-pointer ${
                location.pathname === item.link
                  ? "bg-green-500 text-white"
                  : "hover:bg-green-500 hover:text-white"
              }`}
            >
              <FontAwesomeIcon icon={item.icon} className="mr-3" />
              <span className="text-lg">{item.name}</span>
            </Link>
          ))}
          <div
            className={`flex items-center px-6 py-2 rounded-sm cursor-pointer ${
              location.pathname === "/logout"
                ? "bg-green-500 text-white"
                : "hover:bg-green-500 hover:text-white"
            }`}
            onClick={onRedirectLogout}
          >
            <FontAwesomeIcon icon={faSignOut} className="mr-3" />
            <span className="text-lg">Logout</span>
          </div>
        </div>
      </div>
      <div className="mb-6 flex items-center px-6">
        <div className="shrink-0 h-10 w-10 aspect-square">
          <Link to="/member-own-profile">
            <img
              className="h-full w-full rounded-full"
              src={profileResponse?.user?.image?.image?.path}
              alt={wrapText(
                profileResponse?.first_name
                  ? profileResponse?.first_name +
                      " " +
                      profileResponse?.last_name
                  : authResponse?.email
              )}
            />
          </Link>
        </div>
        <div className="ml-3 relative group">
          <p className="text-base font-semibold leading-none">
            <Link
              to="/member-own-profile"
              data-tooltip-id="my-tooltip"
              data-tooltip-content={
                profileResponse?.first_name
                  ? profileResponse?.first_name +
                    " " +
                    profileResponse?.last_name
                  : authResponse?.email
              }
            >
              {wrapText(
                profileResponse?.first_name
                  ? profileResponse?.first_name +
                      " " +
                      profileResponse?.last_name
                  : authResponse?.email
              )}
            </Link>
          </p>
          <Tooltip id="my-tooltip" className="max-w-72 !rounded-md" />
        </div>
      </div>
    </div>
  );
}

export default SidebarMember;
