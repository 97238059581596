"use client";

import { Button } from "flowbite-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getRegisterResponse } from "../utils/localstorage";
import axios from "axios";

function VerifyEmail() {
  const [otp, setOtp] = useState(new Array(8).fill(""));
  const navigate = useNavigate();
  const lsRegisterResponse = getRegisterResponse();
  const registerResponse = lsRegisterResponse.registerResponse;

  const accessToken = registerResponse["access-token"];
  const refreshToken = registerResponse["refresh-token"];

  function handleChange(e, index) {
    const value = e.target.value;
    if (!value.match(/^[a-zA-Z0-9]*$/)) return; // hanya menerima angka dan huruf
    setOtp([...otp.map((data, indx) => (indx === index ? value : data))]);

    if (value && e.target.nextSibling) {
      e.target.nextSibling.focus();
    }
  }

  function handlePaste(e) {
    let paste = e.clipboardData.getData("text");
    paste = paste.replace(/-/g, ""); // Menghapus tanda "-" jika ada

    if (paste.length <= otp.length && paste.match(/^[a-zA-Z0-9]*$/)) {
      setOtp([
        ...paste
          .split("")
          .map((char, index) => (index < otp.length ? char : otp[index])),
      ]);
      e.preventDefault();
    }
  }

  function handleKeyDown(e, index) {
    if (e.key === "Backspace" || e.key === "Delete") {
      setOtp([...otp.map((data, indx) => (indx === index ? "" : data))]);

      if (e.target.previousSibling && !otp[index]) {
        e.target.previousSibling.focus();
      }
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    const verificationCode = otp.join("");

    const midpoint = Math.ceil(verificationCode.length / 2);

    // Split the string into two parts
    const firstPart = verificationCode.substring(0, midpoint);
    const secondPart = verificationCode.substring(midpoint);

    // Combine the parts with a dash
    const joinedCode = `${firstPart}-${secondPart}`;

    const payload = {
      token: joinedCode,
      email: registerResponse?.email,
    };

    try {
      await axios
        .post(process.env.REACT_APP_API_BASE_URL + "auth/verify", payload, {
          headers: {
            Authorization: `Bearer ${registerResponse.token}`,
            "Access-Token": `${accessToken}`,
            "Refresh-Token": `${refreshToken}`,
            "Content-Type": "application/json",
          },
        })
        .then((verifyResponse) => {
          axios
            .get(process.env.REACT_APP_API_BASE_URL + "profiles/me", {
              headers: {
                Authorization: `Bearer ${verifyResponse?.data?.data?.token}`,
                "Access-Token": `${verifyResponse?.headers["access-token"]}`,
                "Refresh-Token": `${verifyResponse?.headers["refresh-token"]}`,
                "Content-Type": "application/json",
              },
            })
            .then((profileResponse) => {
              console.log(verifyResponse);
              // * set auth authResponse to localStorage
              localStorage.setItem(
                "authResponse",
                JSON.stringify({
                  ...verifyResponse?.data?.data,
                  "access-token": verifyResponse?.headers["access-token"],
                  "refresh-token": verifyResponse?.headers["access-token"],
                })
              );

              // * set profile data to localStorage
              localStorage.setItem(
                "profileResponse",
                JSON.stringify(profileResponse.data.data)
              );
              // Redirect berdasarkan role
              switch (registerResponse?.role) {
                case 1:
                  navigate("/home-admin");
                  break;
                case 2:
                case 3:
                case 6:
                  navigate("/home-meal");
                  break;
                case 4:
                  navigate("/home-partner");
                  break;
                case 5:
                  navigate("/home-patron");
                  break;
                default:
                  navigate("/signin");
              }
            });
        });
    } catch (error) {
      console.log(error);
      alert("Something went wrong. Please try again later.");
    }
  }

  return (
    <form
      className="flex max-w-2xl flex-col gap-4 mx-auto mt-36"
      onSubmit={handleSubmit}
    >
      <div>
        <div className="flex mb-3">
          <FontAwesomeIcon icon={faArrowLeft} />
          <a href="/role" className="ml-2 text-sm">
            Back
          </a>
        </div>
        <h2 className="text-2xl font-bold leading-9 tracking-tight text-gray-800">
          Verification Code
        </h2>
        <p className="mt-4 mb-4 text-sm">
          We sent a code to {registerResponse?.email}
        </p>
      </div>
      <div onPaste={handlePaste}>
        {otp.slice(0, 4).map((data, i) => (
          <input
            key={i}
            type="text"
            value={data}
            maxLength={1}
            onChange={(e) => handleChange(e, i)}
            onKeyDown={(e) => handleKeyDown(e, i)}
            className="otp"
          />
        ))}
        <span className="mx-2">-</span>
        {otp.slice(4, 8).map((data, i) => (
          <input
            key={i + 4}
            type="text"
            value={data}
            maxLength={1}
            onChange={(e) => handleChange(e, i + 4)}
            onKeyDown={(e) => handleKeyDown(e, i + 4)}
            className="otp"
          />
        ))}
      </div>
      <div>
        <p className="mt-4 text-sm mb-4">
          Didn't receive the email?
          <a
            href="#"
            className="ml-1 font-semibold text-cyan-600 dark:text-cyan-500 cursor-pointer"
          >
            Click to resend
          </a>
        </p>
      </div>
      <Button
        type="submit"
        className="w-[30vh] bg-green-600 text-white hover:bg-green-200"
      >
        Submit
      </Button>
    </form>
  );
}

export default VerifyEmail;
