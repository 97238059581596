import { Button, Label, TextInput, FileInput, Select, Textarea } from "flowbite-react";
import NavbarRating from "../components/navbar/navbarRating";
import { useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import NavbarPatron from "../components/navbar/navbarPatron";

function UploadBukti() {
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [preview, setPreview] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const donationAmount = location.state?.donationAmount || "Rp. 10.000.000";

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setFileName(selectedFile.name);
    const fileReader = new FileReader();
    fileReader.onload = () => {
      setPreview(fileReader.result);
    };
    fileReader.readAsDataURL(selectedFile);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const donationAmount = document.getElementById('donation').value; // Ambil nilai donasi
    localStorage.setItem('donationAmount', donationAmount); // Simpan di localStorage
    const isSuccess = Math.random() > 0.5;
    if (isSuccess) {
      navigate('/ringkasan-donasi');
    } else {
      navigate('/error');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <NavbarPatron />
      <div className="flex flex-col max-w-7xl mx-auto mt-8 items-center p-4 text-center">
        <h2 className="text-2xl font-bold leading-9 tracking-tight text-gray-800 mb-4">Complete Your Donation</h2>
        <p className="mb-8 max-w-2xl">Your donation is being processed. Please upload proof of payment to complete your donation.</p>
      </div>
      <div className="flex flex-col md:flex-row max-w-7xl mx-auto mt-6 mb-16 items-start p-4 space-y-4 md:space-y-0 md:space-x-8">
        <div className="flex justify-center md:w-1/2">
          <img src="/img/pay.svg" alt="Donation" className="h-80 w-auto mb-4 md:mb-0" />
        </div>
        <div className="md:w-1/2 space-y-4">
          <div className="flex space-x-4">
            <div className="flex-1">
              <Label htmlFor="name" value="Name" />
              <TextInput id="name" type="text" required className="mt-2" />
            </div>
            <div className="flex-1">
              <Label htmlFor="email" value="Email" />
              <TextInput id="email" type="email" required className="mt-2" />
            </div>
          </div>
          <div className="flex space-x-4">
            <div className="flex-1">
              <Label htmlFor="sex" value="Sex" />
              <Select id="sex" required className="mt-2">
                <option>Female</option>
                <option>Male</option>
              </Select>
            </div>
            <div className="flex-1">
              <Label htmlFor="donation" value="Donation" />
              <TextInput id="donation" type="text" value={donationAmount} disabled readOnly className="mt-2" />
            </div>
          </div>
          <div>
            <Label htmlFor="address" value="Address" />
            <TextInput id="address" type="text" required className="mt-2" />
          </div>
          <div className="flex items-center space-x-4">
            <div className="w-1/2">
              <Label htmlFor="upload" value="Upload Image" />
              <Label
                htmlFor="dropzone-file"
                className="flex h-[16vh] w-[18vw] cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:border-gray-500 dark:hover:bg-gray-600 mt-2"
              >
                <div className="flex flex-col items-center justify-center pb-6 pt-5">
                  <svg
                    className="mb-2 h-8 w-8 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 16"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                    />
                  </svg>
                  <p className="mb-2 text-xs text-gray-500 dark:text-gray-400">
                    <span className="font-semibold">Click to upload</span> or drag and drop
                  </p>
                  <p className="text-[10px] text-gray-500 dark:text-gray-400 -mt-2">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
                </div>
                <FileInput id="dropzone-file" onChange={handleFileChange} className="hidden" />
              </Label>
            </div>
            {preview && (
              <div className="w-1/2 mt-4 md:mt-0">
                <img src={preview} alt="Uploaded Preview" className="rounded-lg shadow-md" />
                <p className="mt-2 text-gray-600 text-sm">{fileName}</p>
              </div>
            )}
          </div>
          <div>
            <Label htmlFor="comment" value="Message" />
            <Textarea id="comment" placeholder="Leave a comment..." required rows={4} className="mt-2 mb-12" />
          </div>
          <Button type="submit" className="w-full bg-green-600 text-white hover:bg-green-200">Submit</Button>
        </div>
      </div>
    </form>
  );
}

export default UploadBukti;