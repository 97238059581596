"use client";

import { Button, Label } from "flowbite-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faArrowLeft,
	faEye,
	faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import "./forgotPass.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

function ResetPass() {
	const [show, setShow] = useState(false);
	const [showConfirm, setShowConfirm] = useState(false);
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [errorMessage, setErrorMessage] = useState("");
	const navigate = useNavigate(); // Initialize useNavigate for navigation

	const handleClick = () => {
		setShow(!show);
	};

	const confirmClick = () => {
		setShowConfirm(!showConfirm);
	};

	const handleSubmit = async (event) => {
		event.preventDefault();

		if (password !== confirmPassword) {
			setErrorMessage("Passwords do not match");
			return;
		}

		try {
			const response = await fetch(
				"  https://meals-api.103.127.137.58.nip.io/api/v1/auth/reset-password",
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						email: "jonathanvnc@gmail.com",
						password: password,
						confirm_password: confirmPassword,
						token: "X9R0-U61O",
					}),
				},
			);

			const data = await response.json();

			if (data.status === "success") {
				alert(data.message);
				navigate("/send-code"); // Redirect to send code
			} else {
				setErrorMessage(data.message);
			}
		} catch (error) {
			setErrorMessage("An error occurred while resetting the password");
		}
	};

	return (
		<form
			className="flex max-w-xl flex-col gap-4 mx-auto mt-32"
			onSubmit={handleSubmit}
		>
			<div>
				<a href="/input-email" className="flex mb-3">
					<FontAwesomeIcon icon={faArrowLeft} />
					<p className="ml-2 text-sm">Back</p>
				</a>
				<h2 className="text-2xl font-bold leading-9 tracking-tight text-gray-800">
					Password Reset
				</h2>
			</div>
			<div>
				<div className="mb-2 block">
					<Label htmlFor="password" value="Password" />
				</div>
				<div className="flex">
					<input
						id="password"
						name="password"
						type={show ? "text" : "password"}
						className="pass text-sm py-3 w-[100%] focus:border-cyan-500 focus:ring-cyan-500"
						placeholder="8+ characters"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
						required
					/>
					<p
						onClick={handleClick}
						className="item-center ml-[-5vh] mt-3 cursor-pointer text-gray-400"
					>
						{show ? (
							<FontAwesomeIcon icon={faEyeSlash} />
						) : (
							<FontAwesomeIcon icon={faEye} />
						)}
					</p>
				</div>
				<p className="mt-4 mb-4 text-sm">It must be at least 8 characters</p>
			</div>
			<div>
				<div className="mb-2 block">
					<Label htmlFor="confirmPassword" value="Confirmation Password" />
				</div>
				<div className="flex">
					<input
						id="confirmPassword"
						name="confirmPassword"
						type={showConfirm ? "text" : "password"}
						className="confirm text-sm py-3 w-[100%] focus:border-cyan-500 focus:ring-cyan-500"
						placeholder="8+ characters"
						value={confirmPassword}
						onChange={(e) => setConfirmPassword(e.target.value)}
						required
					/>
					<p
						onClick={confirmClick}
						className="item-center ml-[-5vh] mt-3 cursor-pointer text-gray-400"
					>
						{showConfirm ? (
							<FontAwesomeIcon icon={faEyeSlash} />
						) : (
							<FontAwesomeIcon icon={faEye} />
						)}
					</p>
				</div>
			</div>
			{errorMessage && <p className="text-red-500 text-sm">{errorMessage}</p>}
			<Button
				type="submit"
				className="w-[30vh] bg-green-600 text-white hover:bg-green-200"
			>
				Submit
			</Button>
		</form>
	);
}

export default ResetPass;
