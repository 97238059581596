export function getAuthAndProfileResponses() {
  const authResponse = JSON.parse(localStorage.getItem("authResponse"));
  const profileResponse = JSON.parse(localStorage.getItem("profileResponse"));

  return {
    authResponse,
    profileResponse,
  };
}

export function getRegisterResponse() {
  const registerResponse = JSON.parse(localStorage.getItem("registerResponse"));

  return {
    registerResponse,
  };
}
