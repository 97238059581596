"use client";

import { Card } from "flowbite-react";
import NavbarLanding from "../components/navbar/navbarLanding";

function AboutUs() {
  const images = [
    "/img/chef-3.jpeg",
    "/img/chef-1.jpeg",
    "/img/chef-2.jpeg",
    "/img/chef-3.jpeg",
    "/img/chef-4.jpeg",
    "/img/chef-5.jpeg",
    "/img/chef-2.jpeg",
    "/img/chef-5.jpeg",
    "/img/chef-1.jpeg",
    "/img/chef-4.jpeg",
  ];

  const namesAndTitles = [
    { name: "Jack Hummber", title: "Chief Financial Officer" },
    { name: "Sarah Smith", title: "Chief Executive Officer" },
    { name: "Michael Brown", title: "Chief Operations Officer" },
    { name: "Emily Davis", title: "Chief Marketing Officer" },
    { name: "David Wilson", title: "Chief Technology Officer" },
    { name: "Jessica Taylor", title: "Chief Human Resources Officer" },
    { name: "Daniel Anderson", title: "Chief Product Officer" },
    { name: "Sophia Thomas", title: "Chief Sales Officer" },
    { name: "Matthew Lee", title: "Chief Information Officer" },
    { name: "Olivia Harris", title: "Chief Customer Officer" },
  ];

  return (
    <form>
      <NavbarLanding />
      <div className="flex max-w-6xl flex-col gap-4 mx-auto mt-20 items-center mb-16">
        <div>
          <h2 className="text-3xl mb-4 text-center font-bold leading-9 tracking-tight text-gray-800">
            We are the people who make up Untitled
          </h2>
        </div>
        <div className="grid grid-cols-5 gap-4">
          {images.map((src, index) => (
            <Card
              key={index}
              className="max-w-xs"
              imgAlt={`Meaningful alt text for image ${index + 1}`}
              imgSrc={src}
            >
              <h5 className="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                {namesAndTitles[index].name}
              </h5>
              <p className="font-normal text-sm -mt-3 text-gray-700 dark:text-gray-400">
                {namesAndTitles[index].title}
              </p>
            </Card>
          ))}
        </div>
      </div>
    </form>
  );
}

export default AboutUs;