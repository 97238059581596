"use client";

import { Checkbox, Table } from "flowbite-react";
import SidebarPartner from "../components/sidebar/sidebarPartner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlass,
  faFilter,
  faArrowUpAZ,
  faPlus,
  faSquareMinus,
  faAnglesRight,
  faAnglesLeft,
} from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

function OrderOrg() {
  const [currentPage, setCurrentPage] = useState(1);
  const dataPerPage = 10;
  const [searchTerm, setSearchTerm] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [selectedItems, setSelectedItems] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const navigate = useNavigate();

  const initialOrders = [
    {
      id: 1,
      name: "Bambang Nurwanto",
      email: "bambang@gmail.com",
      date: "16 April 2024",
      menu: "Lontong sayur, Telor Balado",
      total: "20 Orders",
      status: "Prepared",
    },
    {
      id: 2,
      name: "Ningning Angela",
      email: "ningning@gmail.com",
      date: "16 April 2024",
      menu: "Lontong sayur, Telor Balado",
      total: "20 Orders",
      status: "Prepared",
    },
    {
      id: 3,
      name: "Jack Hummer",
      email: "jack@gmail.com",
      date: "16 April 2024",
      menu: "Lontong sayur, Telor Balado",
      total: "20 Orders",
      status: "Prepared",
    },
    // Add more orders as needed
  ];

  const statuses = [
    { status: "Placed", color: "bg-blue-100 text-blue-800" },
    { status: "Confirmed", color: "bg-yellow-100 text-yellow-800" },
    { status: "Being Prepared", color: "bg-green-100 text-green-800" },
    { status: "Prepared", color: "bg-red-100 text-red-800" },
    { status: "Picked Up", color: "bg-purple-100 text-purple-800" },
    { status: "Out for Delivery", color: "bg-blue-100 text-blue-800" },
    { status: "Delivered", color: "bg-green-100 text-green-800" },
    { status: "Completed", color: "bg-purple-100 text-purple-800" },
    { status: "Rejected", color: "bg-red-100 text-red-600" },
  ];

  useEffect(() => {
    filterAndSortData(searchTerm, sortOrder);
  }, [searchTerm, sortOrder]);

  const getStatusColor = (status) => {
    const statusObj = statuses.find((s) => s.status === status);
    return statusObj ? statusObj.color : "";
  };

  const handlePageChange = (page) => {
    if (page > 0 && page <= Math.ceil(filteredOrders.length / dataPerPage)) {
      setCurrentPage(page);
    }
  };

  const handleSearch = (event) => {
    const keyword = event.target.value.toLowerCase();
    setSearchTerm(keyword);
  };

  const handleSort = () => {
    const newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    setSortOrder(newSortOrder);
  };

  const handleCheckboxChange = (id) => {
    if (selectedItems.includes(id)) {
      setSelectedItems(selectedItems.filter((item) => item !== id));
    } else {
      setSelectedItems([...selectedItems, id]);
    }
  };

  const filterAndSortData = (searchTerm, sortOrder) => {
    const filtered = initialOrders
      .filter(
        (order) =>
          order.name.toLowerCase().includes(searchTerm) ||
          order.email.toLowerCase().includes(searchTerm) ||
          order.menu.toLowerCase().includes(searchTerm)
      )
      .sort((a, b) => {
        if (sortOrder === "asc") {
          return a.name.localeCompare(b.name);
        } else {
          return b.name.localeCompare(a.name);
        }
      });
    setFilteredOrders(filtered);
  };

  const handleMenuClick = (e, orderId) => {
    e.stopPropagation(); // Prevent row click event
    navigate(`/order-details/${orderId}`);
  };

  const totalPages = Math.ceil(filteredOrders.length / dataPerPage);

  return (
    <div className="flex h-screen">
      <SidebarPartner className="fixed h-full" />
      <div className="flex flex-col flex-grow p-6 overflow-y-auto">
        <div className="flex justify-between items-center mb-4">
          <div className="mb-2">
            <h2 className="text-2xl font-bold leading-9 tracking-tight text-gray-800">
              Order Organization
            </h2>
            <p className="text-xs text-gray-600">
              This is a list of orders in your organization
            </p>
          </div>
          <div className="relative flex items-center">
            <FontAwesomeIcon
              icon={faMagnifyingGlass}
              className="absolute left-3 text-gray-600"
            />
            <input
              type="text"
              className="pl-10 pr-4 py-2 border border-gray-300 rounded-lg text-sm w-80"
              placeholder="Search..."
              value={searchTerm}
              onChange={handleSearch}
            />
          </div>
        </div>
        <hr className="border-gray-400 mb-4" />
        <div className="flex justify-between items-center mb-4">
          <div className="flex items-center space-x-2 border border-gray-300 rounded-lg px-3 py-2 text-sm cursor-pointer">
            <FontAwesomeIcon icon={faFilter} className="text-gray-600" />
            <a href="#" className="text-gray-600">
              Filter
            </a>
            {selectedItems.length > 0 && (
              <div className="flex items-center justify-center w-5 h-5 border rounded-full text-white text-xs bg-green-500 text-semibold">
                {selectedItems.length}
              </div>
            )}
          </div>
          <div
            className="flex items-center space-x-2 border border-gray-300 rounded-lg px-3 py-2 ml-4 text-sm cursor-pointer"
            onClick={handleSort}
          >
            <FontAwesomeIcon icon={faArrowUpAZ} className="text-gray-600" />
            <a href="#" className="text-gray-600">
              Sort
            </a>
          </div>
          <div className="flex-grow"></div>
          <div className="flex items-center space-x-2 border border-gray-300 rounded-lg px-3 py-2 text-sm cursor-pointer">
            <FontAwesomeIcon icon={faPlus} className="text-gray-600" />
            <a href="#" className="text-gray-600">
              Add new
            </a>
          </div>
        </div>
        <div className="overflow-x-auto">
          <Table hoverable>
            <Table.Head>
              <Table.HeadCell className="p-4">
                <FontAwesomeIcon icon={faSquareMinus} className="text-lg" />
              </Table.HeadCell>
              <Table.HeadCell>Name Customer</Table.HeadCell>
              <Table.HeadCell>Status</Table.HeadCell>
              <Table.HeadCell>Time Order</Table.HeadCell>
              <Table.HeadCell>Menu Order</Table.HeadCell>
              <Table.HeadCell>Total</Table.HeadCell>
              <Table.HeadCell>Actions</Table.HeadCell>
            </Table.Head>
            <Table.Body className="divide-y">
              {filteredOrders
                .slice(
                  (currentPage - 1) * dataPerPage,
                  currentPage * dataPerPage
                )
                .map((order) => (
                  <Table.Row
                    key={order.id}
                    className="bg-white dark:border-gray-700 dark:bg-gray-800"
                  >
                    <Table.Cell className="p-4">
                      <Checkbox
                        checked={selectedItems.includes(order.id)}
                        onChange={() => handleCheckboxChange(order.id)}
                      />
                    </Table.Cell>
                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                      <div className="flex">
                        <img
                          src="/img/eng-flag.jpeg"
                          alt="Indonesia"
                          className="h-7 w-7 inline-block rounded-full mr-3 mt-1"
                        />
                        <div>
                          <p className="font-semibold">{order.name}</p>
                          <p className="text-xs">{order.email}</p>
                        </div>
                      </div>
                    </Table.Cell>
                    <Table.Cell>
                      <span
                        className={`p-2 rounded-lg ${getStatusColor(
                          order.status
                        )}`}
                      >
                        {order.status}
                      </span>
                    </Table.Cell>
                    <Table.Cell>{order.date}</Table.Cell>
                    <Table.Cell>
                      <a
                        href="#"
                        className="text-cyan-600"
                        onClick={(e) => handleMenuClick(e, order.id)}
                      >
                        {order.menu}
                      </a>
                    </Table.Cell>
                    <Table.Cell>{order.total}</Table.Cell>
                    <Table.Cell>
                      <span
                        className={`py-1 px-4 text-xs rounded-full font-semibold ${getStatusColor(
                          order.status
                        )}`}
                      >
                        {order.status}
                      </span>
                    </Table.Cell>
                  </Table.Row>
                ))}
            </Table.Body>
          </Table>
        </div>
        <div className="flex justify-center mt-4">
          <div className="flex items-center space-x-1">
            <FontAwesomeIcon
              icon={faAnglesLeft}
              onClick={() => handlePageChange(currentPage - 1)}
              className={`cursor-pointer ${
                currentPage === 1 ? "text-gray-400" : ""
              }`}
            />
            {Array(totalPages)
              .fill()
              .map((_, idx) => (
                <button
                  key={idx}
                  onClick={() => handlePageChange(idx + 1)}
                  className={`px-3 py-1 rounded-lg ${
                    currentPage === idx + 1
                      ? "bg-blue-500 text-white"
                      : "bg-gray-200 text-gray-700"
                  }`}
                >
                  {idx + 1}
                </button>
              ))}
            <FontAwesomeIcon
              icon={faAnglesRight}
              onClick={() => handlePageChange(currentPage + 1)}
              className={`cursor-pointer ${
                currentPage === totalPages ? "text-gray-400" : ""
              }`}
            />
          </div>
        </div>
        <div className="text-sm text-gray-700 text-center mt-4">
          Showing {(currentPage - 1) * dataPerPage + 1} to{" "}
          {Math.min(currentPage * dataPerPage, filteredOrders.length)} of{" "}
          {filteredOrders.length} results
        </div>
      </div>
    </div>
  );
}

export default OrderOrg;
