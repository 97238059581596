"use client";

import { Checkbox, Table } from "flowbite-react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faFilter, faArrowUpAZ, faPlus, faSquareMinus, faAnglesRight, faAnglesLeft } from '@fortawesome/free-solid-svg-icons';
import { useState, useMemo, useEffect } from "react";
import SidebarAdmin from "../components/sidebar/sidebarAdmin";
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom

function AdminOrderOrg() {
  const [currentPage, setCurrentPage] = useState(1);
  const dataPerPage = 10;
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedOrders, setSelectedOrders] = useState([]);
  const totalData = 12; // Total data for example
  const totalPages = Math.ceil(totalData / dataPerPage);

  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    // Fetch initial data or use your data source
    const fetchOrders = async () => {
      // Example data, replace with actual data fetch logic
      const fetchedOrders = [
        { id: 1, name: 'Bambang Nurwanto', email: 'bambang@gmail.com', date: '16 April 2024', menu: 'Lontong sayur, Telor Balado', total: '20 Orders', status: 'Prepared' },
        // Add more orders as needed
      ];
      setOrders(fetchedOrders);
    };

    fetchOrders();
  }, []);

  useEffect(() => {
    // Filter orders based on search term
    const filtered = orders.filter(order =>
      order.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      order.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      order.menu.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredOrders(filtered);
  }, [searchTerm, orders]);

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSelectOrder = (id) => {
    setSelectedOrders(prev => {
      if (prev.includes(id)) {
        return prev.filter(selectedId => selectedId !== id);
      }
      return [...prev, id];
    });
  };

  const sortedOrders = useMemo(() => {
    return [...filteredOrders].sort((a, b) => a.name.localeCompare(b.name));
  }, [filteredOrders]);

  const paginatedOrders = useMemo(() => {
    const start = (currentPage - 1) * dataPerPage;
    return sortedOrders.slice(start, start + dataPerPage);
  }, [currentPage, sortedOrders]);

  const getStatusColor = (status) => {
    const statuses = [
      { status: 'Placed', color: 'bg-blue-100 text-blue-800' },
      { status: 'Confirmed', color: 'bg-yellow-100 text-yellow-800' },
      { status: 'Being Prepared', color: 'bg-green-100 text-green-800' },
      { status: 'Prepared', color: 'bg-red-100 text-red-800' },
      { status: 'Picked Up', color: 'bg-purple-100 text-purple-800' },
      { status: 'Out for Delivery', color: 'bg-blue-100 text-blue-800' },
      { status: 'Delivered', color: 'bg-green-100 text-green-800' },
      { status: 'Completed', color: 'bg-purple-100 text-purple-800' },
      { status: 'Rejected', color: 'bg-red-100 text-red-600' }
    ];
    const statusObj = statuses.find(s => s.status === status);
    return statusObj ? statusObj.color : '';
  };

  const handleMenuClick = (orderId) => {
    navigate(`/admin-detail-org/${orderId}`); // Navigate to the order detail page
  };

  return (
    <div className="flex h-screen">
      <SidebarAdmin className="fixed h-full" />
      <div className="flex flex-col flex-grow p-6 overflow-y-auto">
        <div className="flex justify-between items-center mb-4">
          <div className="mb-2">
            <h2 className="text-2xl font-bold leading-9 tracking-tight text-gray-800">Order Organization</h2>
            <p className="text-xs text-gray-600">This is a list of orders in your organization</p>
          </div>
          <div className="relative flex items-center">
            <FontAwesomeIcon icon={faMagnifyingGlass} className="absolute left-3 text-gray-600"/>
            <input
              type="text"
              className="pl-10 pr-4 py-2 border border-gray-300 rounded-lg text-sm w-80"
              placeholder="Search..."
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
        </div>
        <hr className="border-gray-400 mb-4"/>
        <div className="flex justify-between items-center mb-4">
          <div className="flex items-center space-x-2 border border-gray-300 rounded-lg px-3 py-2 text-sm cursor-pointer">
            <FontAwesomeIcon icon={faFilter} className="text-gray-600"/>
            <a href="#" className="text-gray-600">Filter</a>
            {selectedOrders.length > 0 && (
              <div className="flex items-center justify-center w-5 h-5 border rounded-full text-white text-xs bg-green-500 text-semibold">
                {selectedOrders.length}
              </div>
            )}
          </div>
          <div className="flex items-center space-x-2 border border-gray-300 rounded-lg px-3 py-2 ml-4 text-sm cursor-pointer">
            <FontAwesomeIcon icon={faArrowUpAZ} className="text-gray-600"/>
            <a href="#" className="text-gray-600">Sort</a>
          </div>
          <div className="flex-grow"></div>
          <div className="flex items-center space-x-2 border border-gray-300 rounded-lg px-3 py-2 text-sm cursor-pointer">
            <FontAwesomeIcon icon={faPlus} className="text-gray-600"/>
            <a href="#" className="text-gray-600">Add new</a>
          </div>
        </div>
        <div className="overflow-x-auto">
          <Table hoverable>
            <Table.Head>
              <Table.HeadCell className="p-4">
                <FontAwesomeIcon icon={faSquareMinus} className="text-lg" />
              </Table.HeadCell>
              <Table.HeadCell>Name Customer</Table.HeadCell>
              <Table.HeadCell>Status</Table.HeadCell>
              <Table.HeadCell>Time Order</Table.HeadCell>
              <Table.HeadCell>Menu Order</Table.HeadCell>
              <Table.HeadCell>Total</Table.HeadCell>
              <Table.HeadCell>#</Table.HeadCell>
            </Table.Head>
            <Table.Body className="divide-y">
              {paginatedOrders.length > 0 ? paginatedOrders.map((order) => (
                <Table.Row key={order.id} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                  <Table.Cell className="p-4">
                    <Checkbox
                      checked={selectedOrders.includes(order.id)}
                      onChange={() => handleSelectOrder(order.id)}
                    />
                  </Table.Cell>
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                    <div className="flex">
                      <img src="/img/eng-flag.jpeg" alt="Flag" className="h-7 w-7 inline-block rounded-full mr-3 mt-1" />
                      <div>
                        <p className="font-semibold">{order.name}</p>
                        <p className="text-xs">{order.email}</p>
                      </div>
                    </div>
                  </Table.Cell>
                  <Table.Cell>
                    <span className={`p-2 rounded-lg ${getStatusColor(order.status)}`}>
                      {order.status}
                    </span>
                  </Table.Cell>
                  <Table.Cell>{order.date}</Table.Cell>
                  <Table.Cell>
                    <a 
                      href="#" 
                      onClick={(e) => {
                        e.preventDefault();
                        handleMenuClick(order.id); // Handle menu click
                      }}
                      className="text-blue-600"
                    >
                      {order.menu}
                    </a>
                  </Table.Cell>
                  <Table.Cell>{order.total}</Table.Cell>
                  <Table.Cell>
                    <span className={`p-2 rounded-lg ${getStatusColor(order.status)}`}>
                      {order.status}
                    </span>
                  </Table.Cell>
                </Table.Row>
              )) : (
                <Table.Row>
                  <Table.Cell colSpan="7" className="text-center py-4">No orders found</Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </div>
        <div className="flex justify-center mt-4">
          <nav className="inline-flex rounded-md shadow-sm" aria-label="Pagination">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="relative inline-flex items-center px-3 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-l-md hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <FontAwesomeIcon icon={faAnglesLeft} />
            </button>
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index + 1}
                onClick={() => handlePageChange(index + 1)}
                className={`relative inline-flex items-center px-4 py-2 text-sm font-medium ${
                  currentPage === index + 1
                    ? 'text-white bg-cyan-600 border-cyan-600'
                    : 'text-gray-500 bg-white border-gray-300'
                } hover:bg-gray-50`}
              >
                {index + 1}
              </button>
            ))}
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="relative inline-flex items-center px-3 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-r-md hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <FontAwesomeIcon icon={faAnglesRight} />
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
}

export default AdminOrderOrg;