"use client";

import { Button } from "flowbite-react";
import NavbarRating from "../components/navbar/navbarRating";
import { useNavigate } from "react-router-dom";
import { getAuthAndProfileResponses } from "../utils/localstorage";

function ThanksMeal() {
  const navigate = useNavigate();

  const { authResponse, profileResponse } = getAuthAndProfileResponses();

  function doneClick() {
    switch (authResponse?.role) {
      case 1:
        navigate("/home-admin");
        break;
      case 2:
      case 3:
      case 6:
        navigate("/home-meal");
        break;
      case 4:
        navigate("/home-partner");
        break;
      case 5:
        navigate("/home-patron");
        break;
      default:
        navigate("/signin");
    }
  }

  return (
    <form>
      <NavbarRating />
      <div className="flex max-w-xl flex-col gap-4 mx-auto mt-32 items-center">
        <div>
          <h2 className="text-4xl text-center font-bold leading-9 tracking-tight text-gray-800 mb-4">
            Terimakasih!
          </h2>
          <h2 className="text-xl text-center font-semibold leading-9 tracking-tight text-gray-800">
            Pesanan anda akan diproses langsung
          </h2>
          <p className="mt-4 mb-4 text-sm text-center mt-4">
            Terimakasih telah memesan makanan! Kami senang memiliki kesempatan
            untuk melayani Anda, dan pesanan Anda telah berhasil diterima. Kami
            menghargai kepercayaan Anda kepada kami dan berharap dapat
            memberikan pengalaman makan yang baik.
          </p>
        </div>
        <Button
          type="submit"
          className="w-[30vh] mt-8 bg-green-600 text-white hover:bg-green-200"
          onClick={doneClick}
        >
          Kembali ke Home
        </Button>
      </div>
    </form>
  );
}

export default ThanksMeal;
