"use client";

import { Button } from "flowbite-react";
import { useNavigate } from "react-router-dom";
import "./forgotPass.css";

function Done() {
  const navigate = useNavigate();

  const handleDoneClick = () => {
    navigate("/signin");
  };

  return (
    <form className="flex max-w-md flex-col gap-4 mx-auto mt-56 items-center">
      <div>
        <h2 className="text-4xl text-center font-bold leading-9 tracking-tight text-gray-800">All Done</h2>
        <p className="mt-4 mb-4 text-md text-center">Your password has been reset so now you can log in to your account</p>
      </div>
      <Button type="button" onClick={handleDoneClick} className="w-[30vh] bg-green-600 text-white hover:bg-green-200">Done</Button>
    </form>
  );
}

export default Done;