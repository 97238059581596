"use client";

import { useState } from "react";
import { Label, Button, TextInput, FileInput, Textarea } from "flowbite-react";
import SidebarPartner from "../components/sidebar/sidebarPartner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

function AdminAddMenu() {
	const [formData, setFormData] = useState({
		name: "",
		category: "",
		mainIngredient: "",
		dateAdded: "",
		image: null,
	});

	const [error, setError] = useState("");
	const [success, setSuccess] = useState("");

	const handleChange = (e) => {
		const { id, value } = e.target;
		setFormData((prevState) => ({
			...prevState,
			[id]: value,
		}));
	};

	const handleFileChange = (e) => {
		setFormData((prevState) => ({
			...prevState,
			image: e.target.files[0],
		}));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		const payload = new FormData();
		payload.append("name", formData.name);
		payload.append("category", formData.category);
		payload.append("mainIngredient", formData.mainIngredient);
		payload.append("dateAdded", formData.dateAdded);
		if (formData.image) {
			payload.append("image", formData.image);
		}

		try {
			const response = await fetch(
				"https://meals-api.103.127.137.58.nip.io/api/v1/meals",
				{
					method: "POST",
					body: payload,
				},
			);

			const data = await response.json();
			if (response.ok) {
				setSuccess(data.message);
				setError("");
			} else {
				setError(data.message || "An error occurred.");
				setSuccess("");
			}
		} catch (err) {
			setError("An error occurred. Please try again.");
			setSuccess("");
		}
	};

	return (
		<div className="flex h-screen">
			<SidebarPartner className="fixed h-full" />
			<div className="flex flex-col flex-grow p-6 overflow-y-auto">
				<div className="flex items-center justify-between mb-4">
					<div className="flex items-center">
						<a href="/admin-list-meal">
							<FontAwesomeIcon icon={faChevronLeft} className="text-lg" />
						</a>
						<div className="mb-2 ml-5">
							<h2 className="text-2xl font-bold leading-9 tracking-tight text-gray-800">
								Add Menu
							</h2>
							<p className="text-xs text-gray-600">
								This is a form for adding menu
							</p>
						</div>
					</div>
				</div>
				<hr className="border-gray-400 mb-4" />
				<form
					onSubmit={handleSubmit}
					className="flex flex-col mt-4 lg:flex-row lg:gap-6"
				>
					<div className="flex flex-col gap-4 w-full lg:w-1/2">
						<div>
							<Label htmlFor="name" value="Meal Name" />
							<TextInput
								id="name"
								type="text"
								className="mt-2"
								required
								onChange={handleChange}
							/>
						</div>
						<div>
							<Label htmlFor="category" value="Category" />
							<TextInput
								id="category"
								type="text"
								className="mt-2"
								required
								onChange={handleChange}
							/>
						</div>
						<div>
							<Label htmlFor="mainIngredient" value="Main Ingredient" />
							<TextInput
								id="mainIngredient"
								type="text"
								className="mt-2"
								required
								onChange={handleChange}
							/>
						</div>
						<div>
							<Label htmlFor="image" value="Menu Picture" />
							<Label
								htmlFor="dropzone-file"
								className="flex h-54 w-full mt-2 cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:border-gray-500 dark:hover:bg-gray-600"
							>
								<div className="flex flex-col items-center justify-center pb-6 pt-5">
									<svg
										className="mb-4 h-8 w-8 text-gray-500 dark:text-gray-400"
										aria-hidden="true"
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 20 16"
									>
										<path
											stroke="currentColor"
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth="2"
											d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
										/>
									</svg>
									<p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
										<span className="font-semibold">Click to upload</span> or
										drag and drop
									</p>
									<p className="text-xs text-gray-500 dark:text-gray-400">
										SVG, PNG, JPG or GIF (MAX. 800x400px)
									</p>
								</div>
								<FileInput
									id="dropzone-file"
									className="hidden"
									onChange={handleFileChange}
								/>
							</Label>
						</div>
						<div>
							<Label htmlFor="dateAdded" value="Date Added" />
							<TextInput
								id="dateAdded"
								type="text"
								className="mt-2"
								required
								onChange={handleChange}
							/>
						</div>
					</div>
				</form>
				<Button
					type="submit"
					className="mt-20 w-[20vw] bg-green-600"
					onClick={handleSubmit}
				>
					Save
				</Button>
				{error && <p className="mt-4 text-red-500">{error}</p>}
				{success && <p className="mt-4 text-green-500">{success}</p>}
			</div>
		</div>
	);
}

export default AdminAddMenu;
