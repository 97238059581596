"use client";

import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouse,
  faBurger,
  faUser,
  faBell,
  faClipboard,
  faClipboardCheck,
  faFileInvoiceDollar,
  faChevronDown,
  faSignOut,
} from "@fortawesome/free-solid-svg-icons";

export function SidebarAdmin() {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeMenu, setActiveMenu] = useState("Home");
  const [menuOpen, setMenuOpen] = useState({
    Member: false,
    Patron: false,
    Partner: false,
    Order: false,
    Meal: false,
  });

  const menuItems = [
    { name: "Home", icon: faHouse, link: "/home-admin" },
    { name: "Member", icon: faBell },
    { name: "Patron", icon: faBurger },
    { name: "Partner", icon: faClipboard, link: "/admin-list-partner" },
    {
      name: "Organization",
      icon: faClipboard,
      link: "/admin-list-organization",
    },
    { name: "Caregiver", icon: faClipboard, link: "/admin-list-caregiver" },
    { name: "Meal", icon: faUser },
    { name: "Order", icon: faFileInvoiceDollar },
  ];

  const onRedirectLogout = async () => {
    if (window.confirm("Are you sure you want to logout?")) {
      localStorage.clear();
      navigate("/");
    }
  };

  const submenuItems = {
    Member: [
      {
        name: "List Member",
        icon: faClipboardCheck,
        link: "/admin-list-member",
      },
      {
        name: "List Allergies",
        icon: faClipboard,
        link: "/admin-list-allergies",
      },
      { name: "List Illness", icon: faClipboard, link: "/admin-list-illness" },
      {
        name: "Member Illness",
        icon: faClipboard,
        link: "/admin-member-illness",
      },
      {
        name: "Member Allergies",
        icon: faClipboard,
        link: "/admin-member-allergies",
      },
    ],
    Patron: [
      {
        name: "List Patron",
        icon: faFileInvoiceDollar,
        link: "/admin-list-patron",
      },
      {
        name: "Donation",
        icon: faClipboardCheck,
        link: "/admin-list-donation",
      },
    ],
    Order: [
      {
        name: "Organization",
        icon: faClipboardCheck,
        link: "/admin-order-org",
      },
      { name: "Meal Illness", icon: faClipboard, link: "/admin-order-illness" },
      {
        name: "Meal Allergies",
        icon: faClipboard,
        link: "/admin-order-allergies",
      },
    ],
    Meal: [
      { name: "List Meal", icon: faClipboardCheck, link: "/admin-list-meal" },
      {
        name: "List Meal Allergies",
        icon: faClipboard,
        link: "/admin-meal-allergies",
      },
      {
        name: "List Meal Illness",
        icon: faClipboard,
        link: "/admin-meal-illness",
      },
      {
        name: "List Food Category",
        icon: faClipboard,
        link: "/admin-food-category",
      },
    ],
  };

  const handleMenuClick = (name) => {
    setActiveMenu(name);
    setMenuOpen((prevState) => ({ ...prevState, [name]: !prevState[name] }));
  };

  return (
    <div
      className={`w-64 bg-gray-200 text-gray flex flex-col justify-between ${
        Object.values(menuOpen).some(Boolean) ? "h-auto" : "h-screen"
      }`}
    >
      <div>
        <div className="flex justify-center items-center py-4">
          <img
            src="/img/logo.png"
            alt="Meals to Heals"
            className="h-auto w-48"
          />
        </div>
        <div className="space-y-4">
          {menuItems.map((item) => (
            <div key={item.name}>
              <div
                className={`flex items-center px-6 py-2 rounded-sm cursor-pointer ${
                  activeMenu === item.name
                    ? "bg-green-500 text-white"
                    : "hover:bg-green-500 hover:text-white"
                }`}
                onClick={() => handleMenuClick(item.name)}
              >
                <FontAwesomeIcon icon={item.icon} className="mr-3" />
                <Link
                  to={item.link}
                  className="text-base"
                  style={{ fontSize: "14px" }}
                >
                  {item.name}
                </Link>
                {submenuItems[item.name] && (
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    className={`ml-auto transition-transform ${
                      menuOpen[item.name] ? "transform rotate-180" : ""
                    }`}
                    style={{ fontSize: "12px" }}
                  />
                )}
              </div>
              {submenuItems[item.name] && menuOpen[item.name] && (
                <div className="ml-6 mt-2 space-y-2">
                  {submenuItems[item.name].map((subItem) => (
                    <div
                      key={subItem.name}
                      className={`flex items-center px-6 py-2 rounded-sm cursor-pointer ${
                        activeMenu === subItem.name
                          ? "bg-green-500 text-white"
                          : "hover:bg-green-500 hover:text-white"
                      }`}
                      onClick={() => setActiveMenu(subItem.name)}
                    >
                      <FontAwesomeIcon icon={subItem.icon} className="mr-3" />
                      <Link
                        to={subItem.link}
                        className="text-base"
                        style={{ fontSize: "14px" }}
                      >
                        {subItem.name}
                      </Link>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
        <div
          className={`mt-2 flex items-center px-6 py-4 rounded-sm cursor-pointer ${
            location.pathname === "/logout"
              ? "bg-green-500 text-white"
              : "hover:bg-green-500 hover:text-white"
          }`}
          onClick={onRedirectLogout}
        >
          <FontAwesomeIcon icon={faSignOut} className="mr-3" />
          <span className="text-base">Logout</span>
        </div>
      </div>
      <div
        className={`mb-2 flex items-center px-6 ${
          Object.values(menuOpen).some(Boolean) ? "mt-12" : ""
        }`}
      >
        <Link to="/user-profile">
          <img
            className="h-10 w-10 rounded-full"
            src="https://flowbite.com/docs/images/people/profile-picture-1.jpg"
            alt="Jese Leos"
          />
        </Link>
        <div className="ml-3">
          <p
            id="profile-popover"
            className="text-base font-semibold leading-none"
          >
            <Link to="/user-profile">Jese Leos</Link>
          </p>
        </div>
      </div>
    </div>
  );
}

export default SidebarAdmin;
