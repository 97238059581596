"use client";

import { useState } from "react";
import { Label, Button, TextInput, FileInput, Textarea } from "flowbite-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import SidebarAdmin from "../components/sidebar/sidebarAdmin";

function AdminAddMember() {
	const [formData, setFormData] = useState({
		decrepitName: "",
		nameChild: "",
		dateOfBirth: "",
		address: "",
		email: "",
		memberPicture: null,
		dateAdded: "",
	});

	const [error, setError] = useState("");
	const [success, setSuccess] = useState("");

	const handleChange = (e) => {
		const { id, value } = e.target;
		setFormData((prevState) => ({
			...prevState,
			[id]: value,
		}));
	};

	const handleFileChange = (e) => {
		setFormData((prevState) => ({
			...prevState,
			memberPicture: e.target.files[0],
		}));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			const response = await fetch(
				"https://meals-api.103.127.137.58.nip.io/api/v1/members",
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify(formData),
				},
			);

			const data = await response.json();
			if (response.ok) {
				setSuccess(data.message);
				setError("Your data failed to add");
			} else {
				setError(data.message);
				setSuccess("Success Creating new user");
			}
		} catch (err) {
			setError("An error occurred. Please try again.");
			setSuccess("");
		}
	};

	return (
		<div className="flex h-screen">
			<SidebarAdmin className="fixed h-full" />
			<div className="flex flex-col flex-grow p-6 overflow-y-auto">
				<div className="flex items-center justify-between mb-4">
					<div className="flex items-center">
						<a href="/admin-list-member">
							<FontAwesomeIcon icon={faChevronLeft} className="text-lg" />
						</a>
						<div className="mb-2 ml-5">
							<h2 className="text-2xl font-bold leading-9 tracking-tight text-gray-800">
								Add Member Form
							</h2>
							<p className="text-xs text-gray-600">
								This is a form for adding member data
							</p>
						</div>
					</div>
				</div>
				<hr className="border-gray-400 mb-4" />
				<form
					onSubmit={handleSubmit}
					className="flex flex-col mt-4 lg:flex-row lg:gap-6"
				>
					<div className="flex flex-col gap-4 w-full lg:w-1/2">
						<div>
							<Label htmlFor="decrepitName" value="Decrepit Name" />
							<TextInput
								id="decrepitName"
								type="text"
								className="mt-2"
								required
								onChange={handleChange}
							/>
						</div>
						<div>
							<Label htmlFor="nameChild" value="Name of Child" />
							<TextInput
								id="nameChild"
								type="text"
								className="mt-2"
								required
								onChange={handleChange}
							/>
						</div>
						<div>
							<Label htmlFor="dateOfBirth" value="Date of Birth" />
							<TextInput
								id="dateOfBirth"
								type="text"
								className="mt-2"
								required
								onChange={handleChange}
							/>
						</div>
						<div>
							<Label htmlFor="address" value="Address" />
							<Textarea
								id="address"
								placeholder="Leave a comment..."
								required
								rows={5}
								className="mt-2"
								onChange={handleChange}
							/>
						</div>
					</div>
					<div className="flex flex-col gap-4 w-full lg:w-1/2">
						<div>
							<Label htmlFor="email" value="Email" />
							<TextInput
								id="email"
								type="text"
								className="mt-2"
								required
								onChange={handleChange}
							/>
						</div>
						<Label htmlFor="memberPicture" value="Member Picture" />
						<Label
							htmlFor="dropzone-file"
							className="flex h-54 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:border-gray-500 dark:hover:bg-gray-600"
						>
							<div className="flex flex-col items-center justify-center pb-6 pt-5">
								<svg
									className="mb-4 h-8 w-8 text-gray-500 dark:text-gray-400"
									aria-hidden="true"
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 20 16"
								>
									<path
										stroke="currentColor"
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="2"
										d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
									/>
								</svg>
								<p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
									<span className="font-semibold">Click to upload</span> or drag
									and drop
								</p>
								<p className="text-xs text-gray-500 dark:text-gray-400">
									SVG, PNG, JPG or GIF (MAX. 800x400px)
								</p>
							</div>
							<FileInput
								id="dropzone-file"
								className="hidden"
								onChange={handleFileChange}
							/>
						</Label>
						<div>
							<Label htmlFor="dateAdded" value="Date Added" />
							<TextInput
								id="dateAdded"
								type="text"
								className="mt-2"
								required
								onChange={handleChange}
							/>
						</div>
					</div>
				</form>
				<Button type="submit" className="mt-20 w-[20vw] bg-green-600">
					Save
				</Button>
				{error && <p className="mt-4 text-red-500">{error}</p>}
				{success && <p className="mt-4 text-green-500">{success}</p>}
			</div>
		</div>
	);
}

export default AdminAddMember;
