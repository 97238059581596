// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pass, .confirm {
    border-radius: 10px;
    border-color: rgb(209 213 219);
    background-color: rgb(249 250 251);
}`, "",{"version":3,"sources":["webpack://./src/user/user.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,8BAA8B;IAC9B,kCAAkC;AACtC","sourcesContent":[".pass, .confirm {\n    border-radius: 10px;\n    border-color: rgb(209 213 219);\n    background-color: rgb(249 250 251);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
