"use client";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

function NavbarRegis() {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate('/');
  };

  return (
    <div className="flex justify-between items-center p-2 bg-white shadow-md">
      <div className="flex items-center ml-6 cursor-pointer" onClick={handleNavigate}>
        <img src="/img/logo.png" alt="Meals to Heals" className="h-12 w-32 cursor-pointer" />
      </div>
      <div className="flex-grow text-center">
        <p className="text-xl font-semibold">Meals to Heals</p>
      </div>
      <div className="relative">
        <div 
          className="flex items-center justify-center w-10 h-10 mr-6 rounded-full transition-all duration-300 hover:bg-gray-200 cursor-pointer"
          onClick={handleNavigate}
        >
          <FontAwesomeIcon icon={faXmark} className="text-gray-600 text-xl" />
        </div>
      </div>
    </div>
  );
}

export default NavbarRegis;