"use client";

import { Checkbox, Table } from "flowbite-react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faFilter, faArrowUpAZ, faPlus, faSquareMinus, faPen, faTrash, faAnglesRight, faAnglesLeft } from '@fortawesome/free-solid-svg-icons';
import { useState, useEffect, useMemo } from "react";
import SidebarAdmin from "../components/sidebar/sidebarAdmin";
import { Link } from "react-router-dom";

function AdminListMeal() {
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const [meals, setMeals] = useState([]);
	const [filteredMeals, setFilteredMeals] = useState([]);
	const [dataPerPage] = useState(10);
	const [searchTerm, setSearchTerm] = useState("");
	const [filterOpen, setFilterOpen] = useState(false);
	const [selectedMeals, setSelectedMeals] = useState([]);

	useEffect(() => {
		// Fetch the data from the API
		const fetchMeals = async () => {
			try {
				const response = await fetch(
					`https://meals-api.103.127.137.58.nip.io/api/v1/meals?page=${currentPage}&limit=${dataPerPage}`,
				);
				const result = await response.json();
				if (result.status === "success") {
					setMeals(result.data.datas);
					setTotalPages(result.data.total_pages);
				} else {
					console.error("Failed to fetch meals:", result.message);
				}
			} catch (error) {
				console.error("An error occurred while fetching meals:", error);
			}
		};

		fetchMeals();
	}, [currentPage]);

	useEffect(() => {
		// Filter meals based on search term
		const filtered = meals.filter(
			(meal) =>
				meal.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
				meal.category.toLowerCase().includes(searchTerm.toLowerCase()) ||
				meal.main_ingredient.toLowerCase().includes(searchTerm.toLowerCase()),
		);
		setFilteredMeals(filtered);
	}, [searchTerm, meals]);

	const handlePageChange = (page) => {
		if (page > 0 && page <= totalPages) {
			setCurrentPage(page);
		}
	};

	const handleSearchChange = (e) => {
		setSearchTerm(e.target.value);
	};

	const handleFilterToggle = () => {
		setFilterOpen(!filterOpen);
	};

	const handleSelectMeal = (meal) => {
		setSelectedMeals((prev) => {
			if (prev.includes(meal.id)) {
				return prev.filter((id) => id !== meal.id);
			}
			return [...prev, meal.id];
		});
	};

	const sortedMeals = useMemo(() => {
		return [...filteredMeals].sort((a, b) => a.name.localeCompare(b.name));
	}, [filteredMeals]);

	const paginatedMeals = useMemo(() => {
		const start = (currentPage - 1) * dataPerPage;
		return sortedMeals.slice(start, start + dataPerPage);
	}, [currentPage, sortedMeals]);

	return (
		<div className="flex h-screen">
			<SidebarAdmin className="fixed h-full" />
			<div className="flex flex-col flex-grow p-6 overflow-y-auto">
				<div className="flex justify-between items-center mb-4">
					<div className="mb-2">
						<h2 className="text-2xl font-bold leading-9 tracking-tight text-gray-800">
							List Meal
						</h2>
						<p className="text-xs text-gray-600">This is a list of meals</p>
					</div>
					<div className="relative flex items-center">
						<FontAwesomeIcon
							icon={faMagnifyingGlass}
							className="absolute left-3 text-gray-600"
						/>
						<input
							type="text"
							className="pl-10 pr-4 py-2 border border-gray-300 rounded-lg text-sm w-80"
							placeholder="Search..."
							value={searchTerm}
							onChange={handleSearchChange}
						/>
					</div>
				</div>
				<hr className="border-gray-400 mb-4" />
				<div className="flex justify-between items-center mb-4">
					<div
						className="flex items-center space-x-2 border border-gray-300 rounded-lg px-3 py-2 text-sm cursor-pointer"
						onClick={handleFilterToggle}
					>
						<FontAwesomeIcon icon={faFilter} className="text-gray-600" />
						<a href="#" className="text-gray-600">
							Filter
						</a>
						{selectedMeals.length > 0 && (
							<div className="flex items-center justify-center w-5 h-5 border rounded-full text-white text-xs bg-green-500 text-semibold">
								{selectedMeals.length}
							</div>
						)}
					</div>
					<div className="flex items-center space-x-2 border border-gray-300 rounded-lg px-3 py-2 ml-4 text-sm cursor-pointer">
						<FontAwesomeIcon icon={faArrowUpAZ} className="text-gray-600" />
						<a href="#" className="text-gray-600">
							Sort
						</a>
					</div>
					<div className="flex-grow"></div>
					<Link to="/admin-add-menu">
						<div className="flex items-center space-x-2 border border-gray-300 rounded-lg px-3 py-2 text-sm cursor-pointer">
							<FontAwesomeIcon icon={faPlus} className="text-gray-600" />
							<p className="text-gray-600">Add new</p>
						</div>
					</Link>
				</div>
				{filterOpen && (
					<div className="bg-gray-100 p-4 mb-4 rounded-lg border border-gray-300">
						{/* Placeholder for filter options */}
						Filter options will go here
					</div>
				)}
				<div className="overflow-x-auto">
					<Table hoverable>
						<Table.Head>
							<Table.HeadCell className="p-4">
								<FontAwesomeIcon icon={faSquareMinus} className="text-lg" />
							</Table.HeadCell>
							<Table.HeadCell>Meal name</Table.HeadCell>
							<Table.HeadCell>Category</Table.HeadCell>
							<Table.HeadCell>Main ingredient</Table.HeadCell>
							<Table.HeadCell>Total</Table.HeadCell>
							<Table.HeadCell>Date added</Table.HeadCell>
							<Table.HeadCell>Action</Table.HeadCell>
						</Table.Head>
						<Table.Body className="divide-y">
							{paginatedMeals.length > 0 ? (
								paginatedMeals.map((meal) => (
									<Table.Row
										key={meal.id}
										className="bg-white dark:border-gray-700 dark:bg-gray-800"
									>
										<Table.Cell className="p-4">
											<Checkbox
												checked={selectedMeals.includes(meal.id)}
												onChange={() => handleSelectMeal(meal)}
											/>
										</Table.Cell>
										<Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
											<img
												src="/img/eng-flag.jpeg"
												alt="Indonesia"
												className="h-7 w-7 inline-block rounded-full mr-3"
											/>
											{meal.name}
										</Table.Cell>
										<Table.Cell>{meal.category || "N/A"}</Table.Cell>
										<Table.Cell>{meal.main_ingredient || "N/A"}</Table.Cell>
										<Table.Cell>{meal.total || "N/A"}</Table.Cell>
										<Table.Cell>
											{new Date(meal.created_at).toLocaleDateString()}
										</Table.Cell>
										<Table.Cell>
											<a
												href="#"
												className="font-medium text-cyan-600 hover:underline dark:text-cyan-500 mr-4"
											>
												<FontAwesomeIcon icon={faPen} />
											</a>
											<a
												href="#"
												className="font-medium text-red-600 hover:underline dark:text-cyan-500"
											>
												<FontAwesomeIcon icon={faTrash} />
											</a>
										</Table.Cell>
									</Table.Row>
								))
							) : (
								<Table.Row>
									<Table.Cell colSpan="7" className="text-center py-4">
										No meals found
									</Table.Cell>
								</Table.Row>
							)}
						</Table.Body>
					</Table>
				</div>
				<div className="flex justify-center mt-4">
					<nav
						className="inline-flex rounded-md shadow-sm"
						aria-label="Pagination"
					>
						<button
							onClick={() => handlePageChange(currentPage - 1)}
							disabled={currentPage === 1}
							className="relative inline-flex items-center px-3 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-l-md hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
						>
							<FontAwesomeIcon icon={faAnglesLeft} />
						</button>
						{Array.from({ length: totalPages }, (_, index) => (
							<button
								key={index + 1}
								onClick={() => handlePageChange(index + 1)}
								className={`relative inline-flex items-center px-4 py-2 text-sm font-medium ${
									currentPage === index + 1
										? "text-white bg-cyan-600 border-cyan-600"
										: "text-gray-500 bg-white border-gray-300"
								} hover:bg-gray-50`}
							>
								{index + 1}
							</button>
						))}
						<button
							onClick={() => handlePageChange(currentPage + 1)}
							disabled={currentPage === totalPages}
							className="relative inline-flex items-center px-3 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-r-md hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
						>
							<FontAwesomeIcon icon={faAnglesRight} />
						</button>
					</nav>
				</div>
			</div>
		</div>
	);
}

export default AdminListMeal;
