"use client";

import { Button } from "flowbite-react";
import { useEffect, useState } from "react";
import React from "react";
import { Link } from "react-router-dom";
import NavbarLanding from "../components/navbar/navbarLanding";
import axios from "axios";
import { getAuthAndProfileResponses } from "../utils/localstorage";

function LandingPage() {
  const [menuData, setMenuData] = useState([]);
  const [partnerData, setPartnerData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { authResponse, profileResponse } = getAuthAndProfileResponses();

  const fetchMealData = async () => {
    try {
      setIsLoading(true);
      await axios
        .get(process.env.REACT_APP_API_BASE_URL + "meals/raw", {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setMenuData(response?.data?.data);
        });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchPartnerData = async () => {
    try {
      setIsLoading(true);
      await axios
        .get(process.env.REACT_APP_API_BASE_URL + "partners/raw", {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setPartnerData(response?.data?.data);
        });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchMealData();
    fetchPartnerData();
  }, []);

  useState(() => {
    localStorage.clear();
  }, []);

  return (
    <form>
      <NavbarLanding />
      <div className="container mx-auto px-4 py-12">
        <div className="flex flex-col lg:flex-row items-center lg:items-start lg:justify-between">
          <div className="lg:w-1/2 lg:pr-8 ml-10">
            <h1 className="text-6xl font-bold mb-4 mt-32">
              MAKANAN TERBAIK UNTUK SELERA ANDA
            </h1>
            <p className="text-md mb-8 mt-8 max-w-[35vw]">
              Makanan sehat dibuat dari bahan-bahan segar dan diolah dengan baik
              oleh restoran. Dedikasi terhadap kualitas ini menjadi ciri khas
              restoran ini.
            </p>
            <div className="flex space-x-4">
              <Link to="/signin">
                <Button className="w-40 bg-green-600 hover:bg-none">
                  Get Started
                </Button>
              </Link>
              <Link to="/signin">
                <Button className="w-40 bg-white border-green-700 border-2 text-green-700 hover:border-cyan-800 hover:text-white">
                  Your Location
                </Button>
              </Link>
            </div>
          </div>
          <div className="lg:w-1/2 mt-8 lg:mt-0 flex justify-center">
            <img
              src="/img/landing-image.png"
              alt="Profile"
              className="w-[80vw] h-auto"
            />
          </div>
        </div>
        <div className="mt-32">
          <div className="flex flex-col items-center mt-20 space-y-20">
            <div className="flex flex-col lg:flex-row items-center lg:justify-between w-full ml-20">
              <div className="text-center lg:text-left lg:w-1/2">
                <h1 className="text-4xl font-bold mb-4">
                  KAMI MEMASTIKAN MAKANAN ANDA <br /> TIBA TEPAT WAKTU
                </h1>
                <p className="text-md mb-8 mt-4">
                  Layanan pengiriman kami memastikan makanan Anda tiba panas dan
                  segar, tepat waktu, setiap saat.
                </p>
                <Link to="/signin">
                  <Button type="submit" className="w-40 bg-green-600">
                    Get Started
                  </Button>
                </Link>
              </div>
              <div className="mt-8 lg:mt-0 lg:w-1/2 flex justify-center">
                <img
                  src="/img/delivery.png"
                  alt="Delivery"
                  className="w-[30vw] h-auto"
                />
              </div>
            </div>
            <div className="flex flex-col lg:flex-row items-center lg:justify-between w-full ml-10">
              <div className="mt-8 lg:mt-0 lg:w-1/2 flex justify-center">
                <img
                  src="/img/chef.jpeg"
                  alt="Chef"
                  className="w-[35vw] h-auto rounded-[25px]"
                />
              </div>
              <div className="text-center lg:text-left lg:w-1/2 ml-20">
                <h1 className="text-4xl font-bold mb-4">
                  BAHAN MAKANAN <br /> BERKUALITAS TINGGI
                </h1>
                <p className="text-md mb-8 mt-4">
                  Kami hanya menggunakan bahan-bahan berkualitas tinggi untuk
                  menciptakan hidangan yang lezat dan bergizi.
                </p>
                <Link to="/signin">
                  <Button type="submit" className="w-40 bg-green-600">
                    Get Started
                  </Button>
                </Link>
              </div>
            </div>
            <div className="flex flex-col lg:flex-row items-center lg:justify-between w-full ml-20">
              <div className="text-center lg:text-left lg:w-1/2">
                <h1 className="text-4xl font-bold mb-4">
                  MENYEDIAKAN MAKANAN YANG <br /> SEHAT DAN HIGIENIS
                </h1>
                <p className="text-md mb-8 mt-4">
                  Kami mengutamakan kebersihan dan kesehatan, memastikan bahwa
                  setiap makanan aman dan sehat untuk Anda.
                </p>
                <Link to="/signin">
                  <Button type="submit" className="w-40 bg-green-600">
                    Get Started
                  </Button>
                </Link>
              </div>
              <div className="mt-8 lg:mt-0 lg:w-1/2 flex justify-center">
                <img
                  src="/img/makanan1.jpeg"
                  alt="Food"
                  className="w-[35vw] h-auto rounded-[25px]"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-32">
          <h1 className="text-4xl font-bold mb-4 text-center">
            RASA YANG LUAR BIASA <br /> DARI MAKANAN DI BAWAH INI
          </h1>
          <h1 className="text-lg mb-4 text-center">
            Makanan dibawah ini merupakan salah satu makanan yang paling populer
          </h1>
          <Link to="/signin">
            <Button
              type="submit"
              className="w-40 bg-green-600 mb-8 float-right -mt-12 w-[10vw]"
            >
              Get Started
            </Button>
          </Link>
          <div className="grid grid-cols-6 gap-4 mt-16">
            {menuData?.slice(0, 6).map((item, index) => (
              <div key={index}>
                <div className="border-gray-200 mt-6 border-2 h-48 rounded-xl flex justify-center items-center p-4">
                  <img
                    src={item?.images[0]?.image?.path}
                    alt={item?.name}
                    className="w-full h-full rounded-md object-cover"
                  />
                </div>
                <div>
                  <p className="font-semibold mt-2 text-lg">{item?.name}</p>
                  <p className="text-[12px] mt-1 line-clamp-2">
                    {item?.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="mt-32">
          <h1 className="text-4xl font-bold mb-4 text-center">
            BERTEMU DENGAN MITRA KAMI
          </h1>
          <h1 className="text-lg mb-4 text-center">
            Ini adalah mitra yang membantu kami dalam memberikan bantuan untuk
            para lansia
          </h1>
          <div className="grid grid-cols-5 gap-4 mt-16">
            {partnerData.slice(0, 5).map((item, index) => (
              <div key={index}>
                <div className="rounded-xl flex justify-center items-center flex-col bg-transparent">
                  <img
                    src={item?.user?.image?.image?.path}
                    alt="Chef"
                    className="w-[18vw] h-auto rounded-full"
                  />
                  <p>{item?.name}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <footer className="bg-[#013202] text-white py-6 mt-32">
        <div className="container mx-auto flex flex-col md:flex-row items-center justify-between">
          <div className="flex items-center mb-4 md:mb-0">
            <img src="/img/white-logo.png" alt="Logo" className="w-[22vw]" />
          </div>
          <div className="flex flex-col md:flex-row items-center space-y-2 md:space-y-0 md:space-x-6">
            <Link to="/contact-us" className="hover:underline">
              Contact Us
            </Link>
            <Link to="/about-us" className="hover:underline">
              About Us
            </Link>
            <a href="#location" className="hover:underline">
              Location
            </a>
            <Link to="/landing-page" className="hover:underline">
              Home
            </Link>
          </div>
          <div className="flex space-x-4 mt-4 md:mt-0">
            <a href="https://www.instagram.com" className="hover:text-gray-400">
              <img
                src="/img/instagram-icon.png"
                alt="Instagram"
                className="w-7"
              />
            </a>
            <a href="https://www.twitter.com" className="hover:text-gray-400">
              <img src="/img/twitter-icon.png" alt="Twitter" className="w-12" />
            </a>
            <a
              href="https://www.facebook.com"
              className="hover:text-gray-400 mr-2"
            >
              <img
                src="/img/facebook-icon.png"
                alt="Facebook"
                className="w-7"
              />
            </a>
            <a href="https://www.linkedin.com" className="hover:text-gray-400">
              <img
                src="/img/linkedin-icon.png"
                alt="LinkedIn"
                className="w-7"
              />
            </a>
          </div>
        </div>
      </footer>
    </form>
  );
}

export default LandingPage;
