"use client";

import { Button } from "flowbite-react";
import { useNavigate } from 'react-router-dom';

function Success() {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate('/home-patron');
  };

  return (
    <form>
      <div className="flex max-w-xl flex-col gap-4 mx-auto mt-20 items-center">
        <div>
          <img src="/img/success.svg" alt="Indonesia" className="h-64 w-auto" />
          <h2 className="text-5xl text-center font-bold leading-9 tracking-tight text-gray-800 mt-8">Succeed</h2>
          <h2 className="text-xl text-center font-bold leading-9 tracking-tight text-gray-800 mt-4">Congratulations, transaction successful</h2>
          <p className="mt-4 mb-4 text-md text-center mt-2">
            Thank you for providing assistance in the form of donations for elderly members to increase the need for healthy food
          </p>
        </div>
        <Button type="button" onClick={handleBackClick} className="w-[30vh] bg-green-600 text-white hover:bg-green-200">Done</Button>
      </div>
    </form>
  );
}

export default Success;