"use client";

import { Button } from "flowbite-react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import NavbarRating from "../components/navbar/navbarRating";
import "./rating.css";

function Rating() {
    const [rating, setRating] = useState(null);
    const [hover, setHover] = useState(null);
    const navigate = useNavigate(); // Initialize useNavigate

    const handleSubmit = (e) => {
        e.preventDefault(); // Prevent default form submission
        localStorage.setItem('rating', rating); // Save rating to localStorage
        navigate('/comment'); // Navigate to the comment page
    };

    return (
        <form onSubmit={handleSubmit}> {/* Add onSubmit to form */}
            <NavbarRating />
            <div className="flex max-w-xl flex-col gap-4 mx-auto mt-36 items-center">
                <div>
                    <h2 className="text-4xl text-center font-bold leading-9 tracking-tight text-gray-800">Your opinion is important to us!</h2>
                    <p className="mt-4 mb-4 text-lg text-center mt-8">
                        What do you think about the food you have ordered?
                    </p>
                </div>
                <div>
                    {[...Array(5)].map((star, index) => {
                        const currentRating = index + 1;
                        return (
                            <label key={index}>
                                <input type="radio" name="rating"
                                    value={currentRating} onClick={() => setRating(currentRating)} />
                                <FontAwesomeIcon icon={faStar} className="star"
                                    color={currentRating <= (hover || rating) ? "#ffc107" : "#e4e5e9"}
                                    onMouseEnter={() => setHover(currentRating)}
                                    onMouseLeave={() => setHover(null)} />
                            </label>
                        );
                    })}
                </div>
                <Button type="submit" className="w-[30vh] mt-10 bg-green-600 text-white hover:bg-green-200">Send</Button>
            </div>
        </form>
    );
}

export default Rating;