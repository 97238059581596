"use client";

import { Checkbox, Table } from "flowbite-react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faFilter, faArrowUpAZ, faPlus, faSquareMinus, faPen, faTrash, faAnglesRight, faAnglesLeft } from '@fortawesome/free-solid-svg-icons';
import { useState, useEffect, useMemo } from "react";
import SidebarAdmin from "../components/sidebar/sidebarAdmin";

function AdminMealIllness() {
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);
  const [totalData, setTotalData] = useState(0); // Assume total data is fetched from the server
  const [totalPages, setTotalPages] = useState(1);
  const [mealIllnesses, setMealIllnesses] = useState([]);
  const [filteredMealIllnesses, setFilteredMealIllnesses] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterOpen, setFilterOpen] = useState(false);
  const [selectedMealIllnesses, setSelectedMealIllnesses] = useState([]);

  useEffect(() => {
    // Fetch meal illnesses from the API
    const fetchMealIllnesses = async () => {
      try {
        const response = await fetch(`https://example.com/api/v1/meal-illnesses?page=${currentPage}&limit=${dataPerPage}`);
        const result = await response.json();
        if (result.status === "success") {
          setMealIllnesses(result.data.datas);
          setTotalData(result.data.total);
          setTotalPages(Math.ceil(result.data.total / dataPerPage));
        } else {
          console.error("Failed to fetch meal illnesses:", result.message);
        }
      } catch (error) {
        console.error("An error occurred while fetching meal illnesses:", error);
      }
    };

    fetchMealIllnesses();
  }, [currentPage, dataPerPage]);

  useEffect(() => {
    // Filter meal illnesses based on search term
    const filtered = mealIllnesses.filter(mealIllness =>
      mealIllness.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      mealIllness.mealIllness.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredMealIllnesses(filtered);
  }, [searchTerm, mealIllnesses]);

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleFilterToggle = () => {
    setFilterOpen(!filterOpen);
  };

  const handleSelectMealIllness = (id) => {
    setSelectedMealIllnesses(prev => {
      if (prev.includes(id)) {
        return prev.filter(selectedId => selectedId !== id);
      }
      return [...prev, id];
    });
  };

  const sortedMealIllnesses = useMemo(() => {
    return [...filteredMealIllnesses].sort((a, b) => a.name.localeCompare(b.name));
  }, [filteredMealIllnesses]);

  const paginatedMealIllnesses = useMemo(() => {
    const start = (currentPage - 1) * dataPerPage;
    return sortedMealIllnesses.slice(start, start + dataPerPage);
  }, [currentPage, sortedMealIllnesses]);

  return (
    <div className="flex h-screen">
      <SidebarAdmin className="fixed h-full" />
      <div className="flex flex-col flex-grow p-6 overflow-y-auto">
        <div className="flex justify-between items-center mb-4">
          <div className="mb-2">
            <h2 className="text-2xl font-bold leading-9 tracking-tight text-gray-800">List Meal Illness</h2>
            <p className="text-xs text-gray-600">This is a list of meal illnesses</p>
          </div>
          <div className="relative flex items-center">
            <FontAwesomeIcon icon={faMagnifyingGlass} className="absolute left-3 text-gray-600"/>
            <input
              type="text"
              className="pl-10 pr-4 py-2 border border-gray-300 rounded-lg text-sm w-80"
              placeholder="Search..."
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
        </div>
        <hr className="border-gray-400 mb-4"/>
        <div className="flex justify-between items-center mb-4">
          <div className="flex items-center space-x-2 border border-gray-300 rounded-lg px-3 py-2 text-sm cursor-pointer" onClick={handleFilterToggle}>
            <FontAwesomeIcon icon={faFilter} className="text-gray-600"/>
            <a href="#" className="text-gray-600">Filter</a>
            {selectedMealIllnesses.length > 0 && (
              <div className="flex items-center justify-center w-5 h-5 border rounded-full text-white text-xs bg-green-500 text-semibold">
                {selectedMealIllnesses.length}
              </div>
            )}
          </div>
          <div className="flex items-center space-x-2 border border-gray-300 rounded-lg px-3 py-2 ml-4 text-sm cursor-pointer">
            <FontAwesomeIcon icon={faArrowUpAZ} className="text-gray-600"/>
            <a href="#" className="text-gray-600">Sort</a>
          </div>
          <div className="flex-grow"></div>
          <div className="flex items-center space-x-2 border border-gray-300 rounded-lg px-3 py-2 text-sm cursor-pointer">
            <FontAwesomeIcon icon={faPlus} className="text-gray-600"/>
            <a href="#" className="text-gray-600">Add new</a>
          </div>
        </div>
        {filterOpen && (
          <div className="bg-gray-100 p-4 mb-4 rounded-lg border border-gray-300">
            {/* Placeholder for filter options */}
            Filter options will go here
          </div>
        )}
        <div className="overflow-x-auto">
          <Table hoverable>
            <Table.Head>
              <Table.HeadCell className="p-4">
                <FontAwesomeIcon icon={faSquareMinus} className="text-lg" />
              </Table.HeadCell>
              <Table.HeadCell>Name</Table.HeadCell>
              <Table.HeadCell>Meal Illness</Table.HeadCell>
              <Table.HeadCell>Date of Birth</Table.HeadCell>
              <Table.HeadCell>Sex</Table.HeadCell>
              <Table.HeadCell>Date added</Table.HeadCell>
              <Table.HeadCell>Action</Table.HeadCell>
            </Table.Head>
            <Table.Body className="divide-y">
              {paginatedMealIllnesses.length > 0 ? paginatedMealIllnesses.map((mealIllness) => (
                <Table.Row key={mealIllness.id} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                  <Table.Cell className="p-4">
                    <Checkbox
                      checked={selectedMealIllnesses.includes(mealIllness.id)}
                      onChange={() => handleSelectMealIllness(mealIllness.id)}
                    />
                  </Table.Cell>
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                    <div className="flex">
                      <img src="/img/eng-flag.jpeg" alt="Flag" className="h-7 w-7 inline-block rounded-full mr-3 mt-1" />
                      <div>
                        <p className="font-semibold">{mealIllness.name}</p>
                        <p className="text-xs">{mealIllness.email}</p>
                      </div>
                    </div>
                  </Table.Cell>
                  <Table.Cell>{mealIllness.mealIllness}</Table.Cell>
                  <Table.Cell>{new Date(mealIllness.dob).toLocaleDateString()}</Table.Cell>
                  <Table.Cell>{mealIllness.sex}</Table.Cell>
                  <Table.Cell>{new Date(mealIllness.dateAdded).toLocaleDateString()}</Table.Cell>
                  <Table.Cell>
                    <a href="#" className="font-medium text-cyan-600 hover:underline dark:text-cyan-500 mr-4">
                      <FontAwesomeIcon icon={faPen} />
                    </a>
                    <a href="#" className="font-medium text-red-600 hover:underline dark:text-cyan-500">
                      <FontAwesomeIcon icon={faTrash} />
                    </a>
                  </Table.Cell>
                </Table.Row>
              )) : (
                <Table.Row>
                  <Table.Cell colSpan="7" className="text-center py-4">No meal illnesses found</Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </div>
        <div className="flex justify-center mt-4">
          <nav className="inline-flex rounded-md shadow-sm" aria-label="Pagination">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="relative inline-flex items-center px-3 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-l-md hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <FontAwesomeIcon icon={faAnglesLeft} />
            </button>
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index + 1}
                onClick={() => handlePageChange(index + 1)}
                className={`relative inline-flex items-center px-4 py-2 text-sm font-medium ${
                  currentPage === index + 1
                    ? 'text-white bg-cyan-600 border-cyan-600'
                    : 'text-gray-500 bg-white border-gray-300'
                } hover:bg-gray-50`}
              >
                {index + 1}
              </button>
            ))}
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="relative inline-flex items-center px-3 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-r-md hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <FontAwesomeIcon icon={faAnglesRight} />
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
}

export default AdminMealIllness;