"use client";

import { Button } from "flowbite-react";
import NavbarRating from "../components/navbar/navbarRating";
import { useNavigate } from 'react-router-dom';

function Thanks() {
  const navigate = useNavigate(); // Create a navigate function using useNavigate

  const handleDoneClick = () => {
    navigate('/home-meal'); // Navigate to /home-meal when the button is clicked
  };

  return (
    <form>
      <NavbarRating />
      <div className="flex max-w-xl flex-col gap-4 mx-auto mt-32 items-center">
        <div>
          <h2 className="text-4xl text-center font-bold leading-9 tracking-tight text-gray-800">Thank You</h2>
          <p className="mt-4 mb-4 text-md text-center mt-4">
              Thank you for taking the time to provide your feedback! Your detailed review has been successfully submitted and will play a crucial role in helping us enhance the quality of our food and service.
          </p>
          <p className="mt-10 text-md text-center">We appreciate your input and look forward to serving you again in the future.</p>
        </div>
        <Button type="submit" onClick={handleDoneClick} className="w-[30vh] mt-10 bg-green-600 text-white hover:bg-green-200">Done</Button>
      </div>
    </form>
  );
}

export default Thanks;
