"use client";

import { Label, Button, TextInput, FileInput, Textarea } from "flowbite-react";
import SidebarPartner from "../components/sidebar/sidebarPartner";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

function AddNewStock() {
  return (
    <div className="flex h-screen">
      <SidebarPartner className="fixed h-full" />
      <div className="flex flex-col flex-grow p-6 overflow-y-auto">
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center">
            <a href="/list-stock">
              <FontAwesomeIcon icon={faChevronLeft} className="text-lg" />
            </a>
            <div className="mb-2 ml-5">
              <h2 className="text-2xl font-bold leading-9 tracking-tight text-gray-800">Add New Stock</h2>
              <p className="text-xs text-gray-600">This is a form for adding of meal stock</p>
            </div>
          </div>
        </div>
        <hr className="border-gray-400 mb-4" />
        <div className="flex flex-col mt-4 lg:flex-row lg:gap-6">
          <div className="flex flex-col gap-4 w-full lg:w-1/2">
            <div>
              <Label htmlFor="decrepitName1" value="Meal Name" />
              <TextInput id="decrepitName1" type="text" className="mt-2" required />
            </div>
            <div>
              <Label htmlFor="nameChild1" value="Category" />
              <TextInput id="nameChild1" type="text" className="mt-2" required />
            </div>
            <div>
              <Label htmlFor="age1" value="Main Ingredient" />
              <TextInput id="age1" type="text" className="mt-2" required />
            </div>
            <div>
              <Label htmlFor="address1" value="Meal Ingredient" />
              <Textarea id="comment" required rows={5} className="mt-2" />
            </div>
            <div>
              <Label htmlFor="age1" value="Stock" />
              <TextInput id="age1" type="text" className="mt-2" required />
            </div>
          </div>
        </div>
        <Button type="submit" className="mt-20 w-[20vw] bg-green-600 text-white hover:bg-green-200">Save</Button>
      </div>
    </div>
  );
}

export default AddNewStock;
