"use client";

import { Label, Button, TextInput, Textarea, Datepicker } from "flowbite-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import SidebarMember from "../components/sidebar/sidebarMember";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { getAuthAndProfileResponses } from "../utils/localstorage";
import { Tooltip } from "react-tooltip";
import { name } from "../../node_modules/workbox-build/node_modules/ajv/dist/compile/codegen/code";

function MemberCaregiverProfile() {
  const [show, setShow] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
  const [profileImage, setProfileImage] = useState(null);
  const [imageError, setImageError] = useState("");
  const [imageName, setImageName] = useState("");
  let [selectedDate, setSelectedDate] = useState(new Date());

  const [fileBase, setFileBase] = useState("");
  const [fileInfo, setFileInfo] = useState({});

  let [year, month, day] = "0";

  const genders = ["Male", "Female"];

  const { authResponse, profileResponse } = getAuthAndProfileResponses();

  const accessToken = authResponse["access-token"];
  const refreshToken = authResponse["refresh-token"];

  const [caregiverData, setCaregiverData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = () => setShow(!show);
  const newPasswordClick = () => setShowConfirmPassword(!showConfirmPassword);
  const newConfirmPasswordClick = () =>
    setShowConfirmNewPassword(!showConfirmNewPassword);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file && file.size > 2 * 1024 * 1024) {
      setImageError("File size exceeds 2MB**");
      setImageName("");
    } else {
      setImageError("");
      setProfileImage(URL.createObjectURL(file));
      setImageName(file.name);

      setFileInfo(file);

      const reader = new FileReader();

      reader.onloadend = () => {
        const result = reader.result;
        const base64 = result.split(",")[1];
        setFileBase(base64);
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    if (fileBase) {
      handleUploadImage();
    }
  }, [fileBase]);

  const handleUploadImage = async () => {
    try {
      setIsLoading(true);
      await axios.patch(
        process.env.REACT_APP_API_BASE_URL + "members/caregivers",
        JSON.stringify({
          user: {
            file_base64: fileBase,
            file_name: fileInfo?.name,
            file_type: fileInfo?.type,
          },
        }),
        {
          headers: {
            Authorization: `Bearer ${authResponse.token}`,
            "Access-Token": `${accessToken}`,
            "Refresh-Token": `${refreshToken}`,
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  function getFormattedDate(string) {
    const date = new Date(string);
    const formattedDate = date.toISOString().slice(0, 10);
    return formattedDate;
  }

  const handleUpdateBaseProfile = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      await axios.patch(
        process.env.REACT_APP_API_BASE_URL + "members/caregivers",
        JSON.stringify({
          first_name: e.target.firstName.value,
          last_name: e.target.lastName.value,
          date_of_birth: getFormattedDate(e.target.dob.value),
          gender: e.target.gender.value,
        }),
        {
          headers: {
            Authorization: `Bearer ${authResponse.token}`,
            "Access-Token": `${accessToken}`,
            "Refresh-Token": `${refreshToken}`,
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      console.log(error);
    } finally {
      alert("Update profile success");
      setIsLoading(false);
    }
  };

  const handleUpdatePassword = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      await axios.patch(
        process.env.REACT_APP_API_BASE_URL + "members/caregivers/password",
        JSON.stringify({
          old_password: e.target.oldPassword.value,
          new_password: e.target.newPassword.value,
          confirm_new_password: e.target.confirmNewPassword.value,
        }),
        {
          headers: {
            Authorization: `Bearer ${authResponse.token}`,
            "Access-Token": `${accessToken}`,
            "Refresh-Token": `${refreshToken}`,
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      console.log(error);
    } finally {
      alert("Update password success");
      setIsLoading(false);
    }
  };

  const fetchCaregiverData = async () => {
    try {
      setIsLoading(true);
      await axios
        .get(process.env.REACT_APP_API_BASE_URL + "members/caregivers/own", {
          headers: {
            "Content-Type": "application/json",
            "Access-Token": `${accessToken}`,
            "Refresh-Token": `${refreshToken}`,
            Authorization: `Bearer ${authResponse.token}`,
          },
        })
        .then((response) => {
          setCaregiverData(response?.data?.data);
        });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchCaregiverData();
    // [year, month, day] = caregiverData.date_of_birth.split("-");
  }, []);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  let cgDOB = caregiverData?.date_of_birth;

  if (cgDOB) {
    [year, month, day] = cgDOB.split("-");
    selectedDate = new Date(year, month - 1, day);
  }

  return (
    <div className="flex h-screen">
      <SidebarMember className="fixed h-full" />
      <div className="flex flex-col flex-grow p-6 overflow-y-auto">
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center">
            <Link to="/home-meal">
              <FontAwesomeIcon icon={faChevronLeft} className="text-lg" />
            </Link>

            <div className="mb-2 ml-12">
              <h2 className="text-2xl font-bold leading-9 tracking-tight text-gray-800">
                Akun Caregiver
              </h2>
              <p className="text-xs text-gray-600">Ini detail akun caregiver</p>
            </div>
          </div>
        </div>
        <hr className="border-gray-400 mb-4" />
        <div className="mt-4 ml-12 lg:flex-row lg:gap-6">
          <div className="flex">
            <div>
              <img
                src={profileImage || caregiverData?.user?.image?.image?.path}
                alt="Profile"
                className="h-36 w-36 inline-block rounded-full border-2"
              />
            </div>
            <div className="ml-14 mt-4">
              <p className="font-bold text-lg">Profile Picture</p>
              <p className="font-semibold text-[12px] text-gray-400">
                PNG, JPG, JPEG up to 5MB
              </p>
              <div className="flex mt-8">
                <label
                  className="text-md font-semibold cursor-pointer text-green-600"
                  htmlFor="fileInput"
                >
                  Change Picture
                </label>{" "}
                <input
                  id="fileInput"
                  type="file"
                  accept="image/png, image/jpeg"
                  onChange={handleImageChange}
                  className="hidden"
                />
                {imageName
                  ? imageName
                  : caregiverData?.user?.image?.image?.name && (
                      <p className="ml-8 text-sm font-medium text-gray-500">
                        {imageName
                          ? imageName
                          : caregiverData?.user?.image?.image?.name}
                      </p>
                    )}
                {imageError && (
                  <p className="ml-8 text-red-500 text-sm font-semibold">
                    {imageError}
                  </p>
                )}
              </div>
            </div>
          </div>
          <form
            className="gap-4 w-[50vw] mt-8"
            key={caregiverData?.id}
            onSubmit={handleUpdateBaseProfile}
          >
            <h3 className="font-bold text-xl mb-4">Details</h3>
            <div className="name flex">
              <div className="mt-2 w-[100%] mr-2">
                <Label htmlFor="firstName1" value="Nama Depan" />
                <TextInput
                  id="firstName1"
                  type="text"
                  name="firstName"
                  className="mt-2 w-[100%]"
                  defaultValue={caregiverData?.first_name}
                  required
                />
              </div>
              <div className="mt-2 w-[100%] ml-2">
                <Label htmlFor="lastName1" value="Nama Belakang" />
                <TextInput
                  id="lastName1"
                  name="lastName"
                  type="text"
                  className="mt-2 w-[100%]"
                  defaultValue={caregiverData?.last_name}
                  required
                />
              </div>
            </div>
            <div>
              <Label htmlFor="dob" value="Tanggal Lahir" />
              <Datepicker
                onChange={handleDateChange}
                defaultDate={selectedDate}
                name="dob"
              />
            </div>
            <div className="mt-2 w-[100%]">
              <Label htmlFor="email1" value="Email" />
              <TextInput
                id="email1"
                type="text"
                className="mt-2 w-[100%]"
                defaultValue={caregiverData?.user?.email}
                disabled
                required
                data-tooltip-id="my-tooltip"
                data-tooltip-content="Email tidak bisa diganti"
              />
              <Tooltip id="my-tooltip" className="max-w-72 !rounded-md" />
            </div>
            <div>
              <Label htmlFor="gender" value="Jenis Kelamin" />
              <div className="flex mt-2">
                <select
                  name="gender"
                  defaultValue={caregiverData?.gender}
                  id="gender"
                  className="select-option"
                >
                  {genders.map((gender, i) => (
                    <option defaultValue={gender} key={i}>
                      {gender}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <Button type="submit" color="success" className="mt-8 w-[15vw]">
              Simpan
            </Button>
          </form>
          <form
            className="gap-4 w-[50vw] mt-12"
            onSubmit={handleUpdatePassword}
          >
            <h3 className="mt-4 mb-4 font-bold">Ganti Password</h3>
            <div>
              <div className="mb-2 block mt-2">
                <Label htmlFor="oldPassword" value="Password Saat Ini" />
              </div>
              <div className="flex">
                <input
                  id="oldPassword"
                  type={show ? "text" : "password"}
                  className="text-sm py-3 w-[100%] focus:border-cyan-500 focus:ring-cyan-500 rounded-lg"
                  placeholder="8+ characters"
                  name="oldPassword"
                  required
                />
                <p
                  onClick={handleClick}
                  className="item-center ml-[-5vh] mt-3 cursor-pointer text-gray-400"
                >
                  {show ? (
                    <FontAwesomeIcon icon={faEyeSlash} />
                  ) : (
                    <FontAwesomeIcon icon={faEye} />
                  )}
                </p>
              </div>
            </div>
            <div>
              <div className="mb-2 block mt-2">
                <Label htmlFor="newPassword" value="Password Baru" />
              </div>
              <div className="flex">
                <input
                  id="newPassword"
                  type={showConfirmPassword ? "text" : "password"}
                  className="text-sm py-3 w-[100%] focus:border-cyan-500 focus:ring-cyan-500 rounded-lg"
                  placeholder="8+ characters"
                  name="newPassword"
                  required
                />
                <p
                  onClick={newPasswordClick}
                  className="item-center ml-[-5vh] mt-3 cursor-pointer text-gray-400"
                >
                  {showConfirmPassword ? (
                    <FontAwesomeIcon icon={faEyeSlash} />
                  ) : (
                    <FontAwesomeIcon icon={faEye} />
                  )}
                </p>
              </div>
            </div>
            <div>
              <div className="mb-2 block mt-2">
                <Label
                  htmlFor="confirmNewPassword"
                  value="Konfirmasi Password Baru"
                />
              </div>
              <div className="flex">
                <input
                  id="confirmNewPassword"
                  type={showConfirmNewPassword ? "text" : "password"}
                  className="text-sm py-3 w-[100%] focus:border-cyan-500 focus:ring-cyan-500 rounded-lg"
                  placeholder="8+ characters"
                  name="confirmNewPassword"
                  required
                />
                <p
                  onClick={newConfirmPasswordClick}
                  className="item-center ml-[-5vh] mt-3 cursor-pointer text-gray-400"
                >
                  {showConfirmNewPassword ? (
                    <FontAwesomeIcon icon={faEyeSlash} />
                  ) : (
                    <FontAwesomeIcon icon={faEye} />
                  )}
                </p>
              </div>
            </div>
            <Button
              type="submit"
              className="mt-8 w-[15vw] bg-green-600 text-white hover:bg-green-200"
            >
              Simpan
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default MemberCaregiverProfile;
