"use client";

import React, { useState, useEffect, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouse,
  faBurger,
  faBell,
  faClipboard,
  faClipboardCheck,
  faChevronDown,
  faSignOut,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getAuthAndProfileResponses } from "../../utils/localstorage";

const MenuItem = React.memo(({ item, isActive, onClick }) => (
  <Link to={item.link || "#"} onClick={onClick}>
    <div
      className={`flex items-center px-6 py-2 rounded-sm cursor-pointer ${
        isActive
          ? "bg-green-500 text-white"
          : "hover:bg-green-500 hover:text-white"
      }`}
    >
      <FontAwesomeIcon icon={item.icon} className="mr-3" />
      <span className="text-base">{item.name}</span> {/* Increased font size */}
      {item.name === "Order" && (
        <FontAwesomeIcon
          icon={faChevronDown}
          className={`ml-auto transition-transform ${
            isActive ? "rotate-180" : ""
          }`}
          size="sm"
        />
      )}
    </div>
  </Link>
));

const SubMenuItem = React.memo(({ subItem, isActive, onClick }) => (
  <Link to={subItem.link} onClick={onClick}>
    <div
      className={`flex items-center px-6 py-2 rounded-sm cursor-pointer ${
        isActive
          ? "bg-green-500 text-white"
          : "hover:bg-green-500 hover:text-white"
      }`}
    >
      <FontAwesomeIcon icon={subItem.icon} className="mr-3" />
      <span className="text-base">{subItem.name}</span>{" "}
      {/* Increased font size */}
    </div>
  </Link>
));

export function SidebarPartner() {
  const { authResponse, profileResponse } = getAuthAndProfileResponses();

  const [activeMenu, setActiveMenu] = useState("");
  const [orderMenuOpen, setOrderMenuOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const activeItem = menuItems.find(
      (item) =>
        item.link === location.pathname ||
        (item.name === "Order" &&
          orderSubmenuItems.some(
            (subItem) => subItem.link === location.pathname
          ))
    );
    if (activeItem) {
      setActiveMenu(activeItem.name);
      setOrderMenuOpen(activeItem.name === "Order");
    }
  }, [location.pathname]);

  const onRedirectLogout = async () => {
    if (window.confirm("Are you sure you want to logout?")) {
      localStorage.clear();
      navigate("/");
    }
  };

  const menuItems = useMemo(
    () => [
      { name: "Home", icon: faHouse, link: "/home-partner" },
      { name: "Order", icon: faBell },
      { name: "Menu", icon: faBurger, link: "/list-menu" },
      { name: "Category", icon: faClipboard, link: "/food-category" },
    ],
    []
  );

  const orderSubmenuItems = useMemo(
    () => [
      { name: "Organization", icon: faClipboardCheck, link: "/order-org" },
      { name: "Member", icon: faClipboard, link: "/order-member" },
    ],
    []
  );

  const handleMenuClick = (name) => {
    setActiveMenu(name);
    if (name === "Order") {
      setOrderMenuOpen((prev) => !prev);
    } else {
      setOrderMenuOpen(false);
    }
  };

  const wrapText = (text) => {
    if (text?.length > 13) {
      return text.slice(0, 13) + "...";
    }
    return text;
  };

  return (
    <div
      className={`w-64 bg-gray-200 text-gray flex flex-col justify-between ${
        orderMenuOpen ? "min-h-screen" : "h-screen"
      }`}
    >
      <div>
        <div className="flex justify-center items-center py-6">
          <img
            src="/img/logo.png"
            alt="Meals to Heals"
            className="h-auto w-56"
          />
        </div>
        <div className="space-y-4">
          {menuItems.map((item) => (
            <div key={item.name}>
              <MenuItem
                item={item}
                isActive={
                  activeMenu === item.name || location.pathname === item.link
                }
                onClick={() => handleMenuClick(item.name)}
              />
              {item.name === "Order" &&
                (orderMenuOpen || location.pathname.includes("/order")) && (
                  <div className="ml-6 mt-2 space-y-2">
                    {orderSubmenuItems.map((subItem) => (
                      <SubMenuItem
                        key={subItem.name}
                        subItem={subItem}
                        isActive={location.pathname === subItem.link}
                        onClick={() => setActiveMenu("Order")}
                      />
                    ))}
                  </div>
                )}
            </div>
          ))}
          <div
            className={`flex items-center px-6 py-2 rounded-sm cursor-pointer ${
              location.pathname === "/logout"
                ? "bg-green-500 text-white"
                : "hover:bg-green-500 hover:text-white"
            }`}
            onClick={onRedirectLogout}
          >
            <FontAwesomeIcon icon={faSignOut} className="mr-3" />
            <span className="text-base">Logout</span>{" "}
            {/* Increased font size */}
          </div>
        </div>
      </div>
      <div className="mb-6 flex items-center px-6">
        <div className="shrink-0 h-10 w-10 aspect-square">
          <Link to="/user-profile">
            <img
              className="h-full w-full rounded-full"
              src={profileResponse?.user?.image?.image?.path}
              alt={wrapText(
                profileResponse?.name
                  ? profileResponse?.name
                  : authResponse?.email
              )}
            />
          </Link>
        </div>
        <div className="ml-3 relative group">
          <p className="text-base font-semibold leading-none">
            <Link to="/user-profile">
              {wrapText(
                profileResponse?.name
                  ? profileResponse?.name
                  : authResponse?.email
              )}
            </Link>
          </p>
          <div className="absolute -top-2 left-0 -translate-y-full opacity-0 group-hover:opacity-100 transition-all duration-300 bg-white rounded px-3 py-2 border border-solid border-green-500">
            {profileResponse?.name
              ? profileResponse?.name
              : authResponse?.email}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SidebarPartner;
