"use client";

import { Button } from "flowbite-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import "./forgotPass.css";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

function SendCode({ email: initialEmail, role: initialRole }) {
	const [otp, setOtp] = useState(new Array(8).fill(""));
	const [email, setEmail] = useState(""); // Awalnya kosong
	const [role, setRole] = useState(null); // Awalnya null
	const navigate = useNavigate();

	useEffect(() => {
		if (initialEmail && initialRole !== null) {
			setEmail(initialEmail);
			setRole(initialRole);
		}
	}, [initialEmail, initialRole]);

	function handleChange(e, index) {
		const value = e.target.value;
		if (!value.match(/^[a-zA-Z0-9]*$/)) return; // hanya menerima angka dan huruf
		setOtp([...otp.map((data, indx) => (indx === index ? value : data))]);

		if (value && e.target.nextSibling) {
			e.target.nextSibling.focus();
		}
	}

	function handlePaste(e) {
		let paste = e.clipboardData.getData("text");
		paste = paste.replace(/-/g, ""); // Menghapus tanda "-" jika ada

		if (paste.length <= otp.length && paste.match(/^[a-zA-Z0-9]*$/)) {
			setOtp([
				...paste
					.split("")
					.map((char, index) => (index < otp.length ? char : otp[index])),
			]);
			e.preventDefault();
		}
	}

	function handleKeyDown(e, index) {
		if (e.key === "Backspace" || e.key === "Delete") {
			setOtp([...otp.map((data, indx) => (indx === index ? "" : data))]);

			if (e.target.previousSibling && !otp[index]) {
				e.target.previousSibling.focus();
			}
		}
	}

	async function handleSubmit(e) {
		e.preventDefault();
		const verificationCode = otp.join("");

		let endpoint = "";

		switch (role) {
			case 3:
				endpoint =
					"https://meals-api.103.127.137.58.nip.io/api/v1/members/register";
				break;
			case 4:
				endpoint =
					"https://meals-api.103.127.137.58.nip.io/api/v1/partners/register";
				break;
			case 5:
				endpoint =
					"https://meals-api.103.127.137.58.nip.io/api/v1/patrons/register";
				break;
			case 6:
				endpoint =
					"https://meals-api.103.127.137.58.nip.io/api/v1/organizations/register";
				break;
			default:
				endpoint =
					"https://meals-api.103.127.137.58.nip.io/api/v1/meals/register"; // default case
				break;
		}

		try {
			const response = await fetch(endpoint, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ email, code: verificationCode }),
			});

			const result = await response.json();

			if (response.ok) {
				// Redirect berdasarkan role
				switch (role) {
					case 3:
						navigate("/home-meal");
						break;
					case 4:
						navigate("/home-partner");
						break;
					case 5:
						navigate("/home-patron");
						break;
					case 6:
						navigate("/home-meal");
						break;
					default:
						navigate("/home-meal");
						break;
				}
			} else {
				alert(`Error: ${result.message}`);
			}
		} catch (error) {
			console.error("Error:", error);
			alert("Something went wrong. Please try again later.");
		}
	}

	return (
		<form
			className="flex max-w-2xl flex-col gap-4 mx-auto mt-36"
			onSubmit={handleSubmit}
		>
			<div>
				<div className="flex mb-3">
					<FontAwesomeIcon icon={faArrowLeft} />
					<a href="/role" className="ml-2 text-sm">
						Back
					</a>
				</div>
				<h2 className="text-2xl font-bold leading-9 tracking-tight text-gray-800">
					Verification Code
				</h2>
				<p className="mt-4 mb-4 text-sm">We sent a code to {email}</p>
			</div>
			<div onPaste={handlePaste}>
				{otp.slice(0, 4).map((data, i) => (
					<input
						key={i}
						type="text"
						value={data}
						maxLength={1}
						onChange={(e) => handleChange(e, i)}
						onKeyDown={(e) => handleKeyDown(e, i)}
						className="otp"
					/>
				))}
				<span className="mx-2">-</span>
				{otp.slice(4, 8).map((data, i) => (
					<input
						key={i + 4}
						type="text"
						value={data}
						maxLength={1}
						onChange={(e) => handleChange(e, i + 4)}
						onKeyDown={(e) => handleKeyDown(e, i + 4)}
						className="otp"
					/>
				))}
			</div>
			<div>
				<p className="mt-4 text-sm mb-4">
					Didn't receive the email?
					<a
						href="#"
						className="ml-1 font-semibold text-cyan-600 dark:text-cyan-500 cursor-pointer"
					>
						Click to resend
					</a>
				</p>
			</div>
			<Button
				type="submit"
				className="w-[30vh] bg-green-600 text-white hover:bg-green-200"
			>
				Submit
			</Button>
		</form>
	);
}

export default SendCode;
