"use client";

import {
  Button,
  Checkbox,
  Label,
  TextInput,
  Select,
  Alert,
} from "flowbite-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import NavbarRegis from "../components/navbar/navbarRegis";
import "./register.css";
import axios from "axios";

function Patron() {
  const [isVerificationCodeSent, setIsVerificationCodeSent] = useState(false);
  const [show, setShow] = useState(false);
  const handleClick = () => {
    setShow(!show);
  };

  const [email, setEmail] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    type: "Personal",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const listTypes = ["Personal", "Group"];

  const [showConfirm, setShowConfirm] = useState(false);
  const confirmClick = () => {
    setShowConfirm(!showConfirm);
  };

  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [agree, setAgree] = useState(false);

  const navigate = useNavigate();

  const handleChange = (e, prop) => {
    const { value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [prop]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.password !== formData.confirmPassword) {
      alert("Passwords do not match");
      return;
    }

    const payload = {
      user: {
        email: formData.email,
        password: formData.password,
        confirm_password: formData.confirmPassword,
      },
      type: formData.type,
      name: formData.name,
    };

    // console.log(payload);
    // console.log(response);
    try {
      // const response = await axios.post(
      // 	"https://meals-api.103.127.137.58.nip.io/api/v1/patrons/register",
      // 	payload,
      // );

      // if (response) {
      // 	alert("Register patron success");
      // 	setEmail(payload.user.email);
      // 	// Set nilai email ke state
      // 	setIsVerificationCodeSent(true);
      // }

      const registerResponse = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}patrons/register`,
        payload
      );

      // * Set auth registerResponse to localStorage
      localStorage.setItem(
        "registerResponse",
        JSON.stringify({
          ...registerResponse.data.data,
          "access-token": registerResponse.headers["access-token"],
          "refresh-token": registerResponse.headers["refresh-token"],
        })
      );

      navigate("/verify-email");
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <NavbarRegis />
      <div className="flex max-w-md flex-col gap-4 mx-auto mt-16 mb-14">
        <div>
          <h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-gray-800">
            Sign Up for App
          </h2>
        </div>
        {errorMessage && (
          <Alert color="failure">
            <span>{errorMessage}</span>
          </Alert>
        )}
        {successMessage && (
          <Alert color="success">
            <span>{successMessage}</span>
          </Alert>
        )}

        <div className="org-email mr-2">
          <Label htmlFor="name" value="Name" />
          <TextInput
            id="name"
            className="mt-2"
            onChange={(e) => handleChange(e, "name")}
            required
          />
        </div>
        <div className="org-email mr-2">
          <Label htmlFor="type" value="Type" />
          {/* <TextInput id="name" className="mt-2" required /> */}
          <Select
            id="type"
            className="mt-2"
            required
            onChange={(e) => handleChange(e, "type")}
          >
            {listTypes.map((type, i) => (
              <option value={type} key={i}>
                {type}
              </option>
            ))}
          </Select>
        </div>
        <div className="org-email mr-2">
          <Label htmlFor="email" value="Email" />
          <TextInput
            id="email"
            className="mt-2"
            onChange={(e) => handleChange(e, "email")}
            required
          />
        </div>
        <div>
          <div className="mb-2 block">
            <Label htmlFor="password1" value="Password" />
          </div>
          <div className="flex">
            <input
              id="password1"
              type={show ? "text" : "password"}
              className="pass text-sm py-3 w-[100%] focus:border-cyan-500 focus:ring-cyan-500"
              placeholder="8+ characters"
              onChange={(e) => handleChange(e, "password")}
              required
            />
            <p
              onClick={handleClick}
              className="item-center ml-[-5vh] mt-3 cursor-pointer text-gray-400"
            >
              {show ? (
                <FontAwesomeIcon icon={faEyeSlash} />
              ) : (
                <FontAwesomeIcon icon={faEye} />
              )}
            </p>
          </div>
        </div>
        <div>
          <div className="mb-2 block">
            <Label htmlFor="password2" value="Confirmation Password" />
          </div>
          <div className="flex">
            <input
              id="password2"
              type={showConfirm ? "text" : "password"}
              className="confirm text-sm py-3 w-[100%] focus:border-cyan-500 focus:ring-cyan-500"
              placeholder="8+ characters"
              onChange={(e) => handleChange(e, "confirmPassword")}
              required
            />
            <p
              onClick={confirmClick}
              className="item-center ml-[-5vh] mt-3 cursor-pointer text-gray-400"
            >
              {showConfirm ? (
                <FontAwesomeIcon icon={faEyeSlash} />
              ) : (
                <FontAwesomeIcon icon={faEye} />
              )}
            </p>
          </div>
        </div>

        <div className="flex items-center max-w-xl -mt-2">
          <div className="remember cursor-pointer">
            <Checkbox
              id="remember"
              className="cursor-pointer"
              onChange={(e) => setAgree(e.target.checked)}
            />
            <Label htmlFor="remember" className="ml-2 text-xs cursor-pointer">
              I Agree
            </Label>
          </div>
          <div className="policy">
            <Label
              htmlFor="policy"
              className="ml-2 text-xs text-cyan-600 dark:text-cyan-500 cursor-pointer"
            >
              Privacy Policy
            </Label>
          </div>
        </div>
        <div className="text-xs text-center mt-4">
          <p>
            By clicking "I Agree" you agree to the application TOS <br />
            and Privacy Policy
          </p>
        </div>
        <Button
          type="submit"
          className="bg-green-600 text-white hover:bg-green-200"
          disabled={!agree}
        >
          Submit
        </Button>
        <div className="text-sm text-center -mt-2">
          <p>
            Already have an account?
            <a
              href="#"
              className="ml-1 font-semibold text-cyan-600 dark:text-cyan-500 cursor-pointer"
            >
              Log In
            </a>
          </p>
        </div>
      </div>
    </form>
  );
}

export default Patron;
