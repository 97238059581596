"use client";

import { Button } from "flowbite-react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faEyeSlash, faBuildingColumns, faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import NavbarPatron from "../components/navbar/navbarPatron";
import { getAuthAndProfileResponses } from "../utils/localstorage";

function HomePatron() {
  const navigate = useNavigate(); // Initialize useNavigate
  const { authResponse, profileResponse } = getAuthAndProfileResponses();

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission
    navigate('/donasi'); // Redirect to the donation page
  };

  return (
    <form onSubmit={handleSubmit}>
      <NavbarPatron />
      <div className="flex flex-col md:flex-row max-w-7xl mx-auto mt-8 items-center p-4">
        <div className="md:w-1/2 p-4">
          <img src="/img/money.svg" alt="Indonesia" className="h-auto w-80 ml-40 mb-10" />
        </div>
        <div className="md:w-1/2 p-4">
          {/* <h2 className="text-2xl font-bold leading-9 tracking-tight text-gray-800 mb-6">Hello, Jacob</h2> */}
          <h2 className="text-2xl font-bold leading-9 tracking-tight text-gray-800 mb-6">
            Hello, {" "}
              {profileResponse?.first_name
              ? profileResponse?.first_name + " " + profileResponse?.last_name
              : authResponse?.email}
          </h2>
          <div className="flex flex-col space-y-4">
            <div className="flex items-start space-x-4">
              <FontAwesomeIcon icon={faCircleCheck} className="text-green-500 mt-4 mr-3 w-8 h-8" />
              <p className="text-xs">
                We will protect your transactions and accounts by implementing strict security measures, including data encryption, multi-factor authentication, and continuous activity monitoring, to ensure that your personal and financial information remains safe from threats and unauthorized access.
              </p>
            </div>
            <div className="flex items-start space-x-4">
              <FontAwesomeIcon icon={faLock} className="text-cyan-500 mt-4 mr-4 w-8 h-8" />
              <p className="text-xs">
                We will protect your personal data by implementing strict security measures, including advanced encryption, multi-factor authentication, and continuous monitoring, and ensuring that your data can only be accessed by authorized parties and used in accordance with strict privacy policies and protective regulations applicable data.
              </p>
            </div>
            <div className="flex items-start space-x-4">
              <FontAwesomeIcon icon={faEyeSlash} className="text-yellow-300 mt-4 mr-3 w-8 h-8" />
              <p className="text-xs">
                We will protect your personal data by implementing strict security measures, including advanced encryption, multi-factor authentication, and continuous monitoring, and ensuring that your data can only be accessed by authorized parties and used in accordance with strict privacy policies and protective regulations applicable data.
              </p>
            </div>
            <div className="flex items-start space-x-4">
              <FontAwesomeIcon icon={faBuildingColumns} className="text-purple-800 mt-4 mr-3 w-8 h-8" />
              <p className="text-xs">
                We will protect your personal data by implementing strict security measures, including advanced encryption, multi-factor authentication, and continuous monitoring, and ensuring that your data can only be accessed by authorized parties and used in accordance with strict privacy policies and protective regulations applicable data.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center mt-8">
        <Button type="submit" className="w-80 bg-green-600 text-white hover:bg-green-200">Donation</Button>
      </div>
    </form>
  );
}

export default HomePatron;