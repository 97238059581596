"use client";

import { Label, Button, TextInput, Textarea } from "flowbite-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faChevronLeft,
	faEye,
	faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import SidebarMember from "../components/sidebar/sidebarMember";
import { useState } from "react";
import "./user.css";
import { Link } from "react-router-dom";

function UserProfile() {
	const [show, setShow] = useState(false);
	const [showConfirm, setShowConfirm] = useState(false);
	const [profileImage, setProfileImage] = useState(null);
	const [imageError, setImageError] = useState("");
	const [imageName, setImageName] = useState("");

	const handleClick = () => setShow(!show);
	const confirmClick = () => setShowConfirm(!showConfirm);

	const handleImageChange = (e) => {
		const file = e.target.files[0];
		if (file && file.size > 2 * 1024 * 1024) {
			setImageError("File size exceeds 2MB**");
			setImageName("");
		} else {
			setImageError("");
			setProfileImage(URL.createObjectURL(file));
			setImageName(file.name);
		}
	};

	return (
		<div className="flex h-screen">
			<SidebarMember className="fixed h-full" />
			<div className="flex flex-col flex-grow p-6 overflow-y-auto">
				<div className="flex items-center justify-between mb-4">
					<div className="flex items-center">
						<Link to="/home-meal">
							<FontAwesomeIcon icon={faChevronLeft} className="text-lg" />
						</Link>

						<div className="mb-2 ml-12">
							<h2 className="text-2xl font-bold leading-9 tracking-tight text-gray-800">
								Account
							</h2>
							<p className="text-xs text-gray-600">
								This is detail your account
							</p>
						</div>
					</div>
				</div>
				<hr className="border-gray-400 mb-4" />
				<div className="mt-4 ml-12 lg:flex-row lg:gap-6">
					<div className="flex">
						<div>
							<img
								src={profileImage || "/img/profile.jpeg"}
								alt="Profile"
								className="h-36 w-36 inline-block rounded-full border-2"
							/>
						</div>
						<div className="ml-14 mt-4">
							<p className="font-bold text-lg">Profile Picture</p>
							<p className="font-semibold text-[12px] text-gray-400">
								PNG, JPG, JPEG up to 5MB
							</p>
							<div className="flex mt-8">
								<label
									className="text-md font-semibold cursor-pointer text-green-600"
									htmlFor="fileInput"
								>
									Change Picture
								</label>
								<input
									id="fileInput"
									type="file"
									accept="image/png, image/jpeg"
									onChange={handleImageChange}
									className="hidden"
								/>
								{imageName && (
									<p className="ml-8 text-sm font-medium text-gray-500">
										{imageName}
									</p>
								)}
								{imageError && (
									<p className="ml-8 text-red-500 text-sm font-semibold">
										{imageError}
									</p>
								)}
							</div>
						</div>
					</div>
					<div className="gap-4 w-[50vw] mt-8">
						<h3 className="font-bold text-xl mb-4">Details</h3>
						<div className="name flex">
							<div className="mt-2 w-[100%] mr-2">
								<Label htmlFor="firstName1" value="First Name" />
								<TextInput
									id="firstName1"
									type="text"
									className="mt-2 w-[100%]"
									required
								/>
							</div>
							<div className="mt-2 w-[100%] ml-2">
								<Label htmlFor="lastName1" value="Last Name" />
								<TextInput
									id="lastName1"
									type="text"
									className="mt-2 w-[100%]"
									required
								/>
							</div>
						</div>
						<div className="mt-2 w-[100%]">
							<Label htmlFor="email1" value="Email" />
							<TextInput
								id="email1"
								type="text"
								className="mt-2 w-[100%]"
								required
							/>
						</div>
						<div className="mt-2 w-[100%]">
							<Label htmlFor="address1" value="Address" />
							<Textarea
								id="comment"
								required
								rows={5}
								className="mt-2 w-[100%]"
							/>
						</div>
						<Button
							type="submit"
							className="mt-8 w-[15vw] bg-green-600 text-white hover:bg-green-200"
						>
							Save
						</Button>
					</div>
					<div className="gap-4 w-[50vw] mt-12">
						<h3 className="mt-4 mb-4 font-bold">Change Password</h3>
						<div>
							<div className="mb-2 block mt-2">
								<Label htmlFor="currentPassword" value="Current Password" />
							</div>
							<div className="flex">
								<input
									id="currentPassword"
									type={show ? "text" : "password"}
									className="pass text-sm py-3 w-[100%] focus:border-cyan-500 focus:ring-cyan-500"
									placeholder="8+ characters"
									required
								/>
								<p
									onClick={handleClick}
									className="item-center ml-[-5vh] mt-3 cursor-pointer text-gray-400"
								>
									{show ? (
										<FontAwesomeIcon icon={faEyeSlash} />
									) : (
										<FontAwesomeIcon icon={faEye} />
									)}
								</p>
							</div>
						</div>
						<div>
							<div className="mb-2 block mt-2">
								<Label htmlFor="newPassword" value="New Password" />
							</div>
							<div className="flex">
								<input
									id="newPassword"
									type={showConfirm ? "text" : "password"}
									className="confirm text-sm py-3 w-[100%] focus:border-cyan-500 focus:ring-cyan-500"
									placeholder="8+ characters"
									required
								/>
								<p
									onClick={confirmClick}
									className="item-center ml-[-5vh] mt-3 cursor-pointer text-gray-400"
								>
									{showConfirm ? (
										<FontAwesomeIcon icon={faEyeSlash} />
									) : (
										<FontAwesomeIcon icon={faEye} />
									)}
								</p>
							</div>
						</div>
						<Button
							type="submit"
							className="mt-8 w-[15vw] bg-green-600 text-white hover:bg-green-200"
						>
							Save
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default UserProfile;
