"use client";

import { Button, Checkbox, Label, TextInput } from "flowbite-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faL } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import NavbarRegis from "../components/navbar/navbarRegis";
import { useNavigate } from "react-router-dom";
import "./register.css";
import { MultiSelect } from "react-multi-select-component";
import SendCode from "../forgotPass/sendCode";
import axios from "axios";

function Member() {
  let latitude = 0;
  let longitude = 0;

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    dateOfBirth: "",
    height: "",
    weight: "",
    gender: "Male",
    email: "",
    password: "",
    allergy_id: "",
    illness_id: "",
    addresses_name: "",
    addresses_address: "",
    addresses_note: "",
    addresses_latitude: "",
    addresses_longitude: "",
    confirmPassword: "",
    role: 3, // Tambahkan role di sini
  });

  const [agreeToTerms, setAgreeToTerms] = useState(false);

  const genders = ["Male", "Female"];

  const [show, setShow] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const navigate = useNavigate();
  const [isVerificationCodeSent, setIsVerificationCodeSent] = useState(false);
  const [email, setEmail] = useState(""); // Tambahkan state untuk email
  const [allergies, setAllergies] = useState([]);
  const [illnesses, setIllnesses] = useState([]);
  const [dob, setDob] = useState("");
  const [fileBase, setFileBase] = useState("");
  const [fileInfo, setFileInfo] = useState({});
  const [allergyData, setAllergyData] = useState([]);
  const [illnessData, setIllnessData] = useState([]);

  const handleClick = () => setShow(!show);
  const confirmClick = () => setShowConfirm(!showConfirm);

  const handleChange = (e, prop) => {
    const { value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [prop]: e.target.value,
    }));
  };

  const handleDob = (e) => {
    setDob(e.target.value);
  };

  const handleUpload = (e) => {
    const file = e.target.files[0];
    setFileInfo(file);

    const reader = new FileReader();

    reader.onloadend = () => {
      const result = reader.result;
      const base64 = result.split(",")[1];
      setFileBase(base64);
    };
    reader.readAsDataURL(file);
  };

  useEffect(() => {
    const loadData = async () => {
      const { data: responseAllergies } = await axios.get(
        process.env.REACT_APP_API_BASE_URL + "allergies/raw"
      );

      const { data: responseIllnesses } = await axios.get(
        process.env.REACT_APP_API_BASE_URL + "illnesses/raw"
      );

      const allergies = responseAllergies.data;
      const illnesses = responseIllnesses.data;

      const arrAllergies = [...allergies].map((data) => {
        return { ...data, label: data.name, value: data.id };
      });
      const arrIllnesses = [...illnesses].map((data) => {
        return { ...data, label: data.name, value: data.id };
      });

      setAllergies(arrAllergies);
      setIllnesses(arrIllnesses);
    };

    loadData();

    if (navigator.geolocation) {
      // Request the current position
      navigator.geolocation.getCurrentPosition(
        function (position) {
          // Extract latitude and longitude
          latitude = position.coords.latitude;
          longitude = position.coords.longitude;

          console.log("Latitude: " + latitude);
          console.log("Longitude: " + longitude);
        },
        function (error) {
          // Handle errors
          console.error("Error occurred. Error code: " + error.code);
          // Error codes: 1 = permission denied, 2 = position unavailable, 3 = timeout
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.password !== formData.confirmPassword) {
      alert("Passwords do not match");
      return;
    }

    let addresses = [
      {
        name: formData?.addresses_name,
        address: formData?.addresses_address,
        note: formData?.addresses_note,
        latitude: latitude,
        longitude: longitude,
      },
    ];

    if (latitude !== 0 || !longitude !== 0) {
      addresses = null;
    }

    const payload = {
      user: {
        email: formData?.email,
        password: formData?.password,
        confirm_password: formData?.confirmPassword,
        addresses: addresses,
        file_base64: fileBase,
        file_name: fileInfo?.name,
        file_type: fileInfo?.type,
      },
      height: parseFloat(formData?.height),
      weight: parseFloat(formData?.weight),
      first_name: formData?.firstName,
      last_name: formData?.lastName,
      gender: formData?.gender,
      date_of_birth: dob,
      illness_id: illnessData.map((data) => data.id),
      allergy_id: allergyData.map((data) => data.id),
    };

    try {
      const registerResponse = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}members/register`,
        payload
      );

      // * Set auth registerResponse to localStorage
      localStorage.setItem(
        "registerResponse",
        JSON.stringify({
          ...registerResponse.data.data,
          "access-token": registerResponse.headers["access-token"],
          "refresh-token": registerResponse.headers["refresh-token"],
        })
      );

      navigate("/verify-email"); // Navigate to verify-email on successful registration
    } catch (error) {
      // Display a more detailed error message
      alert(`Something went wrong. Please try again later. Error: ${error}`);
    }
  };

  // Menampilkan form verifikasi jika registrasi berhasil
  if (isVerificationCodeSent) {
    return <SendCode email={formData.email} role={3} />;
  }

  return (
    <form onSubmit={handleSubmit}>
      <NavbarRegis />
      <div className="flex max-w-md flex-col gap-4 mx-auto mt-16 mb-14">
        <div>
          <h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-gray-800">
            Sign Up for App
          </h2>
        </div>
        <div className="flex">
          <div className="m-name mr-2">
            <Label htmlFor="firstName" value="First Name" />
            <TextInput
              id="firstName"
              className="mt-2"
              value={formData.firstName}
              onChange={(e) => handleChange(e, "firstName")}
              required
            />
          </div>
          <div className="m-username">
            <Label htmlFor="lastName" value="Last Name" />
            <TextInput
              id="lastName"
              className="mt-2"
              value={formData.lastName}
              onChange={(e) => handleChange(e, "lastName")}
              required
            />
          </div>
        </div>
        <div>
          <Label htmlFor="email" value="Email" />
          <TextInput
            id="email"
            type="email"
            value={formData.email}
            onChange={(e) => handleChange(e, "email")}
            required
            className="mt-2"
          />
        </div>

        {/* gender */}
        <div>
          <Label htmlFor="gender" value="Gender" />
          <div className="flex mt-2">
            <select
              name=""
              value={formData.gender}
              id="gender"
              className="select-option"
              onChange={(e) => handleChange(e, "gender")}
            >
              {genders.map((gender, i) => (
                <option value={gender} key={i}>
                  {gender}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div>
          <Label htmlFor="height" value="Height" />
          <TextInput
            id="height"
            type="number"
            placeholder="Your Height"
            value={formData.height}
            onChange={(e) => handleChange(e, "height")}
            required
            className="mt-2"
          />
        </div>

        <div>
          <Label htmlFor="weight" value="Weight" />
          <TextInput
            id="weight"
            type="number"
            placeholder="Your Weight"
            value={formData.weight}
            onChange={(e) => handleChange(e, "weight")}
            required
            className="mt-2"
          />
        </div>

        {/* allergy id */}
        <div>
          <Label htmlFor="allergies" value="Allergy" />
          <div className="flex mt-2">
            <MultiSelect
              options={allergies}
              className="select-option"
              value={allergyData}
              onChange={setAllergyData}
              labelledBy="Allergies"
            />
          </div>
        </div>

        <div>
          <Label htmlFor="illnesses" value="Illness" />
          <div className="flex mt-2">
            <MultiSelect
              options={illnesses}
              className="select-option"
              value={illnessData}
              onChange={setIllnessData}
              labelledBy="Illness"
            />
          </div>
        </div>

        <div>
          <Label htmlFor="dob" value="Date of Birth" />
          <TextInput
            id="dob"
            type="date"
            placeholder="Your Date of Birth"
            defaultValue={formData.dateOfBirth}
            onChange={handleDob}
            required
            className="mt-2"
          />
        </div>

        <div>
          <Label htmlFor="image" value="Image" />
          <TextInput
            id="image"
            type="file"
            placeholder="Your Image"
            onChange={handleUpload}
            required
            className="mt-2"
          />
        </div>

        <div>
          <Label htmlFor="password" value="Password" />
          <div className="flex mt-2">
            <input
              id="password"
              type={show ? "text" : "password"}
              className="pass text-sm py-3 w-[100%] focus:border-cyan-500 focus:ring-cyan-500"
              placeholder="8+ characters"
              value={formData.password}
              onChange={(e) => handleChange(e, "password")}
              required
            />
            <p
              onClick={handleClick}
              className="item-center ml-[-5vh] mt-3 cursor-pointer text-gray-400"
            >
              {show ? (
                <FontAwesomeIcon icon={faEyeSlash} />
              ) : (
                <FontAwesomeIcon icon={faEye} />
              )}
            </p>
          </div>
        </div>

        <div>
          <Label htmlFor="confirmPassword" value="Confirmation Password" />
          <div className="flex mt-2">
            <input
              id="confirmPassword"
              type={showConfirm ? "text" : "password"}
              className="confirm text-sm py-3 w-[100%] focus:border-cyan-500 focus:ring-cyan-500"
              placeholder="8+ characters"
              value={formData.confirmPassword}
              onChange={(e) => handleChange(e, "confirmPassword")}
              required
            />
            <p
              onClick={confirmClick}
              className="item-center ml-[-5vh] mt-3 cursor-pointer text-gray-400"
            >
              {showConfirm ? (
                <FontAwesomeIcon icon={faEyeSlash} />
              ) : (
                <FontAwesomeIcon icon={faEye} />
              )}
            </p>
          </div>
        </div>

        <div>
          <h2 className="font-bold mb-2">Address</h2>
          <div className="mb-4">
            <Label htmlFor="address_name" value="Name" />
            <TextInput
              id="address_name"
              type="text"
              placeholder="Home Address"
              value={formData?.addresses_name}
              onChange={(e) => handleChange(e, "addresses_name")}
              required
              className="mt-2"
            />
          </div>

          <div className="mb-2">
            <Label htmlFor="address" value="Adress" />
            <TextInput
              id="address"
              type="text"
              placeholder="Your Adress"
              value={formData?.addresses_address}
              onChange={(e) => handleChange(e, "addresses_address")}
              required
              className="mt-2"
            />
          </div>

          <div className="mb-2">
            <Label htmlFor="address_note" value="Note" />
            <TextInput
              id="address_note"
              type="text"
              placeholder="Your Adress Note"
              value={formData?.addresses_note}
              onChange={(e) => handleChange(e, "addresses_note")}
              required
              className="mt-2"
            />
          </div>
        </div>

        <div className="flex items-center max-w-xl -mt-2">
          <div className="remember cursor-pointer">
            <Checkbox
              id="agreeToTerms"
              onChange={(e) => setAgreeToTerms(e.target.checked)}
              className="cursor-pointer"
            />
            <Label
              htmlFor="agreeToTerms"
              className="ml-2 text-xs cursor-pointer"
            >
              I Agree
            </Label>
          </div>
          <div className="policy">
            <Label
              htmlFor="policy"
              className="ml-2 text-xs text-cyan-600 dark:text-cyan-500 cursor-pointer float-right"
            >
              Privacy Policy
            </Label>
          </div>
        </div>
        <div className="text-xs text-center mt-4">
          <p>
            By clicking "I Agree" you agree to the application TOS <br />
            and Privacy Policy
          </p>
        </div>
        <Button
          type="submit"
          className="bg-green-600 text-white hover:bg-green-200"
          disabled={!agreeToTerms}
        >
          Submit
        </Button>
        <div className="text-sm text-center -mt-2">
          <p>
            Already have an account?
            <a
              href="#"
              className="ml-1 font-semibold text-cyan-600 dark:text-cyan-500 cursor-pointer"
            >
              Log In
            </a>
          </p>
        </div>
      </div>
    </form>
  );
}

export default Member;
