"use client";

import { Checkbox, Table } from "flowbite-react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faFilter, faArrowUpAZ, faPlus, faSquareMinus, faPen, faTrash, faAnglesRight, faAnglesLeft } from '@fortawesome/free-solid-svg-icons';
import { useState, useEffect } from "react";
import SidebarAdmin from "../components/sidebar/sidebarAdmin";

function AdminListPatron() {
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortDirection, setSortDirection] = useState('asc');
  const dataPerPage = 10;
  const [totalData, setTotalData] = useState(50); // Placeholder total data count

  useEffect(() => {
    fetchData();
  }, [currentPage, searchTerm, selectedFilters, sortDirection]);

  const fetchData = async () => {
    try {
      const url = new URL('https://example.com/api/v1/patrons'); // Replace with your API endpoint
      const params = {
        page: currentPage,
        limit: dataPerPage,
        sort: sortDirection,
        q: searchTerm,
        filters: selectedFilters.join(','),
      };

      Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));

      const response = await fetch(url);
      const result = await response.json();

      setData(result.data.items || []);
      setTotalData(result.data.total || 0);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSortChange = () => {
    setSortDirection(prevDirection => prevDirection === 'asc' ? 'desc' : 'asc');
  };

  const handleFilterChange = (filter) => {
    setSelectedFilters(prevFilters => {
      if (prevFilters.includes(filter)) {
        return prevFilters.filter(f => f !== filter);
      } else {
        return [...prevFilters, filter];
      }
    });
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredData = data.filter(item =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const sortedData = filteredData.sort((a, b) => {
    const compare = a.name.localeCompare(b.name);
    return sortDirection === 'asc' ? compare : -compare;
  });

  const paginatedData = sortedData.slice((currentPage - 1) * dataPerPage, currentPage * dataPerPage);

  return (
    <div className="flex h-screen">
      <SidebarAdmin className="fixed h-full" />
      <div className="flex flex-col flex-grow p-6 overflow-y-auto">
        <div className="flex justify-between items-center mb-4">
          <div className="mb-2">
            <h2 className="text-2xl font-bold leading-9 tracking-tight text-gray-800">List Patron</h2>
            <p className="text-xs text-gray-600">This is a list of patrons</p>
          </div>
          <div className="relative flex items-center">
            <FontAwesomeIcon icon={faMagnifyingGlass} className="absolute left-3 text-gray-600"/>
            <input
              type="text"
              className="pl-10 pr-4 py-2 border border-gray-300 rounded-lg text-sm w-80"
              placeholder="Search..."
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
        </div>
        <hr className="border-gray-400 mb-4"/>
        <div className="flex justify-between items-center mb-4">
          <div
            className="flex items-center space-x-2 border border-gray-300 rounded-lg px-3 py-2 text-sm cursor-pointer"
            onClick={() => handleFilterChange('filterName')} // Adjust filterName based on your data
          >
            <FontAwesomeIcon icon={faFilter} className="text-gray-600"/>
            <span className="text-gray-600">Filter</span>
            {selectedFilters.length > 0 && (
              <div className="flex items-center justify-center w-5 h-5 border rounded-full text-white text-xs bg-green-500 text-semibold">
                {selectedFilters.length}
              </div>
            )}
          </div>
          <div
            className="flex items-center space-x-2 border border-gray-300 rounded-lg px-3 py-2 ml-4 text-sm cursor-pointer"
            onClick={handleSortChange}
          >
            <FontAwesomeIcon icon={faArrowUpAZ} className="text-gray-600"/>
            <span className="text-gray-600">Sort</span>
          </div>
          <div className="flex-grow"></div>
          <div className="flex items-center space-x-2 border border-gray-300 rounded-lg px-3 py-2 text-sm cursor-pointer">
            <FontAwesomeIcon icon={faPlus} className="text-gray-600"/>
            <span className="text-gray-600">Add new</span>
          </div>
        </div>
        <div className="overflow-x-auto">
          <Table hoverable>
            <Table.Head>
              <Table.HeadCell className="p-4">
                <FontAwesomeIcon icon={faSquareMinus} className="text-lg" />
              </Table.HeadCell>
              <Table.HeadCell>Name</Table.HeadCell>
              <Table.HeadCell>Region</Table.HeadCell>
              <Table.HeadCell>Donation</Table.HeadCell>
              <Table.HeadCell>Total Donate</Table.HeadCell>
              <Table.HeadCell>Date added</Table.HeadCell>
              <Table.HeadCell>Action</Table.HeadCell>
            </Table.Head>
            <Table.Body className="divide-y">
              {paginatedData.length === 0 ? (
                <Table.Row>
                  <Table.Cell colSpan="7" className="text-center">No data available</Table.Cell>
                </Table.Row>
              ) : (
                paginatedData.map((patron) => (
                  <Table.Row key={patron.id} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                    <Table.Cell className="p-4">
                      <Checkbox />
                    </Table.Cell>
                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                      <div className="flex">
                        <img src={patron.imgSrc || '/img/default-avatar.png'} alt={patron.name} className="h-7 w-7 inline-block rounded-full mr-3 mt-1" />
                        <div>
                          <p className="font-semibold">{patron.name}</p>
                          <p className="text-xs">{patron.email}</p>
                        </div>
                      </div>
                    </Table.Cell>
                    <Table.Cell>{patron.region}</Table.Cell>
                    <Table.Cell>{patron.donationType}</Table.Cell>
                    <Table.Cell>{patron.totalDonate}</Table.Cell>
                    <Table.Cell>{new Date(patron.dateAdded).toLocaleDateString()}</Table.Cell>
                    <Table.Cell>
                      <a href="#" className="font-medium text-cyan-600 hover:underline dark:text-cyan-500 mr-4">
                        <FontAwesomeIcon icon={faPen} />
                      </a>
                      <a href="#" className="font-medium text-red-600 hover:underline dark:text-cyan-500">
                        <FontAwesomeIcon icon={faTrash} />
                      </a>
                    </Table.Cell>
                  </Table.Row>
                ))
              )}
            </Table.Body>
          </Table>
        </div>
        <div className="flex justify-center mt-4">
          <nav className="inline-flex rounded-md shadow-sm" aria-label="Pagination">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="relative inline-flex items-center px-3 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-l-md hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <FontAwesomeIcon icon={faAnglesLeft} />
            </button>
            {Array.from({ length: Math.ceil(totalData / dataPerPage) }, (_, index) => (
              <button
                key={index + 1}
                onClick={() => handlePageChange(index + 1)}
                className={`relative inline-flex items-center px-4 py-2 text-sm font-medium ${
                  currentPage === index + 1
                    ? 'text-white bg-cyan-600 border-cyan-600'
                    : 'text-gray-500 bg-white border-gray-300'
                } hover:bg-gray-50`}
              >
                {index + 1}
              </button>
            ))}
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === Math.ceil(totalData / dataPerPage)}
              className="relative inline-flex items-center px-3 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-r-md hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <FontAwesomeIcon icon={faAnglesRight} />
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
}

export default AdminListPatron;