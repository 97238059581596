"use client";

import { Button } from "flowbite-react";
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import NavbarPatron from "../components/navbar/navbarPatron";

function DonationSummary() {
  const navigate = useNavigate();
  const [donationAmount, setDonationAmount] = useState('Rp. 0');

  useEffect(() => {
    const amount = localStorage.getItem('donationAmount') || 'Rp. 0'; // Ambil dari localStorage
    setDonationAmount(amount); // Set state dengan nilai donasi
  }, []);

  const handleBackClick = () => {
    navigate('/success');
  };

  return (
    <form>
      <NavbarPatron />
      <div className="flex flex-col max-w-7xl mx-auto mt-8 items-center p-4 text-center">
        <h2 className="text-2xl font-bold leading-9 tracking-tight text-gray-800 mb-4">How much do you want to donate now?</h2>
        <p className="mb-8 max-w-2xl">Your donation is received now, but you pay nothing today and make payments over time. There's no interest or fees and applying does not affect your credit score</p>
      </div>
      <div className="flex justify-center">
        <div className="bg-gray-200 rounded-md p-6 max-w-2xl w-full">
          <h4 className="text-lg font-bold mb-4">Donation Summary</h4>
          <div className="space-y-2 mb-4">
            <div className="flex justify-between">
              <p>Healthy Food for the elderly</p>
              <p>{donationAmount}</p>
            </div>
            <div className="flex justify-between">
              <p>Healthy Food for the elderly</p>
              <p>{donationAmount}</p>
            </div>
          </div>
          <hr className="border-b border-gray-500 mb-4"/>
          <div>
            <p className="text-lg font-bold mb-4">Donation transaction process</p>
            <div className="space-y-2">
              <div className="flex items-center relative">
                <div className="w-3 h-3 bg-green-500 rounded-full mr-4"></div>
                <div className="absolute w-0.5 bg-green-500 h-full left-1 top-4"></div>
                <p>Waiting for payment</p>
                <p className="ml-auto">{donationAmount}</p>
              </div>
              <div className="flex items-center relative">
                <div className="w-3 h-3 bg-green-500 rounded-full mr-4"></div>
                <div className="absolute w-0.5 bg-green-500 h-full left-1 top-4"></div>
                <p>Proof of donation is pending</p>
                <p className="ml-auto">{donationAmount}</p>
              </div>
              <div className="flex items-center">
                <div className="w-3 h-3 bg-green-500 rounded-full mr-4"></div>
                <p>Donations accepted</p>
                <p className="ml-auto">{donationAmount}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center mt-8 mb-10">
        <Button type="submit" onClick={handleBackClick} className="w-80 bg-green-600 text-white hover:bg-green-200">Donation</Button>
      </div>
    </form>
  );
}

export default DonationSummary;