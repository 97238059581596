"use client";

import { Checkbox, Table } from "flowbite-react";
import SidebarPartner from "../components/sidebar/sidebarPartner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlass,
  faFilter,
  faArrowUpAZ,
  faPlus,
  faSquareMinus,
  faAnglesRight,
  faAnglesLeft,
} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

function OrderMember() {
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const dataPerPage = 10;
  const totalData = 12; // Total data for example
  const totalPages = Math.ceil(totalData / dataPerPage);

  const [orders, setOrders] = useState([
    {
      id: 1,
      name: "Bambang Nurwanto",
      email: "bambang@gmail.com",
      date: "16 April 2024",
      menu: "Lontong sayur, Telor Balado",
      total: "20 Orders",
      status: "Delivered",
    },
    {
      id: 2,
      name: "Annisa",
      email: "annisa@gmail.com",
      date: "16 April 2024",
      menu: "Lontong sayur, Telor Balado",
      total: "20 Orders",
      status: "Picked Up",
    },
    // Add more orders as needed
  ]);

  const [selectedOrders, setSelectedOrders] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isSortedAZ, setIsSortedAZ] = useState(true);

  const handleCheckboxChange = (orderId) => {
    if (selectedOrders.includes(orderId)) {
      setSelectedOrders(selectedOrders.filter((id) => id !== orderId));
    } else {
      setSelectedOrders([...selectedOrders, orderId]);
    }
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSort = () => {
    setIsSortedAZ(!isSortedAZ);
    setOrders(
      [...orders].sort((a, b) =>
        isSortedAZ ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name)
      )
    );
  };

  const getStatusColor = (status) => {
    const statuses = {
      Placed: "bg-blue-100 text-blue-800",
      Confirmed: "bg-yellow-100 text-yellow-800",
      "Being Prepared": "bg-green-100 text-green-800 text-[7.5px]",
      Prepared: "bg-red-100 text-red-800",
      "Picked Up": "bg-purple-100 text-purple-800",
      "Out for Delivery": "bg-blue-100 text-blue-800 text-[7.5px]",
      Delivered: "bg-green-100 text-green-800",
      Completed: "bg-purple-100 text-purple-800",
      Rejected: "bg-red-100 text-red-600",
    };
    return statuses[status] || "";
  };

  const filteredOrders = orders.filter(
    (order) =>
      order.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      order.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
      order.menu.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleMenuClick = (orderId) => {
    navigate(`/order-details/${orderId}`);
  };

  return (
    <div className="flex h-screen">
      <SidebarPartner className="fixed h-full" />
      <div className="flex flex-col flex-grow p-6 overflow-y-auto">
        <div className="flex justify-between items-center mb-4">
          <div className="mb-2">
            <h2 className="text-2xl font-bold leading-9 tracking-tight text-gray-800">
              Order Member
            </h2>
            <p className="text-xs text-gray-600">This is a list of members</p>
          </div>
          <div className="relative flex items-center">
            <FontAwesomeIcon
              icon={faMagnifyingGlass}
              className="absolute left-3 text-gray-600"
            />
            <input
              type="text"
              className="pl-10 pr-4 py-2 border border-gray-300 rounded-lg text-sm w-80"
              placeholder="Search..."
              value={searchQuery}
              onChange={handleSearch}
            />
          </div>
        </div>
        <hr className="border-gray-400 mb-4" />
        <div className="flex justify-between items-center mb-4">
          <div className="flex items-center space-x-2 border border-gray-300 rounded-lg px-3 py-2 text-sm cursor-pointer">
            <FontAwesomeIcon icon={faFilter} className="text-gray-600" />
            <a href="#" className="text-gray-600">
              Filter
            </a>
            {selectedOrders.length > 0 && (
              <div className="flex items-center justify-center w-5 h-5 border rounded-full text-white text-xs bg-green-500 text-semibold">
                {selectedOrders.length}
              </div>
            )}
          </div>
          <div
            className="flex items-center space-x-2 border border-gray-300 rounded-lg px-3 py-2 ml-4 text-sm cursor-pointer"
            onClick={handleSort}
          >
            <FontAwesomeIcon icon={faArrowUpAZ} className="text-gray-600" />
            <a href="#" className="text-gray-600">
              Sort
            </a>
          </div>
          <div className="flex-grow"></div>
          <div className="flex items-center space-x-2 border border-gray-300 rounded-lg px-3 py-2 text-sm cursor-pointer">
            <FontAwesomeIcon icon={faPlus} className="text-gray-600" />
            <a href="#" className="text-gray-600">
              Add new
            </a>
          </div>
        </div>
        <div className="overflow-x-auto">
          <Table hoverable>
            <Table.Head>
              <Table.HeadCell className="p-4">
                <FontAwesomeIcon icon={faSquareMinus} className="text-lg" />
              </Table.HeadCell>
              <Table.HeadCell>Name Customer</Table.HeadCell>
              <Table.HeadCell>Status</Table.HeadCell>
              <Table.HeadCell>Time Order</Table.HeadCell>
              <Table.HeadCell>Menu Order</Table.HeadCell>
              <Table.HeadCell>Total</Table.HeadCell>
              <Table.HeadCell>Actions</Table.HeadCell>
            </Table.Head>
            <Table.Body className="divide-y">
              {filteredOrders.map((order) => (
                <Table.Row
                  key={order.id}
                  className="bg-white dark:border-gray-700 dark:bg-gray-800"
                >
                  <Table.Cell className="p-4">
                    <Checkbox
                      checked={selectedOrders.includes(order.id)}
                      onChange={() => handleCheckboxChange(order.id)}
                    />
                  </Table.Cell>
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                    <div className="flex">
                      <img
                        src="/img/eng-flag.jpeg"
                        alt="Indonesia"
                        className="h-7 w-7 inline-block rounded-full mr-3 mt-1"
                      />
                      <div>
                        <p className="font-semibold">{order.name}</p>
                        <p className="text-xs">{order.email}</p>
                      </div>
                    </div>
                  </Table.Cell>
                  <Table.Cell>
                    <span
                      className={`py-1 px-4 text-xs rounded-full font-semibold ${getStatusColor(
                        order.status
                      )}`}
                    >
                      {order.status}
                    </span>
                  </Table.Cell>
                  <Table.Cell>{order.date}</Table.Cell>
                  <Table.Cell
                    className="cursor-pointer text-cyan-600"
                    onClick={() => handleMenuClick(order.id)}
                  >
                    {order.menu}
                  </Table.Cell>
                  <Table.Cell>{order.total}</Table.Cell>
                  <Table.Cell>
                    <span
                      className={`py-1 px-4 text-xs rounded-full font-semibold ${getStatusColor(
                        order.status
                      )}`}
                    >
                      {order.status}
                    </span>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
        <div className="flex justify-center mt-4">
          <nav
            className="inline-flex rounded-md shadow-sm"
            aria-label="Pagination"
          >
            <button
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={currentPage === 1}
              className="relative inline-flex items-center px-3 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-l-md hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <FontAwesomeIcon icon={faAnglesLeft} />
            </button>
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index + 1}
                onClick={() => setCurrentPage(index + 1)}
                className={`relative inline-flex items-center px-4 py-2 text-sm font-medium ${
                  currentPage === index + 1
                    ? "text-white bg-cyan-600 border-cyan-600"
                    : "text-gray-500 bg-white border-gray-300"
                } hover:bg-gray-50`}
              >
                {index + 1}
              </button>
            ))}
            <button
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="relative inline-flex items-center px-3 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-r-md hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <FontAwesomeIcon icon={faAnglesRight} />
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
}

export default OrderMember;
