"use client";

import { Checkbox, Table } from "flowbite-react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faFilter, faArrowUpAZ, faPlus, faSquareMinus, faPen, faTrash, faAnglesRight, faAnglesLeft } from '@fortawesome/free-solid-svg-icons';
import { useState } from "react";
import SidebarAdmin from "../components/sidebar/sidebarAdmin";

function AdminMemberIllness() {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedFilters, setSelectedFilters] = useState(new Set());
  const [data, setData] = useState([
    { id: 1, name: 'Zelkan Mark', email: 'zelkan.mark@example.com', sex: 'Male', dob: '16 April 1956', illness: 'Appendicitis', dateAdded: '14 Jul 2020' },
    { id: 2, name: 'Anna Smith', email: 'anna.smith@example.com', sex: 'Female', dob: '20 March 1985', illness: 'Diabetes', dateAdded: '22 Aug 2021' },
    { id: 3, name: 'John Doe', email: 'john.doe@example.com', sex: 'Male', dob: '12 February 1979', illness: 'Asthma', dateAdded: '30 Sep 2019' },
    // Add more data as needed
  ]);

  const dataPerPage = 10;
  const totalData = data.length;
  const totalPages = Math.ceil(totalData / dataPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1); // Reset to the first page on search
  };

  const handleFilterChange = (id) => {
    const updatedFilters = new Set(selectedFilters);
    if (updatedFilters.has(id)) {
      updatedFilters.delete(id);
    } else {
      updatedFilters.add(id);
    }
    setSelectedFilters(updatedFilters);
  };

  const handleSort = () => {
    const sortedData = [...data].sort((a, b) => a.name.localeCompare(b.name));
    setData(sortedData);
  };

  const handleEdit = (id) => {
    // Navigate to edit page or handle edit action
  };

  const handleDelete = (id) => {
    // Handle delete action
  };

  const filteredData = data.filter(item =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.sex.toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.illness.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="flex h-screen">
      <SidebarAdmin className="fixed h-full" />
      <div className="flex flex-col flex-grow p-6 overflow-y-auto">
        <div className="flex justify-between items-center mb-4">
          <div className="mb-2">
            <h2 className="text-2xl font-bold leading-9 tracking-tight text-gray-800">Member Illness</h2>
            <p className="text-xs text-gray-600">This is a list of members' illnesses</p>
          </div>
          <div className="relative flex items-center">
            <FontAwesomeIcon icon={faMagnifyingGlass} className="absolute left-3 text-gray-600"/>
            <input
              type="text"
              value={searchTerm}
              onChange={handleSearch}
              className="pl-10 pr-4 py-2 border border-gray-300 rounded-lg text-sm w-80"
              placeholder="Search..."
            />
          </div>
        </div>
        <hr className="border-gray-400 mb-4"/>
        <div className="flex justify-between items-center mb-4">
          <div className="flex items-center space-x-2 border border-gray-300 rounded-lg px-3 py-2 text-sm cursor-pointer">
            <FontAwesomeIcon icon={faFilter} className="text-gray-600"/>
            <a href="#" className="text-gray-600">Filter</a>
            <div className={`flex items-center justify-center w-5 h-5 border rounded-full text-white text-xs bg-green-500 text-semibold ${selectedFilters.size > 0 ? '' : 'hidden'}`}>
              {selectedFilters.size}
            </div>
          </div>
          <div className="flex items-center space-x-2 border border-gray-300 rounded-lg px-3 py-2 ml-4 text-sm cursor-pointer" onClick={handleSort}>
            <FontAwesomeIcon icon={faArrowUpAZ} className="text-gray-600"/>
            <a href="#" className="text-gray-600">Sort</a>
          </div>
          <div className="flex-grow"></div>
          <div className="flex items-center space-x-2 border border-gray-300 rounded-lg px-3 py-2 text-sm cursor-pointer">
            <FontAwesomeIcon icon={faPlus} className="text-gray-600"/>
            <a href="#" className="text-gray-600">Add new</a>
          </div>
        </div>
        <div className="overflow-x-auto">
          <Table hoverable>
            <Table.Head>
              <Table.HeadCell className="p-4">
                <FontAwesomeIcon icon={faSquareMinus} className="text-lg" />
              </Table.HeadCell>
              <Table.HeadCell>Name</Table.HeadCell>
              <Table.HeadCell>Sex</Table.HeadCell>
              <Table.HeadCell>Date of Birth</Table.HeadCell>
              <Table.HeadCell>Illness</Table.HeadCell>
              <Table.HeadCell>Date Added</Table.HeadCell>
              <Table.HeadCell>Action</Table.HeadCell>
            </Table.Head>
            <Table.Body className="divide-y">
              {filteredData.slice((currentPage - 1) * dataPerPage, currentPage * dataPerPage).map((item) => (
                <Table.Row key={item.id} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                  <Table.Cell className="p-4">
                    <Checkbox
                      checked={selectedFilters.has(item.id)}
                      onChange={() => handleFilterChange(item.id)}
                    />
                  </Table.Cell>
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                    <div className="flex">
                      <img src="/img/eng-flag.jpeg" alt="Flag" className="h-7 w-7 inline-block rounded-full mr-3 mt-1" />
                      <div>
                        <p className="font-semibold">{item.name}</p>
                        <p className="text-xs">{item.email}</p>
                      </div>
                    </div>
                  </Table.Cell>
                  <Table.Cell>{item.sex}</Table.Cell>
                  <Table.Cell>{item.dob}</Table.Cell>
                  <Table.Cell>{item.illness}</Table.Cell>
                  <Table.Cell>{item.dateAdded}</Table.Cell>
                  <Table.Cell>
                    <a href="#" onClick={() => handleEdit(item.id)} className="font-medium text-cyan-600 hover:underline dark:text-cyan-500 mr-4">
                      <FontAwesomeIcon icon={faPen} />
                    </a>
                    <a href="#" onClick={() => handleDelete(item.id)} className="font-medium text-red-600 hover:underline dark:text-red-500">
                      <FontAwesomeIcon icon={faTrash} />
                    </a>
                  </Table.Cell>
                </Table.Row>
              ))}
              {filteredData.length === 0 && (
                <Table.Row>
                  <Table.Cell colSpan="7" className="text-center py-4">No data found</Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </div>
        <div className="flex justify-center mt-4">
          <nav className="inline-flex rounded-md shadow-sm" aria-label="Pagination">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="relative inline-flex items-center px-3 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-l-md hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <FontAwesomeIcon icon={faAnglesLeft} />
            </button>
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index + 1}
                onClick={() => handlePageChange(index + 1)}
                className={`relative inline-flex items-center px-4 py-2 text-sm font-medium ${
                  currentPage === index + 1
                    ? 'text-white bg-cyan-600 border-cyan-600'
                    : 'text-gray-500 bg-white border-gray-300'
                } hover:bg-gray-50`}
              >
                {index + 1}
              </button>
            ))}
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="relative inline-flex items-center px-3 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-r-md hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <FontAwesomeIcon icon={faAnglesRight} />
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
}

export default AdminMemberIllness;