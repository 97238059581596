"use client";

import { Button } from "flowbite-react";
import { useNavigate } from 'react-router-dom';

function Error() {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate('/home-patron');
  };

  return (
    <form>
      <div className="flex max-w-xl flex-col gap-4 mx-auto mt-20 items-center">
        <div>
          <img src="/img/warning.svg" alt="Indonesia" className="h-64 w-auto" />
          <h2 className="text-5xl text-center font-bold leading-9 tracking-tight text-gray-800 mt-8">Oops</h2>
          <p className="mt-4 mb-4 text-md text-center mt-8">
          Sorry, there was an error in your transaction
          </p>
        </div>
        <Button type="submit" onClick={handleBackClick} className="w-[30vh] mt-4 bg-green-600 text-white hover:bg-green-200">Back</Button>
      </div>
    </form>
  );
}

export default Error;