"use client";

import { Checkbox, Table } from "flowbite-react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faFilter, faArrowUp, faArrowDown, faPlus, faSquareMinus, faPen, faTrash, faAnglesRight, faAnglesLeft } from '@fortawesome/free-solid-svg-icons';
import { useState, useEffect, useMemo } from "react";
import SidebarAdmin from "../components/sidebar/sidebarAdmin";
import { Link } from "react-router-dom";

function AdminListPartner() {
	const [currentPage, setCurrentPage] = useState(1);
	const dataPerPage = 10;
	const [totalData, setTotalData] = useState(0);
	const [partners, setPartners] = useState([]);
	const [filteredCount, setFilteredCount] = useState(0);
	const [filterOpen, setFilterOpen] = useState(false);
	const [sortDirection, setSortDirection] = useState("asc");
	const [searchTerm, setSearchTerm] = useState("");

	const totalPages = Math.ceil(totalData / dataPerPage);

	const fetchData = async (page) => {
		try {
			const response = await fetch(
				`https://meals-api.103.127.137.58.nip.io/api/v1/manages/partners?page=${page}&limit=${dataPerPage}&sort=${sortDirection}`,
			);
			const result = await response.json();
			if (result.status === "success") {
				setPartners(result.data.datas);
				setTotalData(result.data.total_datas);
			} else {
				console.error("Failed to fetch data");
			}
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};

	useEffect(() => {
		fetchData(currentPage);
	}, [currentPage, sortDirection]);

	const handleSort = () => {
		setSortDirection((prevDirection) =>
			prevDirection === "asc" ? "desc" : "asc",
		);
	};

	const handleFilterToggle = () => {
		setFilterOpen(!filterOpen);
	};

	const handleSearchChange = (e) => {
		setSearchTerm(e.target.value);
	};

	// Filter data based on search term
	const filteredPartners = useMemo(() => {
		const filtered = partners.filter(
			(partner) =>
				partner.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
				partner.region.toLowerCase().includes(searchTerm.toLowerCase()) ||
				partner.company.toLowerCase().includes(searchTerm.toLowerCase()) ||
				partner.address.toLowerCase().includes(searchTerm.toLowerCase()),
		);
		setFilteredCount(filtered.length);
		return filtered;
	}, [searchTerm, partners]);

	const paginatedPartners = useMemo(() => {
		const start = (currentPage - 1) * dataPerPage;
		return filteredPartners.slice(start, start + dataPerPage);
	}, [currentPage, filteredPartners]);

	const handlePageChange = (page) => {
		setCurrentPage(page);
	};

	return (
		<div className="flex h-screen">
			<SidebarAdmin className="fixed h-full" />
			<div className="flex flex-col flex-grow p-6 overflow-y-auto">
				<div className="flex justify-between items-center mb-4">
					<div className="mb-2">
						<h2 className="text-2xl font-bold leading-9 tracking-tight text-gray-800">
							List Partner
						</h2>
						<p className="text-xs text-gray-600">This is a list of Partner</p>
					</div>
					<div className="relative flex items-center">
						<FontAwesomeIcon
							icon={faMagnifyingGlass}
							className="absolute left-3 text-gray-600"
						/>
						<input
							type="text"
							className="pl-10 pr-4 py-2 border border-gray-300 rounded-lg text-sm w-80"
							placeholder="Search..."
							value={searchTerm}
							onChange={handleSearchChange}
						/>
					</div>
				</div>
				<hr className="border-gray-400 mb-4" />
				<div className="flex justify-between items-center mb-4">
					<div
						className="flex items-center space-x-2 border border-gray-300 rounded-lg px-3 py-2 text-sm cursor-pointer"
						onClick={handleFilterToggle}
					>
						<FontAwesomeIcon icon={faFilter} className="text-gray-600" />
						<span className="text-gray-600">Filter</span>
						{filteredCount > 0 && (
							<div className="flex items-center justify-center w-5 h-5 border rounded-full text-white text-xs bg-green-500 text-semibold">
								{filteredCount}
							</div>
						)}
					</div>
					<div
						className="flex items-center space-x-2 border border-gray-300 rounded-lg px-3 py-2 ml-4 text-sm cursor-pointer"
						onClick={handleSort}
					>
						<FontAwesomeIcon
							icon={sortDirection === "asc" ? faArrowUp : faArrowDown}
							className="text-gray-600"
						/>
						<span className="text-gray-600">Sort</span>
					</div>
					<div className="flex-grow"></div>
					<Link to="admin-add-partner">
						<div className="flex items-center space-x-2 border border-gray-300 rounded-lg px-3 py-2 text-sm cursor-pointer">
							<FontAwesomeIcon icon={faPlus} className="text-gray-600" />
							<span className="text-gray-600">Add new</span>
						</div>
					</Link>
				</div>
				{filterOpen && (
					<div className="bg-gray-100 p-4 mb-4 rounded-lg border border-gray-300">
						{/* Placeholder for filter options */}
						Filter options will go here
					</div>
				)}
				<div className="overflow-x-auto">
					<Table hoverable>
						<Table.Head>
							<Table.HeadCell className="p-4">
								<FontAwesomeIcon icon={faSquareMinus} className="text-lg" />
							</Table.HeadCell>
							<Table.HeadCell>Name</Table.HeadCell>
							<Table.HeadCell>Region</Table.HeadCell>
							<Table.HeadCell>Company</Table.HeadCell>
							<Table.HeadCell>Address</Table.HeadCell>
							<Table.HeadCell>Date added</Table.HeadCell>
							<Table.HeadCell>Action</Table.HeadCell>
						</Table.Head>
						<Table.Body className="divide-y">
							{paginatedPartners.length > 0 ? (
								paginatedPartners.map((partner, index) => (
									<Table.Row
										key={index}
										className="bg-white dark:border-gray-700 dark:bg-gray-800"
									>
										<Table.Cell className="p-4">
											<Checkbox />
										</Table.Cell>
										<Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
											<div className="flex">
												<img
													src="/img/eng-flag.jpeg"
													alt="Indonesia"
													className="h-7 w-7 inline-block rounded-full mr-3 mt-1"
												/>
												<div>
													<p className="font-semibold">{partner.name}</p>
													<p className="text-xs">{partner.user.email}</p>
												</div>
											</div>
										</Table.Cell>
										<Table.Cell>{partner.region}</Table.Cell>
										<Table.Cell>{partner.company}</Table.Cell>
										<Table.Cell>{partner.address}</Table.Cell>
										<Table.Cell>
											{new Date(partner.created_at).toLocaleDateString()}
										</Table.Cell>
										<Table.Cell>
											<a
												href="#"
												className="font-medium text-cyan-600 hover:underline dark:text-cyan-500 mr-4"
											>
												<FontAwesomeIcon icon={faPen} />
											</a>
											<a
												href="#"
												className="font-medium text-red-600 hover:underline dark:text-cyan-500"
											>
												<FontAwesomeIcon icon={faTrash} />
											</a>
										</Table.Cell>
									</Table.Row>
								))
							) : (
								<Table.Row>
									<Table.Cell
										colSpan="7"
										className="text-center py-4 text-gray-500"
									>
										No data available
									</Table.Cell>
								</Table.Row>
							)}
						</Table.Body>
					</Table>
				</div>
				<div className="flex justify-center mt-4">
					<nav
						className="inline-flex rounded-md shadow-sm"
						aria-label="Pagination"
					>
						<button
							onClick={() => handlePageChange(currentPage - 1)}
							disabled={currentPage === 1}
							className="relative inline-flex items-center px-3 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-l-md hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
						>
							<FontAwesomeIcon icon={faAnglesLeft} />
						</button>
						{Array.from({ length: totalPages }, (_, index) => (
							<button
								key={index + 1}
								onClick={() => handlePageChange(index + 1)}
								className={`relative inline-flex items-center px-4 py-2 text-sm font-medium ${
									currentPage === index + 1
										? "text-white bg-cyan-600 border-cyan-600"
										: "text-gray-500 bg-white border-gray-300"
								} hover:bg-gray-50`}
							>
								{index + 1}
							</button>
						))}
						<button
							onClick={() => handlePageChange(currentPage + 1)}
							disabled={currentPage === totalPages}
							className="relative inline-flex items-center px-3 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-r-md hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
						>
							<FontAwesomeIcon icon={faAnglesRight} />
						</button>
					</nav>
				</div>
			</div>
		</div>
	);
}

export default AdminListPartner;
