"use client";

import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse, faBurger, faCartShopping, faMoneyBill, faAddressBook, faUser } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

export function SidebarCaregiver() {
  const [activeMenu, setActiveMenu] = useState('Home');

  const menuItems = [
    { name: 'Home', icon: faHouse, link: '/home-meal' },
    { name: 'Meal', icon: faBurger, link: '/menu-meal' },
    { name: 'Member', icon: faUser, link: '/detail-member-caregiver' },
    { name: 'Order', icon: faMoneyBill, link: '/delivery' },
    { name: 'Cart', icon: faCartShopping, link: '/cart' },
    { name: 'Contact', icon: faAddressBook, link: '/contact-us' },
  ];

  return (
    <div className="w-64 h-screen bg-gray-200 text-gray flex flex-col justify-between">
      <div>
        <div className="flex justify-center items-center py-6">
          <img src="/img/logo.png" alt="Meals to Heals" className="h-auto w-56" />
        </div>
        <div className="space-y-4">
          {menuItems.map((item) => (
            <div
              key={item.name}
              className={`flex items-center px-6 py-2 rounded-sm cursor-pointer ${
                activeMenu === item.name ? 'bg-green-500 text-white' : 'hover:bg-green-500 hover:text-white'
              }`}
              onClick={() => setActiveMenu(item.name)}
            >
              <FontAwesomeIcon icon={item.icon} className="mr-3" />
              <Link to={item.link} className="text-lg">{item.name}</Link>
            </div>
          ))}
        </div>
      </div>
      <div className="mb-6 flex items-center px-6">
        <Link to="/user-profile">
          <img
            className="h-10 w-10 rounded-full"
            src="https://flowbite.com/docs/images/people/profile-picture-1.jpg"
            alt="Jese Leos"
          />
        </Link>
        <div className="ml-3">
          <p className="text-base font-semibold leading-none">
            <Link to="/user-profile">Jese Leos</Link>
          </p>
        </div>
      </div>
    </div>
  );
}

export default SidebarCaregiver;