// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*************** Home Code ***************/
input[type=radio]{
    display: none;
}

.star {
    cursor: pointer;
    font-size: 45px;
    margin-top: 15px;
    padding-right: 40px;
}
/*************** Home Code ***************/

/*************** Comment Code ***************/
.star-comment {
    font-size: 40px;
    margin-top: 5px;
    padding-right: 35px;
    color: #ffc107; /* Yellow color */
    pointer-events: none; /* Disable pointer events to prevent changing the rating */
}

.comment {
    width: 100%;
}

.com-bar {
    border: none;
    border-bottom: 2px solid #999; /* You can customize the color */
    outline: none;
    width: 100%;
    padding: 10px;
    margin-top: 20px;
}

/*************** Comment Code ***************/`, "",{"version":3,"sources":["webpack://./src/rating/rating.css"],"names":[],"mappings":"AAAA,0CAA0C;AAC1C;IACI,aAAa;AACjB;;AAEA;IACI,eAAe;IACf,eAAe;IACf,gBAAgB;IAChB,mBAAmB;AACvB;AACA,0CAA0C;;AAE1C,6CAA6C;AAC7C;IACI,eAAe;IACf,eAAe;IACf,mBAAmB;IACnB,cAAc,EAAE,iBAAiB;IACjC,oBAAoB,EAAE,0DAA0D;AACpF;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,YAAY;IACZ,6BAA6B,EAAE,gCAAgC;IAC/D,aAAa;IACb,WAAW;IACX,aAAa;IACb,gBAAgB;AACpB;;AAEA,6CAA6C","sourcesContent":["/*************** Home Code ***************/\ninput[type=radio]{\n    display: none;\n}\n\n.star {\n    cursor: pointer;\n    font-size: 45px;\n    margin-top: 15px;\n    padding-right: 40px;\n}\n/*************** Home Code ***************/\n\n/*************** Comment Code ***************/\n.star-comment {\n    font-size: 40px;\n    margin-top: 5px;\n    padding-right: 35px;\n    color: #ffc107; /* Yellow color */\n    pointer-events: none; /* Disable pointer events to prevent changing the rating */\n}\n\n.comment {\n    width: 100%;\n}\n\n.com-bar {\n    border: none;\n    border-bottom: 2px solid #999; /* You can customize the color */\n    outline: none;\n    width: 100%;\n    padding: 10px;\n    margin-top: 20px;\n}\n\n/*************** Comment Code ***************/"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
