"use client";

import { useState } from "react";
import { Label, Button, TextInput, FileInput, Textarea } from "flowbite-react";
import SidebarPartner from "../components/sidebar/sidebarPartner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

function AdminAddPartner() {
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [region, setRegion] = useState("");
	const [company, setCompany] = useState("");
	const [address, setAddress] = useState("");
	const [dateAdded, setDateAdded] = useState("");
	const [file, setFile] = useState(null);
	const [message, setMessage] = useState("");
	const [error, setError] = useState("");

	const handleFileChange = (e) => {
		setFile(e.target.files[0]);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		const formData = new FormData();
		formData.append("name", name);
		formData.append("email", email);
		formData.append("region", region);
		formData.append("company", company);
		formData.append("address", address);
		formData.append("date_added", dateAdded);
		if (file) {
			formData.append("file", file);
		}

		try {
			const response = await fetch(
				"https://meals-api.103.127.137.58.nip.io/api/v1/manages/partners",
				{
					method: "POST",
					body: formData,
				},
			);

			const data = await response.json();

			if (data.status === "success") {
				setMessage(data.message);
				setError("");
			} else {
				setError(data.message);
				setMessage("");
			}
		} catch (error) {
			setError("Something went wrong!");
			setMessage("");
		}
	};

	return (
		<div className="flex h-screen">
			<SidebarPartner className="fixed h-full" />
			<div className="flex flex-col flex-grow p-6 overflow-y-auto">
				<div className="flex items-center justify-between mb-4">
					<div className="flex items-center">
						<a href="/admin-list-partner">
							<FontAwesomeIcon icon={faChevronLeft} className="text-lg" />
						</a>
						<div className="mb-2 ml-5">
							<h2 className="text-2xl font-bold leading-9 tracking-tight text-gray-800">
								Add Partner
							</h2>
							<p className="text-xs text-gray-600">
								This is a form for adding menu
							</p>
						</div>
					</div>
				</div>
				<hr className="border-gray-400 mb-4" />
				{message && <p className="text-green-600">{message}</p>}
				{error && <p className="text-red-600">{error}</p>}
				<form onSubmit={handleSubmit} encType="multipart/form-data">
					<div className="flex flex-col mt-4 lg:flex-row lg:gap-6">
						<div className="flex flex-col gap-4 w-full lg:w-1/2">
							<div>
								<Label htmlFor="name" value="Name" />
								<TextInput
									id="name"
									type="text"
									className="mt-2"
									required
									value={name}
									onChange={(e) => setName(e.target.value)}
								/>
							</div>
							<div>
								<Label htmlFor="email" value="Email" />
								<TextInput
									id="email"
									type="email"
									className="mt-2"
									required
									value={email}
									onChange={(e) => setEmail(e.target.value)}
								/>
							</div>
							<div>
								<Label htmlFor="region" value="Region" />
								<TextInput
									id="region"
									type="text"
									className="mt-2"
									required
									value={region}
									onChange={(e) => setRegion(e.target.value)}
								/>
							</div>
							<div>
								<Label htmlFor="company" value="Company" />
								<TextInput
									id="company"
									type="text"
									className="mt-2"
									required
									value={company}
									onChange={(e) => setCompany(e.target.value)}
								/>
							</div>
							<div>
								<Label htmlFor="address" value="Address" />
								<Textarea
									id="address"
									className="mt-2"
									required
									value={address}
									onChange={(e) => setAddress(e.target.value)}
								/>
							</div>
							<div>
								<Label htmlFor="file" value="Member Picture" />
								<Label
									htmlFor="dropzone-file"
									className="flex h-54 w-full mt-2 cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:border-gray-500 dark:hover:bg-gray-600"
								>
									<div className="flex flex-col items-center justify-center pb-6 pt-5">
										<svg
											className="mb-4 h-8 w-8 text-gray-500 dark:text-gray-400"
											aria-hidden="true"
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 20 16"
										>
											<path
												stroke="currentColor"
												strokeLinecap="round"
												strokeLinejoin="round"
												strokeWidth="2"
												d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
											/>
										</svg>
										<p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
											<span className="font-semibold">Click to upload</span> or
											drag and drop
										</p>
										<p className="text-xs text-gray-500 dark:text-gray-400">
											SVG, PNG, JPG or GIF (MAX. 800x400px)
										</p>
									</div>
									<FileInput
										id="dropzone-file"
										className="hidden"
										onChange={handleFileChange}
									/>
								</Label>
							</div>
							<div>
								<Label htmlFor="date_added" value="Date added" />
								<TextInput
									id="date_added"
									type="text"
									className="mt-2"
									required
									value={dateAdded}
									onChange={(e) => setDateAdded(e.target.value)}
								/>
							</div>
						</div>
					</div>
					<Button type="submit" className="mt-20 w-[20vw] bg-green-600">
						Save
					</Button>
				</form>
			</div>
		</div>
	);
}

export default AdminAddPartner;
