"use client";

import { useEffect, useState } from "react";
import { Carousel } from "flowbite-react";
import SidebarMember from "../components/sidebar/sidebarMember";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlass,
  faCartShopping,
  faXmark,
  faMinus,
  faPlus,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import { faHeart, faSquarePlus } from "@fortawesome/free-regular-svg-icons";
import axios from "axios";
import { getAuthAndProfileResponses } from "../utils/localstorage";
import { Tooltip } from "react-tooltip";
import { useNavigate } from "react-router-dom";

function HomeMeal() {
  const navigate = useNavigate();

  const [isOrderOpen, setIsOrderOpen] = useState(false);

  const { authResponse, profileResponse } = getAuthAndProfileResponses();

  const accessToken = authResponse["access-token"];
  const refreshToken = authResponse["refresh-token"];

  const [menuData, setMenuData] = useState([]);
  const [menuCategoryData, setMenuCategoryData] = useState([]);
  const [cartData, setCartData] = useState([]);
  const [remainingOrder, setRemainingOrder] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  function openModal(item) {
    setSelectedItem(item);
    setIsModalOpen(true);
  }

  function closeModal() {
    setIsModalOpen(false);
    setSelectedItem(null);
  }

  const orderMeal = async () => {
    const checkboxes = document.querySelectorAll(
      'input[id="cart-checkbox"]:checked'
    );
    const values = Array.from(checkboxes).map((checkbox) => checkbox.value);

    if (values.length > 0) {
      try {
        setIsLoading(true);
        await axios
          .post(
            process.env.REACT_APP_API_BASE_URL + "members/orders",
            JSON.stringify({
              cart_ids: values,
            }),
            {
              headers: {
                Authorization: `Bearer ${authResponse.token}`,
                "Access-Token": `${accessToken}`,
                "Refresh-Token": `${refreshToken}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then(() => {
            fetchRemainingOrder();
            fetchCartData();
            navigate("/thanks-meal");
          });
      } catch (error) {
        alert(error?.response?.data?.data?.errors);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const fetchMealData = async (searchQuery) => {
    try {
      setIsLoading(true);
      await axios
        .get(process.env.REACT_APP_API_BASE_URL + "meals", {
          headers: {
            Authorization: `Bearer ${authResponse.token}`,
            "Content-Type": "application/json",
          },
          params: {
            search: searchQuery,
          },
        })
        .then((response) => {
          setMenuData(response?.data?.data?.datas);
        });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchMealCategoryData = async () => {
    try {
      setIsLoading(true);
      await axios
        .get(process.env.REACT_APP_API_BASE_URL + "meals/categories?limit=8", {
          headers: {
            Authorization: `Bearer ${authResponse.token}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setMenuCategoryData(response?.data?.data?.datas);
        });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchCartData = async () => {
    try {
      setIsLoading(true);
      await axios
        .get(process.env.REACT_APP_API_BASE_URL + "carts/own", {
          headers: {
            Authorization: `Bearer ${authResponse.token}`,
            "Access-Token": `${accessToken}`,
            "Refresh-Token": `${refreshToken}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setCartData(response?.data?.data);
        });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchRemainingOrder = async () => {
    try {
      setIsLoading(true);
      await axios
        .get(process.env.REACT_APP_API_BASE_URL + "members/orders/remaining", {
          headers: {
            Authorization: `Bearer ${authResponse.token}`,
            "Access-Token": `${accessToken}`,
            "Refresh-Token": `${refreshToken}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setRemainingOrder(response?.data?.data?.quantity);
        });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchChange = (e) => {
    fetchMealData(e.target.value);
  };

  const addToCart = async (itemid) => {
    try {
      setIsLoading(true);
      await axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "members/carts",
          JSON.stringify({
            meal_id: itemid,
            quantity: 1,
          }),
          {
            headers: {
              Authorization: `Bearer ${authResponse.token}`,
              "Access-Token": `${accessToken}`,
              "Refresh-Token": `${refreshToken}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then(() => {
          fetchCartData();
        });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const addCartQuantity = async (cartid) => {
    try {
      setIsLoading(true);
      await axios
        .patch(
          process.env.REACT_APP_API_BASE_URL + "members/carts/" + cartid,
          JSON.stringify({
            quantity: 1,
          }),
          {
            headers: {
              Authorization: `Bearer ${authResponse.token}`,
              "Access-Token": `${accessToken}`,
              "Refresh-Token": `${refreshToken}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then(() => {
          fetchCartData();
        });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const substractCartQuantity = async (cartid) => {
    try {
      setIsLoading(true);
      await axios
        .patch(
          process.env.REACT_APP_API_BASE_URL + "members/carts/" + cartid,
          JSON.stringify({
            quantity: -1,
          }),
          {
            headers: {
              Authorization: `Bearer ${authResponse.token}`,
              "Access-Token": `${accessToken}`,
              "Refresh-Token": `${refreshToken}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then(() => {
          fetchCartData();
        });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchRemainingOrder();
    fetchCartData();
    fetchMealCategoryData();
    fetchMealData();
  }, []);

  return (
    <div className="flex h-screen ">
      <SidebarMember className="fixed h-full" />
      <div className="flex flex-col flex-grow p-6 overflow-y-auto">
        <div className="flex items-center justify-between mb-4">
          <div className="mb-2 ml-4">
            <h2 className="text-2xl font-bold leading-9 tracking-tight text-gray-800">
              Halo,{" "}
              {profileResponse?.first_name
                ? profileResponse?.first_name + " " + profileResponse?.last_name
                : authResponse?.email}
            </h2>
          </div>
          <div
            className={`relative flex items-center ${
              isOrderOpen ? "" : "mx-auto"
            }`}
          >
            <FontAwesomeIcon
              icon={faMagnifyingGlass}
              className="absolute left-3 text-gray-600"
            />
            <input
              type="text"
              className="pl-10 pr-4 py-2 border border-gray-300 rounded-lg text-sm w-80"
              placeholder="Search Meals..."
              onChange={handleSearchChange}
            />
          </div>
          {!isOrderOpen && (
            <div className="iconRight flex space-x-4 mr-5">
              <FontAwesomeIcon
                icon={faStar}
                className="w-6 h-6 mr-6 cursor-pointer"
              />
              <FontAwesomeIcon
                icon={faCartShopping}
                className="w-6 h-6 mr-6 cursor-pointer"
                onClick={() => setIsOrderOpen(true)}
              />
            </div>
          )}
        </div>
        <div>
          <div className="h-56 sm:h-64 xl:h-80 2xl:h-96">
            <Carousel>
              <div className="flex h-full items-center justify-center bg-gray-100">
                <img src="/img/banner-1.png" alt="Banner1" />
              </div>
              <div className="flex h-full items-center justify-center bg-gray-100">
                <img src="/img/banner-2.png" alt="Banner2" />
              </div>
              <div className="flex h-full items-center justify-center bg-gray-100">
                <img src="/img/banner-3.png" alt="Banner3" />
              </div>
              <div className="flex h-full items-center justify-center bg-gray-100">
                <img src="/img/banner-4.png" alt="Banner4" />
              </div>
            </Carousel>
          </div>
        </div>
        <div className="mt-12">
          <h2 className="font-bold text-xl mb-4">Kategori</h2>
          <div className="grid grid-cols-8 gap-4">
            {menuCategoryData?.map((item, index) => (
              <div key={index} className="text-center">
                <div className="bg-gray-100 rounded-xl flex items-center justify-center p-4">
                  <img
                    className="w-full rounded-md h-32 object-cover"
                    src={item?.image?.path}
                    alt={item?.image?.name}
                  />
                </div>
                <p className="jenis mt-4 text-[13px] font-semibold">
                  {item?.name}
                </p>
              </div>
            ))}
          </div>
        </div>
        <div className="popular mt-12 mb-32">
          <h2 className="font-bold text-xl mb-4">Paling Populer</h2>
          <div className="grid grid-cols-4 gap-6">
            {menuData?.map((item, index) => (
              <div
                key={index}
                className="bg-gray-100 p-4 rounded-xl flex flex-col items-center relative cursor-pointer"
                onClick={() => openModal(item)} // Open modal on click
              >
                <div className="absolute top-6 right-6 bg-white p-3 rounded-full flex justify-center items-center">
                  <FontAwesomeIcon icon={faHeart} />
                </div>

                <img
                  src={item?.images[0]?.image?.path}
                  alt={item?.name}
                  className="w-[100%] h-[200px] object-cover rounded-md "
                />

                <div className="menu flex justify-between w-full mt-4">
                  <div>
                    <h6 className="font-semibold text-[16px]">{item?.name}</h6>
                    <p className="text-[12px] line-clamp-2">
                      {item?.description}
                    </p>
                  </div>
                  <div>
                    <FontAwesomeIcon
                      className="cursor-pointer hover:bg-gray-200 p-1 rounded-md transition-all duration-300 w-5 h-5 transform active:scale-75"
                      icon={faSquarePlus}
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent modal opening on add to cart
                        addToCart(item.id);
                      }}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {isModalOpen && selectedItem && (
        <div className="fixed inset-0 bg-gray-900 bg-opacity-75 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg max-w-[50vw] w-full max-h-[80vh] overflow-auto">
            <h3 className="text-xl font-semibold mb-4">{selectedItem.name}</h3>
            <img
              src={selectedItem?.images[0]?.image?.path}
              alt={selectedItem?.name}
              className="w-full h-96 object-cover rounded-md mb-4"
            />
            <div>
              <h4 className="text-lg font-medium">Deskripsi</h4>
              <p className="text-gray-700 mb-4">{selectedItem?.description}</p>
            </div>
            <div>
              <h4 className="text-lg font-medium mb-2">
                Penyakit yang bisa terpicu
              </h4>
              <div className="flex flex-row overflow-auto gap-2">
                {selectedItem?.illnesses.map((illness, index) => (
                  <div>
                    <p
                      className="py-1 px-4 text-xs rounded-full font-semibold mb-4 bg-red-100 text-red-800"
                      data-tooltip-id="my-tooltip"
                      data-tooltip-content={illness?.illness?.description}
                    >
                      {illness?.illness?.name}
                    </p>
                    <Tooltip id="my-tooltip" className="max-w-72 !rounded-md" />
                  </div>
                ))}
              </div>
            </div>
            <div>
              <h4 className="text-lg font-medium mb-2">
                Alergi yang bisa terpicu
              </h4>
              <div className="flex flex-row overflow-auto gap-2">
                {selectedItem?.allergies.map((allergy, index) => (
                  <div>
                    <p
                      className="py-1 px-4 text-xs rounded-full font-semibold mb-4 bg-red-100 text-red-800"
                      data-tooltip-id="my-tooltip"
                      data-tooltip-content={allergy?.allergy?.description}
                    >
                      {allergy?.allergy?.name}
                    </p>
                    <Tooltip id="my-tooltip" className="max-w-72 !rounded-md" />
                  </div>
                ))}
              </div>
            </div>
            <div className="mb-4">
              <h4 className="text-lg font-medium">Mitra</h4>
              <div className="flex flex-row items-center gap-2">
                <img
                  className="h-10 w-10 rounded-full"
                  src={selectedItem.partner.user.image.image.path}
                />
                <p className="text-gray-700 h-fit">
                  {selectedItem.partner.name}
                </p>
              </div>
            </div>
            <button
              className="bg-green-500 text-white px-4 py-2 rounded-md"
              onClick={closeModal}
            >
              Close
            </button>
          </div>
        </div>
      )}
      {isOrderOpen && (
        <div className="w-1/5 bg-white p-6">
          <div className="flex justify-between items-center mb-4">
            <h2 className="font-bold text-xl">Pesanan Saya</h2>
            <FontAwesomeIcon
              icon={faXmark}
              className="w-6 h-6 cursor-pointer"
              onClick={() => setIsOrderOpen(false)}
            />
          </div>
          <div className="flex items-center mb-4">
            <p className="mr-2 text-[11px] mt-2 font-semibold text-green-500">
              Pesanan tersisa hari ini
            </p>
            <div className="bg-red-600 rounded-full text-white w-10 h-10 flex items-center justify-center ml-2">
              <h4 className="p-2">{remainingOrder}</h4>
            </div>
          </div>
          <hr className="border-gray-400 mb-4" />
          {cartData?.map((item, index) => (
            <div key={index} className="flex items-center mb-8">
              <div className="flex items-center mr-4">
                <input
                  id="cart-checkbox"
                  value={item?.id}
                  type="checkbox"
                  className="w-4 h-4 text-green-600 bg-gray-100 border-gray-300 rounded focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
              </div>
              <div className="bg-gray-100 rounded-lg p-2 mr-4">
                <img
                  src={item?.meal?.images[0]?.image?.path}
                  alt={item?.name}
                  className="h-14 w-14 object-cover"
                />
              </div>
              <div>
                <p className="font-semibold text-[14px]">{item?.meal?.name}</p>
                <div className="flex items-center mt-2 text-sm">
                  <FontAwesomeIcon
                    onClick={() => substractCartQuantity(item.id)}
                    icon={faMinus}
                    className="cursor-pointer hover:bg-gray-300 p-2 rounded-full"
                  />
                  <p className="mx-4">{item?.quantity}</p>
                  <FontAwesomeIcon
                    onClick={() => addCartQuantity(item.id)}
                    icon={faPlus}
                    className="cursor-pointer hover:bg-gray-300 p-2 rounded-full"
                  />
                </div>
              </div>
            </div>
          ))}
          <button
            className="bg-green-500 text-white py-2 px-4 rounded-full w-[14vw] mt-4"
            onClick={() => orderMeal()}
          >
            Checkout
          </button>
        </div>
      )}
    </div>
  );
}

export default HomeMeal;
