export function formatDate(isoString) {
  const date = new Date(isoString);
  return new Intl.DateTimeFormat("en-US", {
    weekday: "long", // Full day name (e.g., Saturday)
    year: "numeric",
    month: "long", // Full month name (e.g., August)
    day: "numeric", // Day of the month
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true, // 12-hour format
    timeZone: "Asia/Singapore", // GMT+08:00 time zone
  }).format(date);
}
