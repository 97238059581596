"use client";

import { Checkbox, Table } from "flowbite-react";
import SidebarPartner from "../components/sidebar/sidebarPartner";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faFilter, faArrowUpAZ, faPlus, faSquareMinus, faPen, faTrash, faAnglesRight, faAnglesLeft } from '@fortawesome/free-solid-svg-icons';
import { useState } from "react";

function ListDonation() {
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedCount, setSelectedCount] = useState(0); // For tracking selected checkboxes
  const [searchTerm, setSearchTerm] = useState(""); // For search feature
  const [isSorted, setIsSorted] = useState(false); // For sorting
  const dataPerPage = 10;
  const data = [
    {
      name: "Bambang Nurwanto",
      email: "bambang@gmail.com",
      address: "Jln. Pulau Barak No.8 lorem ipsum",
      sex: "Male",
      donation: "Rp. 20.000.000",
      dateAdded: "14 Jul 2020",
    },
    // Tambahkan data lainnya sesuai kebutuhan
  ];

  const totalData = data.length;
  const totalPages = Math.ceil(totalData / dataPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleCheckboxChange = (isChecked) => {
    setSelectedCount(isChecked ? selectedCount + 1 : selectedCount - 1);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSort = () => {
    setIsSorted(!isSorted);
  };

  const filteredData = data
    .filter((item) => 
      item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.address.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => {
      if (!isSorted) return 0;
      return a.name.localeCompare(b.name);
    });

  const paginatedData = filteredData.slice(
    (currentPage - 1) * dataPerPage,
    currentPage * dataPerPage
  );

  return (
    <div className="flex h-screen">
      <SidebarPartner className="fixed h-full" />
      <div className="flex flex-col flex-grow p-6 overflow-y-auto">
        <div className="flex justify-between items-center mb-4">
          <div className="mb-2">
            <h2 className="text-2xl font-bold leading-9 tracking-tight text-gray-800">List Donation</h2>
            <p className="text-xs text-gray-600">This is a list of donation</p>
          </div>
          <div className="relative flex items-center">
            <FontAwesomeIcon icon={faMagnifyingGlass} className="absolute left-3 text-gray-600"/>
            <input
              type="text"
              className="pl-10 pr-4 py-2 border border-gray-300 rounded-lg text-sm w-80"
              placeholder="Search..."
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
        </div>
        <hr className="border-gray-400 mb-4"/>
        <div className="flex justify-between items-center mb-4">
          <div className="flex items-center space-x-2 border border-gray-300 rounded-lg px-3 py-2 text-sm cursor-pointer">
            <FontAwesomeIcon icon={faFilter} className="text-gray-600"/>
            <span className="text-gray-600">Filter</span>
            {selectedCount > 0 && (
              <div className="flex items-center justify-center w-5 h-5 border rounded-full text-white text-xs bg-green-500 text-semibold">
                {selectedCount}
              </div>
            )}
          </div>
          <div
            onClick={handleSort}
            className="flex items-center space-x-2 border border-gray-300 rounded-lg px-3 py-2 ml-4 text-sm cursor-pointer"
          >
            <FontAwesomeIcon icon={faArrowUpAZ} className="text-gray-600"/>
            <span className="text-gray-600">Sort</span>
          </div>
          <div className="flex-grow"></div>
        </div>
        <div className="overflow-x-auto">
          <Table hoverable>
            <Table.Head>
              <Table.HeadCell className="p-4">
                <FontAwesomeIcon icon={faSquareMinus} className="text-lg" />
              </Table.HeadCell>
              <Table.HeadCell>Name</Table.HeadCell>
              <Table.HeadCell>Address</Table.HeadCell>
              <Table.HeadCell>Sex</Table.HeadCell>
              <Table.HeadCell>Donation</Table.HeadCell>
              <Table.HeadCell>Date added</Table.HeadCell>
              <Table.HeadCell>Action</Table.HeadCell>
            </Table.Head>
            <Table.Body className="divide-y">
              {paginatedData.map((item, index) => (
                <Table.Row key={index} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                  <Table.Cell className="p-4">
                    <Checkbox
                      onChange={(e) => handleCheckboxChange(e.target.checked)}
                    />
                  </Table.Cell>
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                    <div className="flex">
                      <img src="/img/eng-flag.jpeg" alt="Indonesia" className="h-7 w-7 inline-block rounded-full mr-3 mt-1" />
                      <div>
                        <p className="font-semibold">{item.name}</p>
                        <p className="text-xs">{item.email}</p>
                      </div>
                    </div>
                  </Table.Cell>
                  <Table.Cell>{item.address}</Table.Cell>
                  <Table.Cell>{item.sex}</Table.Cell>
                  <Table.Cell>{item.donation}</Table.Cell>
                  <Table.Cell>{item.dateAdded}</Table.Cell>
                  <Table.Cell>
                    <a href="#" className="font-medium text-cyan-600 hover:underline dark:text-cyan-500 mr-4">
                      <FontAwesomeIcon icon={faPen} />
                    </a>
                    <a href="#" className="font-medium text-red-600 hover:underline dark:text-cyan-500">
                      <FontAwesomeIcon icon={faTrash} />
                    </a>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
        <div className="flex justify-center mt-4">
          <nav className="inline-flex rounded-md shadow-sm" aria-label="Pagination">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="relative inline-flex items-center px-3 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-l-md hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <FontAwesomeIcon icon={faAnglesLeft} />
            </button>
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index + 1}
                onClick={() => handlePageChange(index + 1)}
                className={`relative inline-flex items-center px-4 py-2 text-sm font-medium ${
                  currentPage === index + 1
                    ? 'text-white bg-cyan-600 border-cyan-600'
                    : 'text-gray-500 bg-white border-gray-300'
                } hover:bg-gray-50`}
              >
                {index + 1}
              </button>
            ))}
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="relative inline-flex items-center px-3 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-r-md hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <FontAwesomeIcon icon={faAnglesRight} />
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
}

export default ListDonation;