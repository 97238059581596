"use client";

import { useState } from "react";
import { Label, Button, TextInput, Modal } from "flowbite-react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import SidebarAdmin from "../components/sidebar/sidebarAdmin";

function AdminBuktiTransfer() {
  const [imageUrl, setImageUrl] = useState("https://via.placeholder.com/150");
  const [showModal, setShowModal] = useState(false);

  const handleImageClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div className="flex h-screen">
      <SidebarAdmin className="fixed h-full" />
      <div className="flex flex-col flex-grow p-6 overflow-y-auto">
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center">
            <FontAwesomeIcon icon={faChevronLeft} className="text-lg" />
            <div className="mb-2 ml-5">
              <h2 className="text-2xl font-bold leading-9 tracking-tight text-gray-800">Evidence of Transfer</h2>
              <p className="text-xs text-gray-600">This is a form for evidence of transfer</p>
            </div>
          </div>
        </div>
        <hr className="border-gray-400 mb-4" />
        <div className="flex flex-col mt-4 lg:flex-row lg:gap-6">
          <div className="flex flex-col gap-4 w-full lg:w-1/2">
            <div>
              <Label htmlFor="decrepitName1" value="Name" />
              <TextInput type="text" id="disabledInput2" placeholder="Lalisa Manoban" disabled readOnly />
            </div>
            <div>
              <Label htmlFor="nameChild1" value="Email" />
              <TextInput type="text" id="disabledInput2" placeholder="Lalisa@gmail.com" disabled readOnly />
            </div>
            <div>
              <Label htmlFor="age1" value="Sex" />
              <TextInput type="text" id="disabledInput2" placeholder="Female" disabled readOnly />
            </div>
            <div>
              <Label htmlFor="address1" value="Address"/>
              <TextInput type="text" id="disabledInput2" placeholder="Jln. Gunung Merbabu No.8 X" disabled readOnly />
            </div>
          </div>
          <div className="flex flex-col gap-4 w-full lg:w-1/2">
            <div>
              <Label htmlFor="age1" value="Donation" />
              <TextInput type="text" id="disabledInput2" placeholder="Rp. 10.000.000" disabled readOnly />
            </div>
            <div>
              <Label htmlFor="age1" value="Proof of Transfer" />
              <div className="flex flex-col items-center mt-2">
                <img
                  src={imageUrl}
                  alt="Uploaded"
                  className="h-[34vh] w-full cursor-pointer rounded-lg border-2 border-gray-300"
                  onClick={handleImageClick}
                />
              </div>
            </div>
            <div>
              <Label htmlFor="dateAdded1" value="Date Added" />
              <TextInput type="text" id="disabledInput2" placeholder="24 July 2024" disabled readOnly />
            </div>
          </div>
        </div>
        <Button type="submit" className="mt-20 w-[20vw] bg-green-600">Save</Button>

        <Modal show={showModal} size="lg" onClose={handleCloseModal}>
          <Modal.Header>Uploaded Image</Modal.Header>
          <Modal.Body>
            <img src={imageUrl} alt="Uploaded" className="w-full" />
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleCloseModal} className="bg-green-600">Close</Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

export default AdminBuktiTransfer;