"use client";

import { useState } from "react";
import { Button, Label, TextInput } from "flowbite-react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import "./forgotPass.css";

function InputEmail() {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate(); // Initialize useNavigate for navigation

  const handleSubmit = async (event) => {
    event.preventDefault();

    const response = await fetch("https://meals-go.meals-backend.3.1.70.57.nip.io/api/v1/auth/forgot-password", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    });

    const data = await response.json();

    if (response.ok) {
      setMessage("Success forgot password");
      navigate('/reset-pass'); // Redirect to reset password page
    } else {
      setMessage(data.message || "Something went wrong");
    }
  };

  return (
    <form className="flex max-w-xl flex-col gap-4 mx-auto mt-28" onSubmit={handleSubmit}>
      <div>
        <div>
          <a href="/signin" className="flex mb-3 cursor-pointer">
            <FontAwesomeIcon icon={faArrowLeft} />
            <p className="ml-2 text-sm">Back</p>
          </a>
        </div>
        <h2 className="text-2xl font-bold leading-9 tracking-tight text-gray-800">Forgot your password?</h2>
        <p className="mt-4 mb-4 text-sm">Enter the email address associated with your account and we'll send you a link to reset your password.</p>
      </div>
      <div>
        <div className="mb-2 block">
          <Label htmlFor="email1" value="Email" />
        </div>
        <TextInput
          id="email1"
          type="email"
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div>
        <p className="mt-4 text-sm mb-4">
          If your email address exists in our database, and you haven't
          requested a password reset in the last 30 minutes, you will receive a
          password recovery link at your email address in a few minutes.
        </p>
      </div>
      <Button
        type="submit"
        className="w-[30vh] bg-green-600 text-white hover:bg-green-200"
      >
        Submit
      </Button>
      {message && <p className="mt-4 text-sm">{message}</p>}
    </form>
  );
}

export default InputEmail;
