import { faAnglesLeft, faAnglesRight, faArrowUpAZ, faFilter, faMagnifyingGlass, faPen, faPlus, faSquareMinus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Checkbox, Table } from "flowbite-react";
import { useEffect, useState } from "react";
import SidebarAdmin from "../components/sidebar/sidebarAdmin";

function AdminFoodCategory() {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCount, setSelectedCount] = useState(0);
  const [sortAscending, setSortAscending] = useState(true);
  const dataPerPage = 10;

  const initialData = [
    { id: 1, mealName: "Nasi Goreng", category: "Karbohidrat", mainIngredient: "Nasi", dateAdded: "14 Jul 2024" },
    { id: 2, mealName: "Sate Ayam", category: "Protein", mainIngredient: "Ayam", dateAdded: "12 Jul 2024" },
    { id: 3, mealName: "Rendang", category: "Protein", mainIngredient: "Daging", dateAdded: "10 Jul 2024" },
    // Tambahkan data sesuai kebutuhan
  ];

  const [filteredData, setFilteredData] = useState(initialData);

  const totalData = filteredData.length;
  const totalPages = Math.ceil(totalData / dataPerPage);

  useEffect(() => {
    // Handle filtering and searching
    const filtered = initialData
      .filter(item => item.mealName.toLowerCase().includes(searchTerm.toLowerCase()));
    setFilteredData(filtered);
  }, [searchTerm]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleCheckboxChange = (event) => {
    setSelectedCount(event.target.checked ? selectedCount + 1 : selectedCount - 1);
  };

  const handleSort = () => {
    const sortedData = [...filteredData].sort((a, b) => {
      if (a.mealName < b.mealName) return sortAscending ? -1 : 1;
      if (a.mealName > b.mealName) return sortAscending ? 1 : -1;
      return 0;
    });
    setFilteredData(sortedData);
    setSortAscending(!sortAscending);
  };

  return (
    <div className="flex h-screen">
      <SidebarAdmin className="fixed h-full" />
      <div className="flex flex-col flex-grow p-6 overflow-y-auto">
        <div className="flex justify-between items-center mb-4">
          <div className="mb-2">
            <h2 className="text-2xl font-bold leading-9 tracking-tight text-gray-800">Food Category</h2>
            <p className="text-xs text-gray-600">This is a list of food in your restaurant</p>
          </div>
          <div className="relative flex items-center">
            <FontAwesomeIcon icon={faMagnifyingGlass} className="absolute left-3 text-gray-600"/>
            <input
              type="text"
              className="pl-10 pr-4 py-2 border border-gray-300 rounded-lg text-sm w-80"
              placeholder="Search..."
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
        </div>
        <hr className="border-gray-400 mb-4"/>
        <div className="flex justify-between items-center mb-4">
          <div className="flex items-center space-x-2 border border-gray-300 rounded-lg px-3 py-2 text-sm cursor-pointer">
            <FontAwesomeIcon icon={faFilter} className="text-gray-600"/>
            <span className="text-gray-600">Filter</span>
            {selectedCount > 0 && (
              <div className="flex items-center justify-center w-5 h-5 border rounded-full text-white text-xs bg-green-500 text-semibold">
                {selectedCount}
              </div>
            )}
          </div>
          <div
            className="flex items-center space-x-2 border border-gray-300 rounded-lg px-3 py-2 ml-4 text-sm cursor-pointer"
            onClick={handleSort}
          >
            <FontAwesomeIcon icon={faArrowUpAZ} className="text-gray-600"/>
            <span className="text-gray-600">Sort</span>
          </div>
          <div className="flex-grow"></div>
          <div className="flex items-center space-x-2 border border-gray-300 rounded-lg px-3 py-2 text-sm cursor-pointer">
            <FontAwesomeIcon icon={faPlus} className="text-gray-600"/>
            <span className="text-gray-600">Add new</span>
          </div>
        </div>
        <div className="overflow-x-auto">
          <Table hoverable>
            <Table.Head>
              <Table.HeadCell className="p-4">
                <FontAwesomeIcon icon={faSquareMinus} className="text-lg" />
              </Table.HeadCell>
              <Table.HeadCell>Meal name</Table.HeadCell>
              <Table.HeadCell>Category</Table.HeadCell>
              <Table.HeadCell>Main ingredient</Table.HeadCell>
              <Table.HeadCell>Date added</Table.HeadCell>
              <Table.HeadCell>Action</Table.HeadCell>
            </Table.Head>
            <Table.Body className="divide-y">
              {filteredData.slice((currentPage - 1) * dataPerPage, currentPage * dataPerPage).map((item) => (
                <Table.Row key={item.id} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                  <Table.Cell className="p-4">
                    <Checkbox onChange={handleCheckboxChange} />
                  </Table.Cell>
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                    <img src="/img/eng-flag.jpeg" alt="Indonesia" className="h-7 w-7 inline-block rounded-full mr-3" />
                    {item.mealName}
                  </Table.Cell>
                  <Table.Cell>{item.category}</Table.Cell>
                  <Table.Cell>{item.mainIngredient}</Table.Cell>
                  <Table.Cell>{item.dateAdded}</Table.Cell>
                  <Table.Cell>
                    <a href="#" className="font-medium text-cyan-600 hover:underline dark:text-cyan-500 mr-4">
                      <FontAwesomeIcon icon={faPen} />
                    </a>
                    <a href="#" className="font-medium text-red-600 hover:underline dark:text-cyan-500">
                      <FontAwesomeIcon icon={faTrash} />
                    </a>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
        <div className="flex justify-center mt-4">
          <nav className="inline-flex rounded-md shadow-sm" aria-label="Pagination">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="relative inline-flex items-center px-3 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-l-md hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <FontAwesomeIcon icon={faAnglesLeft} />
            </button>
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index + 1}
                onClick={() => handlePageChange(index + 1)}
                className={`relative inline-flex items-center px-4 py-2 text-sm font-medium ${
                  currentPage === index + 1
                    ? 'text-white bg-cyan-600 border-cyan-600'
                    : 'text-gray-500 bg-white border-gray-300'
                } hover:bg-gray-50`}
              >
                {index + 1}
              </button>
            ))}
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="relative inline-flex items-center px-3 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-r-md hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <FontAwesomeIcon icon={faAnglesRight} />
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
}

export default AdminFoodCategory;