"use client";

import { Button } from "flowbite-react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { useState, useEffect } from "react";
import "./rating.css";
import NavbarRating from "../components/navbar/navbarRating";

function Comment() {
    const [rating, setRating] = useState(0); // Initialize rating state
    const [comment, setComment] = useState("");
    const maxChars = 500;

    useEffect(() => {
        // Retrieve the rating value from localStorage
        const storedRating = localStorage.getItem('rating');
        if (storedRating) {
            setRating(parseInt(storedRating, 10));
        }
    }, []);

    const handleCommentChange = (e) => {
        if (e.target.value.length <= maxChars) {
            setComment(e.target.value);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Logic to handle comment submission and navigation
        window.location.href = '/thanks'; // Redirect to thanks page
    };

    return (
        <form onSubmit={handleSubmit}>
            <NavbarRating />
            <div className="flex max-w-2xl flex-col gap-4 mx-auto mt-24 items-center">
                <div>
                    <h2 className="text-3xl text-center font-bold leading-9 tracking-tight text-gray-800">
                        Thank you for giving us {rating} rating!
                    </h2>
                    <p className="mt-4 mb-4 text-lg text-center">
                        You can provide suggestions or criticism regarding the food you previously ordered or regarding the services we have provided.
                    </p>
                </div>
                <div>
                    {[...Array(5)].map((_, index) => {
                        return (
                            <FontAwesomeIcon
                                key={index}
                                icon={faStar}
                                className="star-comment"
                                color={index < rating ? "#ffc107" : "#e4e5e9"} // Yellow for rating, gray otherwise
                            />
                        );
                    })}
                </div>
                <div className="comment mt-8">
                    <input
                        className="font-medium w-full p-2 border-b border-gray-300 text-sm focus:outline-none focus:border-gray-500"
                        placeholder="Please tell us more"
                        value={comment}
                        onChange={handleCommentChange}
                    />
                    <p className="text-right text-xs font-semibold text-gray-400 mt-2">
                        {comment.length}/{maxChars}
                    </p>
                </div>
                <Button type="submit" className="w-[30vh] mt-6 bg-green-600 text-white hover:bg-green-200">Send</Button>
            </div>
        </form>
    );
}

export default Comment;