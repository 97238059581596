"use client";

import { Button, Checkbox, Label } from "flowbite-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlass,
  faPlus,
  faMinus,
  faPen,
  faHeart,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import SidebarMember from "../components/sidebar/sidebarMember";
import { useEffect, useState } from "react";
import { getAuthAndProfileResponses } from "../utils/localstorage";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function Cart() {
  const [parentChecked, setParentChecked] = useState(false); // Track whether the parent checkbox is checked or not
  const navigate = useNavigate();

  const handleParentChange = (event) => {
    const isChecked = event.target.checked;
    setParentChecked(isChecked);

    // Update all checkboxes to match the parent checkbox state
    const checkboxes = document.querySelectorAll("#makanan-checkbox");
    checkboxes.forEach((checkbox) => {
      checkbox.checked = isChecked;
    });
  };

  const orderMeal = async () => {
    const checkboxes = document.querySelectorAll("#makanan-checkbox");

    const values = Array.from(checkboxes)
      .filter((checkbox) => checkbox.checked) // Get only checked checkboxes
      .map((checkbox) => checkbox.value);

    if (values.length > 0) {
      try {
        setIsLoading(true);
        await axios
          .post(
            process.env.REACT_APP_API_BASE_URL + "members/orders",
            JSON.stringify({
              cart_ids: values,
            }),
            {
              headers: {
                Authorization: `Bearer ${authResponse.token}`,
                "Access-Token": `${accessToken}`,
                "Refresh-Token": `${refreshToken}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then(() => {
            fetchCartData();
            navigate("/thanks-meal");
          });
      } catch (error) {
        alert(error?.response?.data?.data?.errors);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const [isLoading, setIsLoading] = useState(false);

  const { authResponse, profileResponse } = getAuthAndProfileResponses();

  const accessToken = authResponse["access-token"];
  const refreshToken = authResponse["refresh-token"];

  const [errorMessage, setErrorMessage] = useState("");

  const [cartData, setCartData] = useState([]);

  const addCartQuantity = async (cartid) => {
    try {
      setIsLoading(true);
      await axios
        .patch(
          process.env.REACT_APP_API_BASE_URL + "members/carts/" + cartid,
          JSON.stringify({
            quantity: 1,
          }),
          {
            headers: {
              Authorization: `Bearer ${authResponse.token}`,
              "Access-Token": `${accessToken}`,
              "Refresh-Token": `${refreshToken}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then(() => {
          fetchCartData();
        });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const substractCartQuantity = async (cartid) => {
    try {
      setIsLoading(true);
      await axios
        .patch(
          process.env.REACT_APP_API_BASE_URL + "members/carts/" + cartid,
          JSON.stringify({
            quantity: -1,
          }),
          {
            headers: {
              Authorization: `Bearer ${authResponse.token}`,
              "Access-Token": `${accessToken}`,
              "Refresh-Token": `${refreshToken}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then(() => {
          fetchCartData();
        });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteCart = async (cartid) => {
    try {
      setIsLoading(true);
      await axios
        .delete(
          process.env.REACT_APP_API_BASE_URL + "members/carts/" + cartid,
          {
            headers: {
              Authorization: `Bearer ${authResponse.token}`,
              "Access-Token": `${accessToken}`,
              "Refresh-Token": `${refreshToken}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then(() => {
          fetchCartData();
        });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchCartData = async () => {
    try {
      setIsLoading(true);
      await axios
        .get(process.env.REACT_APP_API_BASE_URL + "carts/own", {
          headers: {
            Authorization: `Bearer ${authResponse.token}`,
            "Access-Token": `${accessToken}`,
            "Refresh-Token": `${refreshToken}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setCartData(response?.data?.data);
        });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevents the form from submitting
  };

  useEffect(() => {
    fetchCartData();
  }, []);

  return (
    <form className="flex" onSubmit={handleSubmit}>
      <SidebarMember />
      <div className="flex flex-col gap-4 mt-8 mx-8 w-full">
        <div className="flex justify-between items-center">
          <div className="mb-2">
            <h2 className="text-2xl font-bold leading-9 tracking-tight text-gray-800">
              Keranjang Saya
            </h2>
            <p className="text-xs text-gray-600">This is your shopping list</p>
          </div>
          <div className="relative flex items-center">
            <FontAwesomeIcon
              icon={faMagnifyingGlass}
              className="absolute left-3 text-gray-600"
            />
            <input
              type="text"
              className="pl-10 pr-4 py-2 border border-gray-300 rounded-lg text-sm w-80"
              placeholder="Search..."
            />
          </div>
        </div>
        <hr className="border-gray-400 mb-4" />
        {errorMessage && (
          <div className="text-red-600 font-semibold mb-4">{errorMessage}</div>
        )}
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <Checkbox
              id="accept"
              checked={parentChecked}
              onChange={handleParentChange}
            />
            <Label
              htmlFor="accept"
              id="pilih-semua-checkbox"
              className="flex font-semibold cursor-pointer"
            >
              Pilih Semua
            </Label>
          </div>
        </div>
        <div className="flex gap-4 w-full">
          <div className="flex flex-col w-2/3 overflow-y-auto max-h-[70vh] pr-4">
            {cartData?.map((item, index) => (
              <div key={item?.id} className="bg-gray-300 rounded-lg p-6 mb-4">
                <div className="flex items-center mb-2">
                  <p className="font-bold text-lg">
                    {item?.meal?.partner?.name}
                  </p>
                </div>
                <div className="flex items-center mb-4">
                  <Checkbox
                    id="makanan-checkbox"
                    value={item?.id}
                    className="mr-4 mt-4 cursor-pointer"
                  />
                  <div className="flex items-center w-full">
                    <img
                      src={item?.meal?.images[0]?.image?.path}
                      alt="Indonesia"
                      className="h-32 w-32 mr-4 rounded-md object-cover"
                    />
                    <div className="text-sm flex-1">
                      <p>{item?.name}</p>
                      <p className="text-xs mt-2">{item?.meal?.description}</p>
                    </div>
                    <div className="item flex items-center gap-2">
                      <div
                        className="hover:bg-gray-200 w-10 h-10 rounded-full flex items-center justify-center"
                        onClick={() => substractCartQuantity(item?.id)}
                      >
                        <FontAwesomeIcon
                          icon={faMinus}
                          className="cursor-pointer"
                        />
                      </div>
                      <p className="mx-2">{item?.quantity}</p>
                      <div
                        className="hover:bg-gray-200 w-10 h-10 rounded-full flex items-center justify-center"
                        onClick={() => addCartQuantity(item?.id)}
                      >
                        <FontAwesomeIcon
                          icon={faPlus}
                          className="cursor-pointer"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="icon flex justify-end gap-4 mt-4">
                  <div
                    className="hover:bg-gray-200 w-10 h-10 rounded-full flex items-center justify-center"
                    onClick={() => deleteCart(item?.id)}
                  >
                    <FontAwesomeIcon
                      icon={faTrash}
                      className="cursor-pointer"
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="w-1/3 bg-gray-300 rounded-lg p-8 max-h-[42vh]">
            <p className="font-bold mb-4">Ringkasan belanja</p>
            <div className="flex justify-between mb-20">
              <p className="font-medium">Total porsi makanan dalam keranjang</p>
              <p className="range">
                {cartData.reduce((acc, item) => acc + item.quantity, 0)}
              </p>
            </div>
            <hr className="border-b border-gray-500 mb-4" />
            <div className="flex justify-center">
              <Button
                type="submit"
                className="w-[55vh] mt-4 bg-green-600 text-white hover:bg-green-200"
                onClick={() => orderMeal()}
              >
                Checkout
              </Button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}

export default Cart;
