"use client";

import { Checkbox, Table } from "flowbite-react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faFilter, faArrowUpAZ, faPlus, faSquareMinus, faPen, faTrash, faAnglesRight, faAnglesLeft } from '@fortawesome/free-solid-svg-icons';
import { useState, useEffect, useMemo } from "react";
import SidebarAdmin from "../components/sidebar/sidebarAdmin";

function AdminMealAllergies() {
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage] = useState(10);
  const [totalData, setTotalData] = useState(0); // Assume total data is fetched from the server
  const [totalPages, setTotalPages] = useState(1);
  const [mealAllergies, setMealAllergies] = useState([]);
  const [filteredMealAllergies, setFilteredMealAllergies] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedAllergies, setSelectedAllergies] = useState([]);

  useEffect(() => {
    // Fetch meal allergies from the API
    const fetchMealAllergies = async () => {
      try {
        const response = await fetch(`https://example.com/api/v1/meal-allergies?page=${currentPage}&limit=${dataPerPage}`);
        const result = await response.json();
        if (result.status === "success") {
          setMealAllergies(result.data.datas);
          setTotalData(result.data.total);
          setTotalPages(Math.ceil(result.data.total / dataPerPage));
        } else {
          console.error("Failed to fetch meal allergies:", result.message);
        }
      } catch (error) {
        console.error("An error occurred while fetching meal allergies:", error);
      }
    };

    fetchMealAllergies();
  }, [currentPage, dataPerPage]);

  useEffect(() => {
    // Filter meal allergies based on search term
    const filtered = mealAllergies.filter(allergy =>
      allergy.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      allergy.allergy.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredMealAllergies(filtered);
  }, [searchTerm, mealAllergies]);

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSelectAllergy = (id) => {
    setSelectedAllergies(prev => {
      if (prev.includes(id)) {
        return prev.filter(selectedId => selectedId !== id);
      }
      return [...prev, id];
    });
  };

  const sortedMealAllergies = useMemo(() => {
    return [...filteredMealAllergies].sort((a, b) => a.name.localeCompare(b.name));
  }, [filteredMealAllergies]);

  const paginatedMealAllergies = useMemo(() => {
    const start = (currentPage - 1) * dataPerPage;
    return sortedMealAllergies.slice(start, start + dataPerPage);
  }, [currentPage, sortedMealAllergies]);

  return (
    <div className="flex h-screen">
      <SidebarAdmin className="fixed h-full" />
      <div className="flex flex-col flex-grow p-6 overflow-y-auto">
        <div className="flex justify-between items-center mb-4">
          <div className="mb-2">
            <h2 className="text-2xl font-bold leading-9 tracking-tight text-gray-800">List Meal Allergies</h2>
            <p className="text-xs text-gray-600">This is a list of meal allergies</p>
          </div>
          <div className="relative flex items-center">
            <FontAwesomeIcon icon={faMagnifyingGlass} className="absolute left-3 text-gray-600"/>
            <input
              type="text"
              className="pl-10 pr-4 py-2 border border-gray-300 rounded-lg text-sm w-80"
              placeholder="Search..."
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
        </div>
        <hr className="border-gray-400 mb-4"/>
        <div className="flex justify-between items-center mb-4">
          <div className="flex items-center space-x-2 border border-gray-300 rounded-lg px-3 py-2 text-sm cursor-pointer">
            <FontAwesomeIcon icon={faFilter} className="text-gray-600"/>
            <a href="#" className="text-gray-600">Filter</a>
            {selectedAllergies.length > 0 && (
              <div className="flex items-center justify-center w-5 h-5 border rounded-full text-white text-xs bg-green-500 text-semibold">
                {selectedAllergies.length}
              </div>
            )}
          </div>
          <div className="flex items-center space-x-2 border border-gray-300 rounded-lg px-3 py-2 ml-4 text-sm cursor-pointer">
            <FontAwesomeIcon icon={faArrowUpAZ} className="text-gray-600"/>
            <a href="#" className="text-gray-600">Sort</a>
          </div>
          <div className="flex-grow"></div>
          <div className="flex items-center space-x-2 border border-gray-300 rounded-lg px-3 py-2 text-sm cursor-pointer">
            <FontAwesomeIcon icon={faPlus} className="text-gray-600"/>
            <a href="#" className="text-gray-600">Add new</a>
          </div>
        </div>
        <div className="overflow-x-auto">
          <Table hoverable>
            <Table.Head>
              <Table.HeadCell className="p-4">
                <FontAwesomeIcon icon={faSquareMinus} className="text-lg" />
              </Table.HeadCell>
              <Table.HeadCell>Name</Table.HeadCell>
              <Table.HeadCell>Meal Allergies</Table.HeadCell>
              <Table.HeadCell>Date of Birth</Table.HeadCell>
              <Table.HeadCell>Sex</Table.HeadCell>
              <Table.HeadCell>Date added</Table.HeadCell>
              <Table.HeadCell>Action</Table.HeadCell>
            </Table.Head>
            <Table.Body className="divide-y">
              {paginatedMealAllergies.length > 0 ? paginatedMealAllergies.map((allergy) => (
                <Table.Row key={allergy.id} className="bg-white dark:border-gray-700 dark:bg-gray-800">
                  <Table.Cell className="p-4">
                    <Checkbox
                      checked={selectedAllergies.includes(allergy.id)}
                      onChange={() => handleSelectAllergy(allergy.id)}
                    />
                  </Table.Cell>
                  <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                    <div className="flex">
                      <img src="/img/eng-flag.jpeg" alt="Flag" className="h-7 w-7 inline-block rounded-full mr-3 mt-1" />
                      <div>
                        <p className="font-semibold">{allergy.name}</p>
                        <p className="text-xs">{allergy.email}</p>
                      </div>
                    </div>
                  </Table.Cell>
                  <Table.Cell>{allergy.allergy}</Table.Cell>
                  <Table.Cell>{new Date(allergy.dob).toLocaleDateString()}</Table.Cell>
                  <Table.Cell>{allergy.sex}</Table.Cell>
                  <Table.Cell>{new Date(allergy.dateAdded).toLocaleDateString()}</Table.Cell>
                  <Table.Cell>
                    <a href="#" className="font-medium text-cyan-600 hover:underline dark:text-cyan-500 mr-4">
                      <FontAwesomeIcon icon={faPen} />
                    </a>
                    <a href="#" className="font-medium text-red-600 hover:underline dark:text-cyan-500">
                      <FontAwesomeIcon icon={faTrash} />
                    </a>
                  </Table.Cell>
                </Table.Row>
              )) : (
                <Table.Row>
                  <Table.Cell colSpan="7" className="text-center py-4">No meal allergies found</Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </div>
        <div className="flex justify-center mt-4">
          <nav className="inline-flex rounded-md shadow-sm" aria-label="Pagination">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="relative inline-flex items-center px-3 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-l-md hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <FontAwesomeIcon icon={faAnglesLeft} />
            </button>
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index + 1}
                onClick={() => handlePageChange(index + 1)}
                className={`relative inline-flex items-center px-4 py-2 text-sm font-medium ${
                  currentPage === index + 1
                    ? 'text-white bg-cyan-600 border-cyan-600'
                    : 'text-gray-500 bg-white border-gray-300'
                } hover:bg-gray-50`}
              >
                {index + 1}
              </button>
            ))}
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="relative inline-flex items-center px-3 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-r-md hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <FontAwesomeIcon icon={faAnglesRight} />
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
}

export default AdminMealAllergies;