"use client";

import { Label, Button } from "flowbite-react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import SidebarAdmin from "../components/sidebar/sidebarAdmin";

function AdminDetailMealAllergies() {
  return (
    <div className="flex h-screen">
      <SidebarAdmin className="fixed h-full" />
      <div className="flex flex-col flex-grow p-6 overflow-y-auto">
        <div className="flex items-center mb-4">
          <a href='/admin-meal-allergies'>
            <FontAwesomeIcon icon={faChevronLeft} className="text-lg" />
          </a>
          <div className="mb-2 ml-10">
            <h2 className="text-2xl font-bold leading-9 tracking-tight text-gray-800">Detail Meal Allergies</h2>
            <p className="text-xs text-gray-600">This is a detail of Meal Allergies</p>
          </div>
        </div>
        <hr className="border-gray-400 mb-4"/>
        <div className="flex justify-between items-center mb-4">
          <p className="font-semibold ml-8">23 Juli 2023</p>
          <div className="flex items-center space-x-2 mr-4">
            <a href="#" className="font-medium text-cyan-500 dark:text-cyan-500">Edit</a>
            <p className="text-cyan-500 font-semibold">|</p>
            <a href="#" className="font-medium text-cyan-500 dark:text-cyan-500">Delete</a>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4 mb-4 mt-4">
          <div>
            <Label htmlFor="name" className="ml-8 font-semibold text-md">Name</Label>
            <p className="text-sm ml-8 mt-2">Bambang Siswanto</p>
          </div>
          <div>
            <Label htmlFor="email" className="ml-8 font-semibold text-md">Email</Label>
            <p className="text-sm ml-8 mt-2">bambangsuswinto@gmail.com</p>
          </div>
          <div className="mt-8">
            <Label htmlFor="partner" className="ml-8 font-semibold text-md">Partner</Label>
            <p className="text-sm ml-8 mt-2">Warung Nana</p>
          </div>
          <div className="mt-8">
            <Label htmlFor="address" className="ml-8 font-semibold text-md">Address</Label>
            <p className="text-sm ml-8 mt-2">Jl. Raya Puputan No.86, Dangin Puri Klod, Kec. Denpasar Tim., Kota Denpasar, Bali 80234</p>
          </div>
          <div className="mt-8">
            <Label htmlFor="address" className="ml-8 font-semibold text-md">Allergies</Label>
            <p className="text-sm ml-8 mt-2">1. Tidak bisa makan telor</p>
            <p className="text-sm ml-8 mt-2">2. Tidak bisa makan nasi yang terlalu keras atau terlalu lembek</p>
          </div>
        </div>
        <div className="flex">
          <Button type="submit" className="w-[15vw] mt-16 ml-8 bg-green-600 text-white hover:bg-green-200">Save</Button>
        </div>
      </div>
    </div>
  );
}

export default  AdminDetailMealAllergies;