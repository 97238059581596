"use client";

import { useState } from "react";
import { Button, Checkbox, Label, TextInput } from "flowbite-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import NavbarRegis from "../components/navbar/navbarRegis";
import "./register.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function Partner() {
  const [isVerificationCodeSent, setIsVerificationCodeSent] = useState(false);
  const [show, setShow] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [totalRest, setTotalRest] = useState("");
  const [restName, setRestName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [agree, setAgree] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [formData, setFormData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    name: "",
    addresses_name: "",
    addresses_address: "",
    addresses_note: "",
    addresses_latitude: "",
    addresses_longitude: "",
    role: 3, // Tambahkan role di sini
  });

  const navigate = useNavigate();

  const handleClick = () => setShow(!show);
  const confirmClick = () => setShowConfirm(!showConfirm);

  const handleChange = (e, prop) => {
    const { value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [prop]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formData.password !== formData.confirmPassword) {
      alert("Passwords do not match");
      return;
    }

    const payload = {
      user: {
        email: formData.email,
        password: formData.password,
        confirm_password: formData.confirmPassword,
        address: [
          {
            name: formData.addresses_name,
            address: formData.addresses_address,
            note: formData.addresses_note,
            longitude: formData.addresses_longitude,
            latitude: formData.addresses_latitude,
          },
        ],
      },
      name: formData.name,
    };

    // console.log(payload);
    try {
      //   const response = await axios.post(
      //     "https://meals-api.103.127.137.58.nip.io/api/v1/partners/register",
      //     payload
      //   );

      //   if (response) {
      //     alert("Register partner success");
      //     setEmail(payload.user.email);
      //     // Set nilai email ke state
      //     setIsVerificationCodeSent(true);
      //   }

      const registerResponse = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}partners/register`,
        payload
      );

      // * Set auth registerResponse to localStorage
      localStorage.setItem(
        "registerResponse",
        JSON.stringify({
          ...registerResponse.data.data,
          "access-token": registerResponse.headers["access-token"],
          "refresh-token": registerResponse.headers["refresh-token"],
        })
      );

      navigate("/verify-email");
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <NavbarRegis />
      <div className="flex max-w-md flex-col gap-4 mx-auto mt-16 mb-14">
        <div>
          <h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-gray-800">
            Sign Up for App
          </h2>
        </div>
        {errorMessage && (
          <div className="text-red-600 text-center mb-4">{errorMessage}</div>
        )}
        <div className="p-name mr-2">
          <Label htmlFor="name" value="Name" />
          <TextInput
            id="name"
            className="mt-2"
            required
            onChange={(e) => handleChange(e, "name")}
          />
        </div>
        <div>
          <div className="mb-2 block">
            <Label htmlFor="email" value="Email" />
          </div>
          <TextInput
            id="email"
            type="email"
            required
            onChange={(e) => handleChange(e, "email")}
          />
        </div>

        <div>
          <div className="mb-2 block">
            <Label htmlFor="password1" value="Password" />
          </div>
          <div className="flex">
            <input
              id="password1"
              type={show ? "text" : "password"}
              className="pass text-sm py-3 w-[100%] focus:border-cyan-500 focus:ring-cyan-500"
              placeholder="8+ characters"
              required
              onChange={(e) => handleChange(e, "password")}
            />
            <p
              onClick={handleClick}
              className="item-center ml-[-5vh] mt-3 cursor-pointer text-gray-400"
            >
              {show ? (
                <FontAwesomeIcon icon={faEyeSlash} />
              ) : (
                <FontAwesomeIcon icon={faEye} />
              )}
            </p>
          </div>
        </div>

        <div>
          <div className="mb-2 block">
            <Label htmlFor="password2" value="Confirmation Password" />
          </div>
          <div className="flex">
            <input
              id="password2"
              type={showConfirm ? "text" : "password"}
              className="confirm text-sm py-3 w-[100%] focus:border-cyan-500 focus:ring-cyan-500"
              placeholder="8+ characters"
              required
              onChange={(e) => handleChange(e, "confirmPassword")}
            />
            <p
              onClick={confirmClick}
              className="item-center ml-[-5vh] mt-3 cursor-pointer text-gray-400"
            >
              {showConfirm ? (
                <FontAwesomeIcon icon={faEyeSlash} />
              ) : (
                <FontAwesomeIcon icon={faEye} />
              )}
            </p>
          </div>
        </div>

        {/* Address */}
        <div>
          <h2 className="font-bold mb-2">Address</h2>
          <div className="mb-4">
            <Label htmlFor="address_name" value="Name" />
            <TextInput
              id="address_name"
              type="text"
              placeholder="Home Address"
              onChange={(e) => handleChange(e, "addresses_name")}
              required
              className="mt-2"
            />
          </div>

          <div className="mb-2">
            <Label htmlFor="address" value="Adress" />
            <TextInput
              id="address"
              type="text"
              placeholder="Your Adress"
              onChange={(e) => handleChange(e, "addresses_address")}
              required
              className="mt-2"
            />
          </div>

          <div className="mb-2">
            <Label htmlFor="address_note" value="Note" />
            <TextInput
              id="address_note"
              type="text"
              placeholder="Your Adress Note"
              onChange={(e) => handleChange(e, "addresses_note")}
              required
              className="mt-2"
            />
          </div>

          <div className="mb-2">
            <Label htmlFor="address_latitude" value="Latitude" />
            <TextInput
              id="address_latitude"
              type="text"
              placeholder="Your Latitude"
              onChange={(e) => handleChange(e, "addresses_latitude")}
              required
              className="mt-2"
            />
          </div>

          <div className="mb-2">
            <Label htmlFor="address_longitude" value="Longitude" />
            <TextInput
              id="address_longitude"
              type="text"
              placeholder="Your Longitude"
              onChange={(e) => handleChange(e, "addresses_longitude")}
              required
              className="mt-2"
            />
          </div>
        </div>

        <div className="flex items-center max-w-xl -mt-2">
          <div className="remember cursor-pointer">
            <Checkbox
              id="remember"
              className="cursor-pointer"
              checked={agree}
              onChange={(e) => setAgree(e.target.checked)}
            />
            <Label htmlFor="remember" className="ml-2 text-xs cursor-pointer">
              I Agree
            </Label>
          </div>
          <div className="policy">
            <Label
              htmlFor="policy"
              className="ml-2 text-xs text-cyan-600 dark:text-cyan-500 cursor-pointer"
            >
              Privacy Policy
            </Label>
          </div>
        </div>
        <div className="text-xs text-center mt-4">
          <p>
            By clicking "I Agree" you agree to the application TOS <br />
            and Privacy Policy
          </p>
        </div>
        <Button
          type="submit"
          className="bg-green-600 text-white hover:bg-green-200"
          disabled={!agree}
        >
          Submit
        </Button>
        <div className="text-sm text-center -mt-2">
          <p>
            Already have an account?
            <a
              href="#"
              className="ml-1 font-semibold text-cyan-600 dark:text-cyan-500 cursor-pointer"
            >
              Log In
            </a>
          </p>
        </div>
      </div>
    </form>
  );
}

export default Partner;
