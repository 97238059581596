"use client";

import { Label, TextInput, Textarea, Button } from "flowbite-react";
import NavbarLanding from "../components/navbar/navbarLanding";

function ContactUs() {
  return (
    <form>
      <NavbarLanding />
      <div className="flex flex-col md:flex-row max-w-7xl mx-auto mt-12 items-center">
        <div className="md:w-1/2 p-4 flex flex-col items-center">
          <img src="/img/contact.svg" alt="Indonesia" className="w-full object-cover mb-4" />
        </div>
        <div className="md:w-1/2 p-4 ml-20 mr-10" style={{ maxWidth: "calc(50% - 60px)" }}>
          <h2 className="text-2xl font-bold text-center mb-4">Bicaralah dengan ahli kami dan dapatkan konsultasi makanan sehat yang baik</h2>
          <p className="mb-6 text-center">Butuh bantuan dengan makanan sehat atau masalah situs web? Kami dapat membantu dengan memberi tahu Anda melalui formulir di bawah ini</p>
          <div className="space-y-4">
            <div className="flex space-x-4">
              <div className="flex-1">
                <Label htmlFor="firstName1" value="First Name" />
                <TextInput id="firstName1" type="text" className="mt-2 w-full" required />
              </div>
              <div className="flex-1">
                <Label htmlFor="lastName1" value="Last Name" />
                <TextInput id="lastName1" type="text" className="mt-2 w-full" required />
              </div>
            </div>
            <div>
              <Label htmlFor="email1" value="Email" />
              <TextInput id="email1" type="text" className="mt-2 w-full" required />
            </div>
            <div>
              <Label htmlFor="comment" value="Message" />
              <Textarea id="comment" placeholder="Leave a comment..." required rows={5} className="mt-2 w-full" />
            </div>
          </div>
          <Button type="submit" className="mt-10 w-full mb-10 bg-green-600 text-white hover:bg-green-200">Send</Button>
        </div>
      </div>
    </form>
  );
}

export default ContactUs;
