"use client";

import { Checkbox, Table } from "flowbite-react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faFilter, faArrowUpAZ, faPlus, faSquareMinus, faPen, faTrash, faAnglesRight, faAnglesLeft } from '@fortawesome/free-solid-svg-icons';
import { useState, useEffect } from "react";
import SidebarAdmin from "../components/sidebar/sidebarAdmin";

function AdminListIllness() {
	const [currentPage, setCurrentPage] = useState(1);
	const [data, setData] = useState([]);
	const [selectedFilters, setSelectedFilters] = useState([]);
	const [searchTerm, setSearchTerm] = useState("");
	const [sort, setSort] = useState({ field: "illness", direction: "asc" });
	const [totalData, setTotalData] = useState(0); // Add state for total data
	const dataPerPage = 10;

	useEffect(() => {
		fetchData();
	}, [currentPage, sort, selectedFilters, searchTerm]);

	const fetchData = async () => {
		try {
			let url = new URL(
				"https://meals-api.103.127.137.58.nip.io/api/v1/illness",
			);
			const params = {
				page: currentPage,
				limit: dataPerPage,
				sort: sort.field,
				direction: sort.direction,
				q: searchTerm,
				filters: selectedFilters.join(","),
			};

			Object.keys(params).forEach((key) =>
				url.searchParams.append(key, params[key]),
			);

			const response = await fetch(url);

			if (!response.ok) {
				throw new Error("Network response was not ok");
			}

			const result = await response.json();

			if (result.data && result.data.datas) {
				setData(result.data.datas);
				setTotalData(result.data.total); // Set total data from response
			} else {
				setData([]);
				setTotalData(0); // Reset total data if no data
			}
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};

	const handlePageChange = (page) => {
		setCurrentPage(page);
	};

	const handleSortChange = (field) => {
		setSort((prevSort) => ({
			field,
			direction: prevSort.direction === "asc" ? "desc" : "asc",
		}));
	};

	const handleFilterChange = (filter) => {
		setSelectedFilters((prevFilters) => {
			if (prevFilters.includes(filter)) {
				return prevFilters.filter((f) => f !== filter);
			} else {
				return [...prevFilters, filter];
			}
		});
	};

	const handleSearchChange = (event) => {
		setSearchTerm(event.target.value);
	};

	const filteredData = data.filter((illness) =>
		illness.name.toLowerCase().includes(searchTerm.toLowerCase()),
	);

	const sortedData = filteredData.sort((a, b) => {
		const field = sort.field;
		if (sort.direction === "asc") {
			return a[field].localeCompare(b[field]);
		} else {
			return b[field].localeCompare(a[field]);
		}
	});

	const paginatedData = sortedData.slice(
		(currentPage - 1) * dataPerPage,
		currentPage * dataPerPage,
	);

	return (
		<div className="flex h-screen">
			<SidebarAdmin className="fixed h-full" />
			<div className="flex flex-col flex-grow p-6 overflow-y-auto">
				<div className="flex justify-between items-center mb-4">
					<div className="mb-2">
						<h2 className="text-2xl font-bold leading-9 tracking-tight text-gray-800">
							List Illness
						</h2>
						<p className="text-xs text-gray-600">This is a list of Illness</p>
					</div>
					<div className="relative flex items-center">
						<FontAwesomeIcon
							icon={faMagnifyingGlass}
							className="absolute left-3 text-gray-600"
						/>
						<input
							type="text"
							className="pl-10 pr-4 py-2 border border-gray-300 rounded-lg text-sm w-80"
							placeholder="Search..."
							value={searchTerm}
							onChange={handleSearchChange}
						/>
					</div>
				</div>
				<hr className="border-gray-400 mb-4" />
				<div className="flex justify-between items-center mb-4">
					<div
						className="flex items-center space-x-2 border border-gray-300 rounded-lg px-3 py-2 text-sm cursor-pointer"
						onClick={() => handleFilterChange("filterName")}
					>
						<FontAwesomeIcon icon={faFilter} className="text-gray-600" />
						<span className="text-gray-600">Filter</span>
						{selectedFilters.length > 0 && (
							<div className="flex items-center justify-center w-5 h-5 border rounded-full text-white text-xs bg-green-500 text-semibold">
								{selectedFilters.length}
							</div>
						)}
					</div>
					<div
						className="flex items-center space-x-2 border border-gray-300 rounded-lg px-3 py-2 ml-4 text-sm cursor-pointer"
						onClick={() => handleSortChange("illness")}
					>
						<FontAwesomeIcon icon={faArrowUpAZ} className="text-gray-600" />
						<span className="text-gray-600">Sort</span>
					</div>
					<div className="flex-grow"></div>
					<div className="flex items-center space-x-2 border border-gray-300 rounded-lg px-3 py-2 text-sm cursor-pointer">
						<FontAwesomeIcon icon={faPlus} className="text-gray-600" />
						<span className="text-gray-600">Add new</span>
					</div>
				</div>
				<div className="overflow-x-auto">
					<Table hoverable>
						<Table.Head>
							<Table.HeadCell className="p-4">
								<FontAwesomeIcon icon={faSquareMinus} className="text-lg" />
							</Table.HeadCell>
							<Table.HeadCell>Illness</Table.HeadCell>
							<Table.HeadCell>Category Illness</Table.HeadCell>
							<Table.HeadCell>Range of age</Table.HeadCell>
							<Table.HeadCell>Total</Table.HeadCell>
							<Table.HeadCell>Date added</Table.HeadCell>
							<Table.HeadCell>Action</Table.HeadCell>
						</Table.Head>
						<Table.Body className="divide-y">
							{paginatedData.length === 0 ? (
								<Table.Row>
									<Table.Cell colSpan="7" className="text-center">
										No data available
									</Table.Cell>
								</Table.Row>
							) : (
								paginatedData.map((illness) => (
									<Table.Row
										key={illness.id}
										className="bg-white dark:border-gray-700 dark:bg-gray-800"
									>
										<Table.Cell className="p-4">
											<Checkbox />
										</Table.Cell>
										<Table.Cell>{illness.name}</Table.Cell>
										<Table.Cell>{illness.category}</Table.Cell>
										<Table.Cell>{illness.ageRange}</Table.Cell>
										<Table.Cell>{illness.total}</Table.Cell>
										<Table.Cell>
											{new Date(illness.dateAdded).toLocaleDateString()}
										</Table.Cell>
										<Table.Cell>
											<a
												href="#"
												className="font-medium text-cyan-600 hover:underline dark:text-cyan-500 mr-4"
											>
												<FontAwesomeIcon icon={faPen} />
											</a>
											<a
												href="#"
												className="font-medium text-red-600 hover:underline dark:text-cyan-500"
											>
												<FontAwesomeIcon icon={faTrash} />
											</a>
										</Table.Cell>
									</Table.Row>
								))
							)}
						</Table.Body>
					</Table>
				</div>
				<div className="flex justify-center mt-4">
					<nav
						className="inline-flex rounded-md shadow-sm"
						aria-label="Pagination"
					>
						<button
							onClick={() => handlePageChange(currentPage - 1)}
							disabled={currentPage === 1}
							className="relative inline-flex items-center px-3 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-l-md hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
						>
							<FontAwesomeIcon icon={faAnglesLeft} />
						</button>
						{Array.from(
							{ length: Math.ceil(totalData / dataPerPage) },
							(_, index) => (
								<button
									key={index + 1}
									onClick={() => handlePageChange(index + 1)}
									className={`relative inline-flex items-center px-4 py-2 text-sm font-medium ${
										currentPage === index + 1
											? "text-white bg-cyan-600 border-cyan-600"
											: "text-gray-500 bg-white border-gray-300"
									} hover:bg-gray-50`}
								>
									{index + 1}
								</button>
							),
						)}
						<button
							onClick={() => handlePageChange(currentPage + 1)}
							disabled={currentPage === Math.ceil(totalData / dataPerPage)}
							className="relative inline-flex items-center px-3 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-r-md hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
						>
							<FontAwesomeIcon icon={faAnglesRight} />
						</button>
					</nav>
				</div>
			</div>
		</div>
	);
}

export default AdminListIllness;
