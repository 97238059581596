"use client";

import { Label, Button } from "flowbite-react";
import SidebarPartner from "../components/sidebar/sidebarPartner";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

function DetailAllergies() {

  const statuses = [
    { status: 'Pending', color: 'bg-yellow-100 text-yellow-800' },
    { status: 'Confirmed', color: 'bg-blue-100 text-blue-800' },
    { status: 'Being Prepared', color: 'bg-green-100 text-green-800' },
    { status: 'Prepared', color: 'bg-red-100 text-red-800' },
    { status: 'Picked Up', color: 'bg-purple-100 text-purple-800' },
    { status: 'Out for Delivery', color: 'bg-blue-100 text-blue-800' },
    { status: 'Delivered', color: 'bg-green-100 text-green-800' },
    { status: 'Completed', color: 'bg-purple-100 text-purple-800' },
    { status: 'Rejected', color: 'bg-red-200 text-red-600' }
  ];

  const currentStatus = statuses[2]; // status

  return (
    <div className="flex h-screen">
      <SidebarPartner className="fixed h-full" />
      <div className="flex flex-col flex-grow p-6 overflow-y-auto">
        <div className="flex items-center mb-4">
          <a href="/meal-allergies">
            <FontAwesomeIcon icon={faChevronLeft} className="text-lg" />
          </a>
          <div className="mb-2 ml-10">
            <h2 className="text-2xl font-bold leading-9 tracking-tight text-gray-800">Detail Order Member</h2>
            <p className="text-xs text-gray-600">This is a detail of order member</p>
          </div>
          <div className="ml-auto">
            <p className={`text-lg mt-2 py-2 px-6 rounded-full font-semibold ${currentStatus.color}`}>{currentStatus.status}</p>
          </div>
        </div>
        <hr className="border-gray-400 mb-4"/>
        <div className="flex justify-between items-center mb-4">
          <p className="font-semibold ml-8">23 Juli 2023</p>
          <div className="flex items-center space-x-2 mr-4">
            <a href="#" className="font-medium text-cyan-500 dark:text-cyan-500">Edit</a>
            <p className="text-cyan-500 font-semibold">|</p>
            <a href="#" className="font-medium text-cyan-500 dark:text-cyan-500">Delete</a>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4 mb-4 mt-4">
          <div>
            <Label htmlFor="name" className="ml-8 font-semibold text-md">Name</Label>
            <p className="text-sm ml-8 mt-2">Bambang Siswanto</p>
          </div>
          <div>
            <Label htmlFor="email" className="ml-8 font-semibold text-md">Email</Label>
            <p className="text-sm ml-8 mt-2">bambangsuswinto@gmail.com</p>
          </div>
          <div className="mt-8">
            <Label htmlFor="partner" className="ml-8 font-semibold text-md">Partner</Label>
            <p className="text-sm ml-8 mt-2">Warung Nana</p>
          </div>
          <div className="mt-8">
            <Label htmlFor="address" className="ml-8 font-semibold text-md">Address</Label>
            <p className="text-sm ml-8 mt-2">Jl. Raya Puputan No.86, Dangin Puri Klod, Kec. Denpasar Tim., Kota Denpasar, Bali 80234</p>
          </div>
          <div className="mt-8">
            <Label htmlFor="totalOrder" className="ml-8 font-semibold text-md">Total Order</Label>
            <p className="mt-2 ml-8 text-sm">10 packages of rice, 10 packages of vegetables, 10 packages of beef ribs, 10 packages of eggs</p>
          </div>
        </div>
        <div className="mb-4 mt-8">
          <div className="grid grid-cols-2 gap-4 mt-4">
            <div className="ml-8">
              <Label htmlFor="allergies" className="font-semibold text-md">Menu</Label>
              <p className="mt-2 text-sm">1. Sup Iga Sapi</p>
              <p className="mt-2 text-sm">2. Telor Balado</p>
            </div>
            <div className="ml-8">
              <Label htmlFor="illnesses" className="font-semibold text-md">Allergies</Label>
              <p className="mt-2 text-sm">1. Tidak bisa makan telor</p>
              <p className="mt-2 text-sm">2. Tidak bisa makan nasi yang terlalu keras atau terlalu lembek</p>
            </div>
          </div>
        </div>
        <div className="flex">
          <Button type="submit" className="max-w-xs mt-12 mb-4 ml-8 bg-green-600 text-white hover:bg-green-200">Reject Order</Button>
          <Button type="submit" className="max-w-xs mt-12 mb-4 ml-8 bg-green-600 text-white hover:bg-green-200">Done</Button>
        </div>
      </div>
    </div>
  );
}

export default DetailAllergies;