"use client";

import { Table } from "flowbite-react";
import SidebarPartner from "../components/sidebar/sidebarPartner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUsers,
  faArrowUp,
  faChevronRight,
  faPen,
  faTrash,
  faClipboardList,
  faClipboardCheck,
} from "@fortawesome/free-solid-svg-icons";
import { Line } from "react-chartjs-2";
import {
  Chart,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  LineElement,
  PointElement,
} from "chart.js";
import { Link } from "react-router-dom";
import { getAuthAndProfileResponses } from "../utils/localstorage";
import { useEffect, useState } from "react";
import axios from "axios";

// Register the necessary components
Chart.register(
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  LineElement,
  PointElement
);

function HomePartner() {
  const { authResponse, profileResponse } = getAuthAndProfileResponses();
  const accessToken = authResponse["access-token"];
  const refreshToken = authResponse["refresh-token"];
  const [isLoading, setIsLoading] = useState(false);
  const [ownOrder, setOwnOrder] = useState([]);

  const ordersData = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Orders",
        data: [12, 19, 3, 5, 2, 3, 12, 19, 3, 5, 2, 3],
        borderColor: "rgba(75, 192, 192, 1)",
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        fill: true,
      },
    ],
  };

  const ratingData = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Rating",
        data: [4, 4.5, 5, 3.5, 4, 4.2, 4.8, 4.6, 4.7, 4.9, 4.3, 4.1],
        borderColor: "rgba(153, 102, 255, 1)",
        backgroundColor: "rgba(153, 102, 255, 0.2)",
        fill: true,
      },
    ],
  };

  const fetchOwnOrder = async () => {
    try {
      setIsLoading(true);
      await axios
        .get(
          process.env.REACT_APP_API_BASE_URL + "partners/orders/own?limit=5",
          {
            headers: {
              Authorization: `Bearer ${authResponse.token}`,
              "Access-Token": `${accessToken}`,
              "Refresh-Token": `${refreshToken}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          setOwnOrder(response?.data?.data?.datas);
        });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchOwnOrder();
  }, []);

  const calculateAverageRating = () => {
    const ratings = [4, 4.5, 5, 3.5, 4, 4.2, 4.8, 4.6, 4.7, 4.9, 4.3, 4.1];
    const total = ratings.reduce((sum, rating) => sum + rating, 0);
    return (total / ratings.length).toFixed(2);
  };

  return (
    <div className="flex h-screen">
      <SidebarPartner className="fixed h-full" />
      <div className="flex flex-col flex-grow p-6 overflow-y-auto">
        <div className="flex justify-between items-center mb-4">
          <div className="mb-2">
            <h2 className="text-2xl font-bold leading-9 tracking-tight text-gray-800">
              {profileResponse?.name
                ? profileResponse?.name
                : authResponse?.email}
            </h2>
            <p className="text-xs text-gray-600">
              This is an update to today's list
            </p>
          </div>
        </div>
        <hr className="border-gray-400 mb-4" />
        <div className="flex">
          <div className="flex bg-gray-100 rounded-md px-6 py-4 mr-10">
            <div className="flex">
              <FontAwesomeIcon
                icon={faClipboardCheck}
                className="w-10 h-10 bg-green-500 text-white p-4 rounded-full mt-2"
              />
              <div className="py-1 px-5 text-center mt-2">
                <p className="font-semibold text-md">Total Order</p>
                <h2 className="text-[1.5em] mt-1 font-bold">345.678</h2>
              </div>
              <div className="flex bg-green-500 text-white py-1 px-3 mt-10 mb-4 text-center rounded-full text-xs font-bold">
                <FontAwesomeIcon icon={faArrowUp} className="mt-1" />
                <p className="ml-2 mt-1">50%</p>
              </div>
            </div>
          </div>
          <Link to="/list-menu">
            <div className="flex bg-gray-100 rounded-md px-6 py-4">
              <div className="flex">
                <FontAwesomeIcon
                  icon={faClipboardList}
                  className="w-10 h-10 bg-cyan-600 p-4 rounded-full mt-2 text-white"
                />
                <div className="py-1 px-5 text-center mt-2">
                  <p className="font-semibold text-md">Total Menu</p>
                  <h2 className="text-[1.5em] mt-1 font-bold">345.678</h2>
                </div>
                <div className="flex bg-cyan-600 text-white py-1 px-3 mt-10 mb-4 text-center rounded-full text-xs font-bold">
                  <FontAwesomeIcon icon={faArrowUp} className="mt-1" />
                  <p className="ml-2 mt-1">50%</p>
                </div>
              </div>
            </div>
          </Link>
        </div>
        <div className="chart flex mt-6">
          <div className="right w-[50vw] pl-4 bg-gray-100 rounded-lg p-3 mr-2">
            <h3 className="text-xl font-bold mb-4">Monthly Orders</h3>
            <Line data={ordersData} className="mb-4" />
          </div>
          <div className="left w-full pr-4 bg-gray-100 rounded-lg p-3 ml-2">
            <h3 className="text-xl font-bold mb-4">Rating</h3>
            <Line data={ratingData} />
            <div className="mt-12 text-center">
              <p className="font-bold text-3xl text-gray-800">
                {calculateAverageRating()} (1155)
              </p>
              <p className="mt-2">Average Rating </p>
            </div>
          </div>
        </div>
        <div className="bg-gray-100 rounded-md mt-6 p-4">
          <div className="flex justify-between items-center mb-4">
            <p className="text-xl font-bold">Orders</p>
            <Link to="/list-donation">
              <div className="flex items-center text-xs cursor-pointer">
                <p className="mr-2">See All Orders</p>
                <FontAwesomeIcon icon={faChevronRight} />
              </div>
            </Link>
          </div>
          <div className="overflow-x-auto">
            <Table hoverable>
              <Table.Body className="divide-y">
                {ownOrder?.map((order, index) => (
                  <Table.Row
                    key={index}
                    className="bg-gray-100 dark:border-gray-700 dark:bg-gray-800 hover:bg-gray-200"
                  >
                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                      <div className="flex items-center">
                        <img
                          src={order?.meal?.images[0]?.image?.path}
                          alt="Indonesia"
                          className="h-7 w-7 inline-block rounded-full mr-3 mt-1"
                        />
                        <div>
                          <p className="font-semibold">{order?.meal?.name}</p>
                        </div>
                      </div>
                    </Table.Cell>
                    <Table.Cell>{order?.quantity}</Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomePartner;
