import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import LandingPage from "./user/landingPage";
import UserProfile from "./user/profile";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomeMeal from "./meal/home";
import MenuMeal from "./meal/menu";
import HomePartner from "./partner/home";
import ContactUs from "./user/contactUs";
import AboutUs from "./user/aboutUs";
import SignIn from "./signin/signin";
import Role from "./register/role";
import Patron from "./register/patron";
import Partner from "./register/partner";
import Organization from "./register/organization";
import Member from "./register/member";
import Caregiver from "./register/caregiver";
import Comment from "./rating/comment";
import Rating from "./rating/home";
import Thanks from "./rating/thanks";
import UploadBukti from "./patron/uploadBukti";
import Success from "./patron/success";
import DonationSummary from "./patron/ringkasDonasi";
import HomePatron from "./patron/home";
import Error from "./patron/error";
import DonationPatron from "./patron/donasi";
import UploadMeal from "./partner/uploadMeal";
import RatingDetail from "./partner/ratingDetail";
import ListRating from "./partner/rating";
import OrderOrg from "./partner/orderOrg";
import ListMenu from "./partner/menu";
import MealAllergies from "./partner/mealAllergies";
import MealIllness from "./partner/mealIllness";
import FoodCategory from "./partner/foodCategory";
import ListDonation from "./partner/donation";
import DetailStock from "./partner/detailStock";
import DetailOrg from "./partner/detailOrg";
import DetailIllness from "./partner/detailIllness";
import DetailDonation from "./partner/detailDonation";
import DetailAllergies from "./partner/detailAllergies";
import AddNewStock from "./partner/addNewStock";
import AddNewStaff from "./partner/addNewStaff";
import AddNewMenu from "./partner/addNewMenu";
import ListMember from "./organization/listMember";
import DetailMember from "./organization/detailMember";
import AddNew from "./organization/addNew";
import ThanksMeal from "./meal/thanks";
import Delivery from "./meal/delivery";
import Cart from "./meal/cart";
import SendCode from "./forgotPass/sendCode";
import ResetPass from "./forgotPass/resetPass";
import InputEmail from "./forgotPass/inputEmail";
import Done from "./forgotPass/done";
import DetailMemberCaregiver from "./caregiver/detailMember";
import AdminListPatron from "./admin/patron";
import AdminListPartner from "./admin/partner";
import AdminListOrganization from "./admin/organization";
import AdminOrderOrg from "./admin/orderOrg";
import AdminOrderIllness from "./admin/orderIllness";
import AdminOrderAllergies from "./admin/orderAllergies";
import AdminMemberIllness from "./admin/memberIllness";
import AdminMemberAllergies from "./admin/memberAllergies";
import AdminMealIllness from "./admin/mealIllness";
import AdminMealAllergies from "./admin/mealAllergies";
import AdminListMeal from "./admin/meal";
import AdminListIllness from "./admin/illness";
import HomeAdmin from "./admin/home";
import AdminFoodCategory from "./admin/foodCategory";
import AdminListDonation from "./admin/donation";
import AdminDetailOrg from "./admin/detailOrderOrg";
import AdminDetailIllness from "./admin/detailOrderIllness";
import AdminDetailAllergies from "./admin/detailOrderAllergies";
import AdminDetailMember from "./admin/detailMember";
import AdminDetailMealIllness from "./admin/detailMealIllness";
import AdminListCaregiver from "./admin/caregiver";
import AdminListAllergies from "./admin/allergies";
import AdminBuktiTransfer from "./admin/buktiTransfer";
import AdminDetailMealAllergies from "./admin/detailMealAllergies";
import AdminListMember from "./admin/member";
import AdminAddMember from "./admin/addMember";
import AdminAddMenu from "./admin/addMeal";
import AdminAddPartner from "./admin/addPartner";
import OrderMember from "./partner/orderMember";
import VerifyEmail from "./register/verifyEmail";
import MemberCaregiverProfile from "./meal/caregiver-profile";
import MemberOwnProfile from "./meal/member-profile";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/verify-email" element={<VerifyEmail />} />
        <Route path="/user-profile" element={<UserProfile />} />
        <Route
          path="/member-caregiver-profile"
          element={<MemberCaregiverProfile />}
        />
        <Route path="/member-own-profile" element={<MemberOwnProfile />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="" element={<LandingPage />} />
        <Route path="/home-meal" element={<HomeMeal />} />
        <Route path="/menu-meal" element={<MenuMeal />} />
        <Route path="/home-partner" element={<HomePartner />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/role" element={<Role />} />
        <Route path="/patron" element={<Patron />} />
        <Route path="/partner" element={<Partner />} />
        <Route path="/organization" element={<Organization />} />
        <Route path="/member" element={<Member />} />
        <Route path="/caregiver" element={<Caregiver />} />
        <Route path="/comment" element={<Comment />} />
        <Route path="/home-rating" element={<Rating />} />
        <Route path="/thanks" element={<Thanks />} />
        <Route path="/upload-bukti-transfer" element={<UploadBukti />} />
        <Route path="/success" element={<Success />} />
        <Route path="/ringkasan-donasi" element={<DonationSummary />} />
        <Route path="/home-patron" element={<HomePatron />} />
        <Route path="/error" element={<Error />} />
        <Route path="/donasi" element={<DonationPatron />} />
        <Route path="/upload-meal" element={<UploadMeal />} />
        {/* <Route path="/list-stock" element={<ListStock />} /> */}
        {/* <Route path="/list-staff" element={<ListStaff />} /> */}
        <Route path="/rating-detail" element={<RatingDetail />} />
        <Route path="/list-rating" element={<ListRating />} />
        <Route path="/order-org" element={<OrderOrg />} />
        <Route path="/order-member" element={<OrderMember />} />
        <Route path="/list-menu" element={<ListMenu />} />
        <Route path="/meal-illness" element={<MealIllness />} />
        <Route path="/meal-allergies" element={<MealAllergies />} />
        <Route path="/food-category" element={<FoodCategory />} />
        <Route path="/detail-stock" element={<DetailStock />} />
        <Route path="/detail-org" element={<DetailOrg />} />
        <Route path="/detail-illness" element={<DetailIllness />} />
        <Route path="/detail-donation" element={<DetailDonation />} />
        <Route path="/detail-allergies" element={<DetailAllergies />} />
        <Route path="/add-new-stock" element={<AddNewStock />} />
        <Route path="/add-new-staff" element={<AddNewStaff />} />
        <Route path="/admin-add-partner" element={<AdminAddPartner />} />
        <Route path="/add-new-menu" element={<AddNewMenu />} />
        <Route path="/list-member" element={<ListMember />} />
        <Route path="/detail-member" element={<DetailMember />} />
        <Route path="/list-donation" element={<ListDonation />} />
        <Route path="/add-new" element={<AddNew />} />
        <Route path="/thanks-meal" element={<ThanksMeal />} />
        <Route path="/delivery" element={<Delivery />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/send-code" element={<SendCode />} />
        <Route path="/reset-pass" element={<ResetPass />} />
        <Route path="/input-email" element={<InputEmail />} />
        <Route path="/done" element={<Done />} />
        <Route path="/admin-add-member" element={<AdminAddMember />} />
        <Route path="/admin-add-menu" element={<AdminAddMenu />} />
        <Route path="/admin-list-member" element={<AdminListMember />} />
        <Route
          path="/detail-member-caregiver"
          element={<DetailMemberCaregiver />}
        />
        <Route path="/admin-list-patron" element={<AdminListPatron />} />
        <Route path="/admin-list-partner" element={<AdminListPartner />} />
        <Route
          path="/admin-list-organization"
          element={<AdminListOrganization />}
        />
        <Route path="/admin-order-org" element={<AdminOrderOrg />} />
        <Route path="/admin-order-illness" element={<AdminOrderIllness />} />
        <Route
          path="/admin-order-allergies"
          element={<AdminOrderAllergies />}
        />
        <Route path="/admin-member-illness" element={<AdminMemberIllness />} />
        <Route
          path="/admin-member-allergies"
          element={<AdminMemberAllergies />}
        />
        <Route path="/admin-meal-illness" element={<AdminMealIllness />} />
        <Route path="/admin-meal-allergies" element={<AdminMealAllergies />} />
        <Route path="/admin-list-meal" element={<AdminListMeal />} />
        <Route path="/admin-list-illness" element={<AdminListIllness />} />
        <Route path="/home-admin" element={<HomeAdmin />} />
        <Route path="/admin-food-category" element={<AdminFoodCategory />} />
        <Route path="/admin-list-donation" element={<AdminListDonation />} />
        <Route path="/admin-detail-org" element={<AdminDetailOrg />} />
        <Route path="/admin-detail-Illness" element={<AdminDetailIllness />} />
        <Route
          path="/admin-detail-allergies"
          element={<AdminDetailAllergies />}
        />
        <Route path="/admin-detail-member" element={<AdminDetailMember />} />
        <Route
          path="/admin-detail-meal-illness"
          element={<AdminDetailMealIllness />}
        />
        <Route
          path="/admin-detail-meal-allergies"
          element={<AdminDetailMealAllergies />}
        />
        <Route path="/admin-list-caregiver" element={<AdminListCaregiver />} />
        <Route path="/admin-bukti-transfer" element={<AdminBuktiTransfer />} />
        <Route path="/admin-list-allergies" element={<AdminListAllergies />} />
      </Routes>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
