"use client";

import { Button } from "flowbite-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faCircle } from "@fortawesome/free-solid-svg-icons";
import SidebarMember from "../components/sidebar/sidebarMember";
import { getAuthAndProfileResponses } from "../utils/localstorage";
import { useEffect, useState } from "react";
import axios from "axios";
import { formatDate } from "../utils/date";

function Delivery() {
  const { authResponse, profileResponse } = getAuthAndProfileResponses();
  const [ordersData, setOrdersData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const accessToken = authResponse["access-token"];
  const refreshToken = authResponse["refresh-token"];

  const getStatusColor = (status) => {
    const statuses = {
      Placed: "bg-blue-100 text-blue-800",
      Confirmed: "bg-yellow-100 text-yellow-800",
      "Being Prepared": "bg-green-100 text-green-800 text-[7.5px]",
      Prepared: "bg-red-100 text-red-800",
      "Picked Up": "bg-purple-100 text-purple-800",
      "Out for Delivery": "bg-blue-100 text-blue-800 text-[7.5px]",
      Delivered: "bg-green-100 text-green-800",
      Completed: "bg-purple-100 text-purple-800",
      Cancelled: "bg-red-100 text-red-600",
    };
    return statuses[status] || "";
  };

  const fetchOrdersData = async () => {
    try {
      setIsLoading(true);
      await axios
        .get(process.env.REACT_APP_API_BASE_URL + "orders/own", {
          headers: {
            Authorization: `Bearer ${authResponse?.token}`,
            "Access-Token": `${accessToken}`,
            "Refresh-Token": `${refreshToken}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setOrdersData(response?.data?.data);
        });
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchOrdersData();
  }, []);

  return (
    <form className="flex h-screen">
      <SidebarMember className="fixed h-full" />
      <div className="flex flex-col gap-4 mt-8 mx-8 w-full">
        <div>
          <h2 className="text-2xl font-bold leading-9 tracking-tight text-gray-800 mb-2">
            Order List
          </h2>
        </div>
        <div className="flex gap-8 w-full">
          {/* belanja */}
          <div className="flex flex-col w-2/3 h-full flex-grow pr-4 overflow-y-auto max-h-[70vh]">
            <div className="bg-gray-300 rounded-lg p-6 mb-4">
              <p className="font-bold mb-2 ml-4 text-lg">SHIPPING ADDRESS</p>
              <div className="flex items-center mb-2 ml-4">
                <FontAwesomeIcon
                  icon={faLocationDot}
                  className="mr-2 text-red-600"
                />
                <p className="font-semibold text-sm">
                  {profileResponse?.user?.addresses
                    ? profileResponse?.user?.addresses[0]?.name
                    : "Home"}
                </p>
                <FontAwesomeIcon icon={faCircle} className="w-1 h-1 mx-2" />
                <p className="font-semibold text-sm">
                  {profileResponse?.first_name
                    ? profileResponse?.first_name +
                      " " +
                      profileResponse?.last_name
                    : authResponse?.email}
                </p>
              </div>
              <p className="mb-4 ml-4">
                {profileResponse?.user?.addresses
                  ? profileResponse?.user?.addresses[0]?.address
                  : "Rose Street"}
              </p>
            </div>
            
            <div className="flex-grow">
              {ordersData?.map((orders, index) => (
                <div
                  key={index}
                  className="bg-gray-300 rounded-lg p-6 mb-4 h-fit"
                >
                  <div className="flex items-center">
                    <p className="font-bold mb-2 ml-4 mt-2 text-lg flex-grow">
                      {formatDate(orders?.created_at)}
                    </p>
                    <span
                      className={`py-1 px-4 text-xs rounded-full font-semibold ${getStatusColor(
                        orders?.status
                      )}`}
                    >
                      {orders?.status}
                    </span>
                  </div>
                  {orders?.meals?.map((meals, index) => (
                    <div key={index} className="flex items-center ml-4 mr-4 mb-4 mt-4">
                      <img
                        src={meals?.meal?.images[0]?.image?.path}
                        alt={meals?.meal?.name}
                        className="h-20 w-24 mr-4 rounded-md"
                      />
                      <div className="flex items-center w-full">
                        <div className="text-sm">{meals?.meal?.name}</div>
                        <p className="ml-auto">{meals?.quantity}</p>
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
          {/* belanja */}
          <div className="h-fit w-1/3">
            <div className="w-full bg-gray-300 rounded-lg p-8">
              <p className="font-bold mb-4">Ringkasan belanja</p>
              <div className="space-y-2 mb-4">
                <div className="flex justify-between">
                  <p>Healthy Food</p>
                  <p>32</p>
                </div>
                <div className="flex justify-between">
                  <p>Healthy Drink</p>
                  <p>32</p>
                </div>
              </div>
              <hr className="border-b border-gray-500 mb-4" />
              <div className="flex justify-between mb-4">
                <p className="font-bold">Quantity</p>
                <p>64</p>
              </div>
              <div className="flex justify-center">
                <Button
                  type="submit"
                  className="w-[55vh] bg-green-600 text-white hover:bg-green-200"
                >
                  Done
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}

export default Delivery;