// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  font-family: "Poppins", sans-serif;
}

.remember {
  float: left;
  width: 50%;
}

.forgot {
  width: 50%;
  font-size: 14px;
}
  
.label{
  float: right;
}
    `, "",{"version":3,"sources":["webpack://./src/signin/signin.css"],"names":[],"mappings":"AAAA;EACE,kCAAkC;AACpC;;AAEA;EACE,WAAW;EACX,UAAU;AACZ;;AAEA;EACE,UAAU;EACV,eAAe;AACjB;;AAEA;EACE,YAAY;AACd","sourcesContent":["body {\n  font-family: \"Poppins\", sans-serif;\n}\n\n.remember {\n  float: left;\n  width: 50%;\n}\n\n.forgot {\n  width: 50%;\n  font-size: 14px;\n}\n  \n.label{\n  float: right;\n}\n    "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
