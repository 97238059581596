// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**************** Send Code ***************/
.otp{
    /* background-color: aqua; */
    border-radius: 50%;
    width: 60px;
    height: 60px;
    margin: 5px;
    text-align: center;
}
/**************** Send Code ***************/

/**************** Reset Pass ***************/
.pass, .confirm {
    border-radius: 10px;
    border-color: rgb(209 213 219);
    background-color: rgb(249 250 251);
}
/**************** Reset Pass ***************/`, "",{"version":3,"sources":["webpack://./src/forgotPass/forgotPass.css"],"names":[],"mappings":"AAAA,2CAA2C;AAC3C;IACI,4BAA4B;IAC5B,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,WAAW;IACX,kBAAkB;AACtB;AACA,2CAA2C;;AAE3C,4CAA4C;AAC5C;IACI,mBAAmB;IACnB,8BAA8B;IAC9B,kCAAkC;AACtC;AACA,4CAA4C","sourcesContent":["/**************** Send Code ***************/\n.otp{\n    /* background-color: aqua; */\n    border-radius: 50%;\n    width: 60px;\n    height: 60px;\n    margin: 5px;\n    text-align: center;\n}\n/**************** Send Code ***************/\n\n/**************** Reset Pass ***************/\n.pass, .confirm {\n    border-radius: 10px;\n    border-color: rgb(209 213 219);\n    background-color: rgb(249 250 251);\n}\n/**************** Reset Pass ***************/"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
