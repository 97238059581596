"use client";

import { useState } from "react";
import { Button, Checkbox, Label, TextInput } from "flowbite-react";
import "./signin.css";
import NavbarRegis from "../components/navbar/navbarRegis";
import { Link, useNavigate } from "react-router-dom";
import axios, { HttpStatusCode } from "axios";

function SignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const authResponse = await axios.post(
        process.env.REACT_APP_API_BASE_URL + "auth/signin",
        JSON.stringify({ email, password }),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const profileResponse = await axios.get(
        process.env.REACT_APP_API_BASE_URL + "profiles/me",
        {
          headers: {
            Authorization: `Bearer ${authResponse.data.data.token}`,
            "Access-Token": authResponse.headers["access-token"],
            "Refresh-Token": authResponse.headers["refresh-token"],
          },
        }
      );

      // * set auth authResponse to localStorage
      localStorage.setItem(
        "authResponse",
        JSON.stringify({
          ...authResponse.data.data,
          "access-token": authResponse.headers["access-token"],
          "refresh-token": authResponse.headers["refresh-token"],
        })
      );

      // * set profile data to localStorage
      localStorage.setItem(
        "profileResponse",
        JSON.stringify(profileResponse.data.data)
      );

      switch (authResponse?.data?.data?.role) {
        case 1:
          navigate("/home-admin");
          break;
        case 2:
        case 3:
        case 6:
          navigate("/home-meal");
          break;
        case 4:
          navigate("/home-partner");
          break;
        case 5:
          navigate("/home-patron");
          break;
        default:
          navigate("/signin");
      }
    } catch (error) {
      setMessage(error.response.data.data.errors);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <NavbarRegis />
      <div className="flex max-w-md flex-col gap-4 mx-auto mt-24">
        <div>
          <h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-gray-800">
            Sign in to App
          </h2>
        </div>
        <div>
          <div className="mb-2 block">
            <Label htmlFor="email1" value="Your email" />
          </div>
          <TextInput
            id="email1"
            type="email"
            placeholder="name@flowbite.com"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div>
          <div className="mb-2 block">
            <Label htmlFor="password1" value="Your password" />
          </div>
          <TextInput
            id="password1"
            type="password"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="flex items-center gap-2 max-w-md">
          <div className="remember">
            <Checkbox id="remember" />
            <Label htmlFor="remember" className="ml-2">
              Remember me
            </Label>
          </div>
          <div className="forgot font-semibold">
            <Link
              to="/input-email"
              className="ml-1 float-right font-semibold text-cyan-600 dark:text-cyan-500"
            >
              Forgot Password?
            </Link>
          </div>
        </div>
        <Button
          type="submit"
          className="bg-green-600 text-white hover:bg-green-200 mt-4"
        >
          Submit
        </Button>
        {message && <p>{message}</p>}
        <div className="text-sm text-center -mt-2">
          <p>
            Don't you have an account?
            <a
              href="/role"
              className="ml-1 font-semibold text-cyan-600 dark:text-cyan-500 cursor-pointer"
            >
              Register
            </a>
          </p>
        </div>
      </div>
    </form>
  );
}

export default SignIn;
