"use client";

import { Label, Button } from "flowbite-react";
import SidebarPartner from "../components/sidebar/sidebarPartner";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faCirclePlus, faCircleMinus } from '@fortawesome/free-solid-svg-icons';

function DetailStock() {

  return (
    <div className="flex h-screen">
      <SidebarPartner className="fixed h-full" />
      <div className="flex flex-col flex-grow p-6 overflow-y-auto">
        <div className="flex items-center mb-4">
          <a href="/list-stock">
            <FontAwesomeIcon icon={faChevronLeft} className="text-lg" />
          </a>
          <div className="mb-2 ml-10">
            <h2 className="text-2xl font-bold leading-9 tracking-tight text-gray-800">Detail Stock of Meal</h2>
            <p className="text-xs text-gray-600">This is a detail of meal</p>
          </div>
        </div>
        <hr className="border-gray-400 mb-4"/>
        <div className="flex justify-between items-center mb-4">
          <p className="font-semibold ml-8">23 Juli 2023</p>
          <div className="flex items-center space-x-2 mr-4">
            <a href="#" className="font-medium text-cyan-500 dark:text-cyan-500">Edit</a>
            <p className="text-cyan-500 font-semibold">|</p>
            <a href="#" className="font-medium text-cyan-500 dark:text-cyan-500">Delete</a>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4 mb-4 mt-4">
          <div>
            <Label htmlFor="name" className="ml-8 font-semibold text-md">Meal name</Label>
            <p className="text-sm ml-8 mt-2">Ikan Kakap Goreng</p>
          </div>
          <div>
            <Label htmlFor="sex" className="ml-8 font-semibold text-md">Category</Label>
            <p className="text-sm ml-8 mt-2">Ikan</p>
          </div>
          <div className="mt-8">
            <Label htmlFor="email" className="ml-8 font-semibold text-md">Main ingredient</Label>
            <p className="text-sm ml-8 mt-2">Ikan</p>
          </div>
          <div className="mt-8">
            <Label htmlFor="email" className="ml-8 font-semibold text-md">Meal ingredient</Label>
            <p className="text-sm ml-8 mt-2">1. Ikan goreng</p>
            <p className="text-sm ml-8 mt-2">2. Sambal Matah</p>
          </div>
          <div className="mt-8">
            <Label htmlFor="address" className="ml-8 font-semibold text-md">Stock of Meal</Label>
            <div className="flex ml-8 mt-3">
              <FontAwesomeIcon icon={faCircleMinus} className="mt-2 w-6 h-auto" />
              <p className="text-xl mr-4 ml-4 mt-1">300</p>
              <FontAwesomeIcon icon={faCirclePlus} className="mt-2 w-6 h-auto" />
            </div>
          </div>
        </div>
        <Button type="submit" className="max-w-xs mt-16 ml-8 bg-green-600 text-white hover:bg-green-200">Save</Button>
      </div>
    </div>
  );
}

export default DetailStock;