"use client";

import { useState } from 'react';
import { Button } from "flowbite-react";
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import NavbarPatron from '../components/navbar/navbarPatron';
import "./patron.css";

function DonationPatron() {
  const navigate = useNavigate(); // Initialize useNavigate
  const [donationAmount, setDonationAmount] = useState(''); // State for selected donation amount

  const handleAmountClick = (amount) => {
    setDonationAmount(amount); // Update donation amount when a button is clicked
  };

  const handleInputChange = (e) => {
    setDonationAmount(e.target.value); // Update donation amount when a custom amount is entered
  };

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission
    navigate('/upload-bukti-transfer', { state: { donationAmount } }); // Pass donation amount to the next page
  };

  return (
    <form onSubmit={handleSubmit}>
      <NavbarPatron />
      <div className="flex flex-col max-w-7xl mx-auto mt-8 items-center p-4 text-center">
        <h2 className="text-2xl font-bold leading-9 tracking-tight text-gray-800 mb-4">How much do you want to donate now?</h2>
        <p className="mb-8 max-w-2xl">Your donation is received now, but you pay nothing today and make payments over time. There's no interest or fees and applying does not affect your credit score</p>
      </div>
      <div className="grid grid-cols-5 gap-8 max-w-7xl mx-auto">
        {['Rp. 5.000', 'Rp. 10.000', 'Rp. 15.000', 'Rp. 20.000', 'Rp. 25.000', 'Rp. 100.000', 'Rp. 200.000', 'Rp. 225.000', 'Rp. 500.000', 'Rp. 1.000.000', 'Rp. 5.000.000'].map((amount, index) => (
          <div
            key={index}
            className="border border-gray-300 p-4 text-center rounded-lg cursor-pointer"
            onClick={() => handleAmountClick(amount)}
          >
            <a className="text-gray-800 font-medium">{amount}</a>
          </div>
        ))}
      </div>
      <div className="flex max-w-5xl -mt-10 ml-desktop-lg ml-laptop ml-laptop-sm">
        <div className="w-full md:w-1/2">
          <input
            className="w-full p-2 border-b border-gray-300 text-md focus:outline-none focus:border-gray-500"
            placeholder="Other nominal"
            value={donationAmount}
            onChange={handleInputChange} // Update state when user types a custom amount
          />
        </div>
      </div>
      <div className="flex justify-center mt-8">
        <Button type="submit" className="w-80 bg-green-600">Donation</Button>
      </div>
    </form>
  );
}

export default DonationPatron;