// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Untuk layar dengan lebar maksimal 1024px (Laptop kecil) */
  @media (max-width: 1024px) {
    .ml-laptop-sm {
      margin-left: 2rem;
    }
  }
  
  /* Untuk layar dengan lebar maksimal 1440px (Laptop standar) */
  @media (max-width: 1440px) {
    .ml-laptop {
      margin-left: 22rem; /* Sesuaikan margin untuk layar laptop standar */
    }
  }
  
  /* Untuk layar dengan lebar minimal 1920px (Desktop besar) */
  @media (min-width: 1920px) {
    .ml-desktop-lg {
      margin-left: 37rem; /* Margin lebih besar untuk layar desktop besar */
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/patron/patron.css"],"names":[],"mappings":"AAAA,4DAA4D;EAC1D;IACE;MACE,iBAAiB;IACnB;EACF;;EAEA,8DAA8D;EAC9D;IACE;MACE,kBAAkB,EAAE,gDAAgD;IACtE;EACF;;EAEA,4DAA4D;EAC5D;IACE;MACE,kBAAkB,EAAE,iDAAiD;IACvE;EACF","sourcesContent":["/* Untuk layar dengan lebar maksimal 1024px (Laptop kecil) */\n  @media (max-width: 1024px) {\n    .ml-laptop-sm {\n      margin-left: 2rem;\n    }\n  }\n  \n  /* Untuk layar dengan lebar maksimal 1440px (Laptop standar) */\n  @media (max-width: 1440px) {\n    .ml-laptop {\n      margin-left: 22rem; /* Sesuaikan margin untuk layar laptop standar */\n    }\n  }\n  \n  /* Untuk layar dengan lebar minimal 1920px (Desktop besar) */\n  @media (min-width: 1920px) {\n    .ml-desktop-lg {\n      margin-left: 37rem; /* Margin lebih besar untuk layar desktop besar */\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
